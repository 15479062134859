import React, { useState } from 'react';
import './FontChanger.css';


const StylishNameGenerator = ({ inputText, textColor, fontSize, maxDisplay }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const fonts = [
   
{
  "name": "Decorated Stylish",
  "prefix": "✴✴ 🎀 ",
  "suffix": " 🎀 ✴✴",
  "map": {
    "a": "𝒜", "b": "𝒷", "c": "𝒸", "d": "𝒹", "e": "𝑒", "f": "𝒻", "g": "𝑔",
    "h": "𝒽", "i": "𝒾", "j": "𝒿", "k": "𝓀", "l": "𝓁", "m": "𝓂", "n": "𝓃",
    "o": "𝑜", "p": "𝓅", "q": "𝓆", "r": "𝓇", "s": "𝓈", "t": "𝓉", "u": "𝓊",
    "v": "𝓋", "w": "𝓌", "x": "𝓍", "y": "𝓎", "z": "𝓏"
  }
},
{
  "name": "Decorated Name Style 2",
  "prefix": "✴ 🎀 ",
  "suffix": " 🎀 ✴",
  "map": {
    "a": "𝒜", "b": "𝒷", "c": "𝒸", "d": "𝒹", "e": "𝑒", "f": "𝒻", "g": "𝑔",
    "h": "𝒽", "i": "𝒾", "j": "𝒿", "k": "𝓀", "l": "𝓁", "m": "𝓂", "n": "𝓃",
    "o": "𝑜", "p": "𝓅", "q": "𝓆", "r": "𝓇", "s": "𝓈", "t": "𝓉", "u": "𝓊",
    "v": "𝓋", "w": "𝓌", "x": "𝓍", "y": "𝓎", "z": "𝓏"
  }
},
{
  "name": "Birthday Style",
  "prefix": "🎂 ⋆ 🍡 🎀 ",
  "suffix": " 🎀 🍡 ⋆ 🎂",
  "map": {
    "a": "𝒜", "b": "𝒷", "c": "𝒸", "d": "𝒹", "e": "𝑒", "f": "𝒻", "g": "𝑔",
    "h": "𝒽", "i": "𝒾", "j": "𝒿", "k": "𝓀", "l": "𝓁", "m": "𝓂", "n": "𝓃",
    "o": "𝑜", "p": "𝓅", "q": "𝓆", "r": "𝓇", "s": "𝓈", "t": "𝓉", "u": "𝓊",
    "v": "𝓋", "w": "𝓌", "x": "𝓍", "y": "𝓎", "z": "𝓏"
  }
},
{
  "name": "Symbol Decorated",
  "prefix": ": 🎀 ",
  "suffix": " 🎀 :",
  "map": {
    "a": "𝒜", "b": "𝒷", "c": "𝒸", "d": "𝒹", "e": "𝑒", "f": "𝒻", "g": "𝑔",
    "h": "𝒽", "i": "𝒾", "j": "𝒿", "k": "𝓀", "l": "𝓁", "m": "𝓂", "n": "𝓃",
    "o": "𝑜", "p": "𝓅", "q": "𝓆", "r": "𝓇", "s": "𝓈", "t": "𝓉", "u": "𝓊",
    "v": "𝓋", "w": "𝓌", "x": "𝓍", "y": "𝓎", "z": "𝓏"
  }
},
{
  "name": "Flower Decorated",
  "prefix": "✧ 🎀 ",
  "suffix": " 🎀 ✧",
  "map": {
    "a": "𝒜", "b": "𝒷", "c": "𝒸", "d": "𝒹", "e": "𝑒", "f": "𝒻", "g": "𝑔",
    "h": "𝒽", "i": "𝒾", "j": "𝒿", "k": "𝓀", "l": "𝓁", "m": "𝓂", "n": "𝓃",
    "o": "𝑜", "p": "𝓅", "q": "𝓆", "r": "𝓇", "s": "𝓈", "t": "𝓉", "u": "𝓊",
    "v": "𝓋", "w": "𝓌", "x": "𝓍", "y": "𝓎", "z": "𝓏"
  }
},
{
  "name": "Minimalistic",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "👍︎", "b": "□︎", "c": "□︎", "d": "●︎", "e": "◻︎", "f": "❄︎", "g": "♏︎",
    "h": "⌧︎", "i": "⧫︎", "j": "□︎", "k": "□︎", "l": "□︎", "m": "□︎", "n": "□︎",
    "o": "□︎", "p": "□︎", "q": "□︎", "r": "□︎", "s": "□︎", "t": "□︎", "u": "□︎",
    "v": "□︎", "w": "□︎", "x": "□︎", "y": "□︎", "z": "□︎"
  }
},
    {

      name: 'Random Emoticons 3',
      prefix: '┑(￣▽￣)┍',
      suffix: '┑(￣▽￣)┍',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑕ', d: 'ᗪ', e: 'E', f: 'ᖴ', g: 'G',
        h: 'ᕼ', i: 'I', j: 'ᒍ', k: 'K', l: 'ᒪ', m: 'ᗰ', n: 'ᑎ',
        o: 'O', p: 'ᑭ', q: 'ᑫ', r: 'ᖇ', s: 'ᔕ', t: 'T', u: 'ᑌ',
        v: 'ᐯ', w: 'ᗯ', x: '᙭', y: 'Y', z: 'ᘔ'
      }
    },
    {
      name: 'CRAZY STYLISH NAME',
      prefix: '☞♟',
      suffix: '✊☺',
      map: {
        a: '₳', b: '฿', c: '₵', d: 'Đ', e: 'Ɇ', f: '₣', g: '₲',
        h: 'Ⱨ', i: 'ł', j: 'J', k: '₭', l: 'Ⱡ', m: '₥', n: '₦',
        o: 'Ø', p: '₱', q: 'Q', r: 'Ɽ', s: '₴', t: '₮', u: 'Ʉ',
        v: 'V', w: '₩', x: 'Ӿ', y: 'Ɏ', z: 'Ⱬ'
      }
    },
    {
      name: 'Name Style',
      prefix: '♚☺',
      suffix: '🎯♪',
      map: {
        a: 'ⓐ', b: '𝓫', c: '𝓒', d: '๔', e: 'ⓔ', f: 'ᶠ', g: 'ﻮ',
        h: 'Ⓗ', i: '𝒾', j: 'נ', k: 'к', l: '𝕝', m: '𝓶', n: 'ℕ',
        o: '𝓞', p: '𝕡', q: 'ᵠ', r: '尺', s: 'ş', t: '𝓽', u: '𝐮',
        v: '𝕧', w: 'Ⓦ', x: 'א', y: 'Ⓨ', z: '𝔷'
      }
    },
    {
      name: 'Random 3',
      prefix: '•.¸¸.•',
      suffix: '•.¸¸.•',
      map: {
        a: 'ꍏ', b: '♭', c: '☾', d: '◗', e: '€', f: 'Ϝ', g: '❡',
        h: '♄', i: '♗', j: '♪', k: 'ϰ', l: '↳', m: '♔', n: '♫',
        o: '🌸', p: 'ρ', q: '☭', r: '☈', s: 'ⓢ', t: '☂', u: '☋',
        v: '✓', w: 'ω', x: '⌘', y: '☿', z: '☡'
      }
    },
    {
      name: 'Random 4',
      prefix: '🐫 ⋆ 🐠',
      suffix: '🐠 ⋆ 🐫',
      map: {
        a: '𝚊', b: '𝚋', c: '𝚌', d: '𝚍', e: '𝚎', f: '𝚏', g: '𝚐',
        h: '𝚑', i: '𝚒', j: '𝚓', k: '𝚔', l: '𝚕', m: '𝚖', n: '𝚗',
        o: '❤', p: '𝚙', q: '𝚚', r: '𝚛', s: '𝚜', t: '𝚝', u: '𝚞',
        v: '𝚟', w: '𝚠', x: '𝚡', y: '𝚢', z: '𝚣'
      }
    },
    {
      name: 'Random 5',
      prefix: '«--(¯`v´¯)--»',
      suffix: '«--(¯`v´¯)--»',
      map: {
        a: 'ค', b: '๖', c: '¢', d: '໓', e: 'ē', f: 'f', g: 'ງ',
        h: 'hi', i: 'ว', j: 'k', k: '๓', l: 'ຖ', m: '໐', n: 'p',
        o: '๑', p: 'r', q: 'Ş', r: 't', s: 'น', t: 'ง', u: 'ຟ',
        v: 'x', w: 'ฯ', x: 'ຊ', y: 'ฯ', z: 'ฯ'
      }
    },
    {
      name: 'Random 6',
      prefix: '↤↤↤↤↤',
      suffix: '↦↦↦↦↦',
      map: {
        a: 'a̴', b: 'b̴', c: 'c̴', d: 'd̴', e: 'e̴', f: 'f̴', g: 'g̴',
        h: 'h̴', i: 'i̴', j: 'j̴', k: 'k̴', l: 'l̴', m: 'm̴', n: 'n̴',
        o: 'o̴', p: 'p̴', q: 'q̴', r: 'r̴', s: 's̴', t: 't̴', u: 'u̴',
        v: 'v̴', w: 'w̴', x: 'x̴', y: 'y̴', z: 'z̴'
      }
    },
    {
      name: 'Fraktur',
      map: {
        A: '𝔄', B: '𝔅', C: 'ℭ', D: '𝔇', E: '𝔈', F: '𝔉', G: '𝔊',
        H: 'ℌ', I: 'ℑ', J: '𝔍', K: '𝔎', L: '𝔏', M: '𝔐', N: '𝔑',
        O: '𝔒', P: '𝔓', Q: '𝔔', R: 'ℜ', S: '𝔖', T: '𝔗', U: '𝔘',
        V: '𝔙', W: '𝔚', X: '𝔛', Y: '𝔜', Z: 'ℨ',
        a: '𝔞', b: '𝔟', c: '𝔠', d: '𝔡', e: '𝔢', f: '𝔣', g: '𝔤',
        h: '𝔥', i: '𝔦', j: '𝔧', k: '𝔨', l: '𝔩', m: '𝔪', n: '𝔫',
        o: '𝔬', p: '𝔭', q: '𝔮', r: '𝔯', s: '𝔰', t: '𝔱', u: '𝔲',
        v: '𝔳', w: '𝔴', x: '𝔵', y: '𝔶', z: '𝔷'
      }
    },
    {
      name: 'Bold Fraktur',
      map: {
        A: '𝕬', B: '𝕭', C: '𝕮', D: '𝕯', E: '𝕰', F: '𝕱', G: '𝕲',
        H: '𝕳', I: '𝕴', J: '𝕵', K: '𝕶', L: '𝕷', M: '𝕸', N: '𝕹',
        O: '𝕺', P: '𝕻', Q: '𝕼', R: '𝕽', S: '𝕾', T: '𝕿', U: '𝖀',
        V: '𝖁', W: '𝖂', X: '𝖃', Y: '𝖄', Z: '𝖅',
        a: '𝖆', b: '𝖇', c: '𝖈', d: '𝖉', e: '𝖊', f: '𝖋', g: '𝖌',
        h: '𝖍', i: '𝖎', j: '𝖏', k: '𝖐', l: '𝖑', m: '𝖒', n: '𝖓',
        o: '𝖔', p: '𝖕', q: '𝖖', r: '𝖗', s: '𝖘', t: '𝖙', u: '𝖚',
        v: '𝖛', w: '𝖜', x: '𝖝', y: '𝖞', z: '𝖟'
      }
    },
    {
      name: 'Fancy Script',
      prefix: '',
      suffix: '',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '𝑜', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },

    {
      name: 'Cool Brackets',
      prefix: '★彡[',
      suffix: ']彡★',
      map: {
        a: 'ᴀ', b: 'ʙ', c: 'ᴄ', d: 'ᴅ', e: 'ᴇ', f: 'ꜰ', g: 'ɢ',
        h: 'ʜ', i: 'ɪ', j: 'ᴊ', k: 'ᴋ', l: 'ʟ', m: 'ᴍ', n: 'ɴ',
        o: 'ᴏ', p: 'ᴘ', q: 'Q', r: 'ʀ', s: 'ꜱ', t: 'ᴛ', u: 'ᴜ',
        v: 'ᴠ', w: 'ᴡ', x: 'x', y: 'ʏ', z: 'ᴢ'
      }
    },
    {
      name: 'Greek Inspired',
      prefix: '',
      suffix: '',
      map: {
        a: 'Λ', b: 'Ϧ', c: 'ㄈ', d: 'Ð', e: 'Ɛ', f: 'F', g: 'Ɠ',
        h: 'н', i: 'ɪ', j: 'ﾌ', k: 'Қ', l: 'Ł', m: '௱', n: 'Л',
        o: 'Ø', p: 'þ', q: 'Ҩ', r: '尺', s: 'ら', t: 'Ť', u: 'Ц',
        v: 'Ɣ', w: 'Ɯ', x: 'χ', y: 'Ϥ', z: 'Ẕ'
      }
    },
    {
      name: 'Fancy Script',
      prefix: '',
      suffix: '',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '𝑜', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Mixed Symbols',
      prefix: '☯🐉 ',
      suffix: ' 😲😾',
      map: {
        a: '𝔸', b: '𝓫', c: '𝒸', d: '∂', e: 'Ẹ', f: 'ᖴ', g: '𝐆',
        h: 'ℍ', i: '𝕚', j: 'ʲ', k: 'Ⓚ', l: 'ℓ', m: '𝕄', n: '𝓷',
        o: 'ｏ', p: 'ק', q: 'q', r: 'ⓡ', s: 'ⓢ', t: 'Ⓣ', u: 'ｕ',
        v: 'Ѷ', w: '𝓦', x: '𝔵', y: 'ч', z: 'z'
      }
    },
    {
      name: 'Stylish Symbols',
      prefix: '💲✊ ',
      suffix: ' ✎🐳',
      map: {
        a: 'Ⓐ', b: 'в', c: '𝓬', d: '𝓭', e: '𝓔', f: 'ᶠ', g: '𝓰',
        h: 'ｈ', i: '𝐈', j: '𝕛', k: '𝕂', l: 'ˡ', m: 'м', n: '𝓝',
        o: 'ㄖ', p: '𝓟', q: 'q', r: '𝕣', s: 'ş', t: '𝕥', u: 'ย',
        v: '𝐕', w: '𝐰', x: 'א', y: '𝐘', z: '𝓩'
      }
    },
    {
      name: 'Fancy Letters',
      prefix: '👺🎀 ',
      suffix: ' ♡🐊',
      map: {
        a: '卂', b: 'ⓑ', c: '𝓒', d: 'Ď', e: 'ᗴ', f: 'Ƒ', g: 'ⓖ',
        h: '𝐇', i: 'ｉ', j: 'ｊ', k: 'Ⓚ', l: '𝓵', m: 'м', n: 'Ⓝ',
        o: '๏', p: 'Ⓟ', q: '𝔮', r: 'ʳ', s: 'ⓢ', t: '𝓉', u: 'ย',
        v: 'ｖ', w: 'ᗯ', x: 'χ', y: 'Ⓨ', z: 'z'
      }
    },
    {
      name: 'Arrow Style',
      prefix: '↫↫↫↫↫ ',
      suffix: ' ↬↬↬↬↬',
      map: {
        a: '𝓐', b: 'в', c: 'ｃ', d: 'ᗪ', e: 'έ', f: 'ғ', g: 'ⓖ',
        h: '𝐡', i: 'ί', j: 'ｊ', k: '𝕂', l: '𝔩', m: 'м', n: 'η',
        o: 'ᵒ', p: '𝓹', q: '𝓺', r: 'Ř', s: 'ᔕ', t: 'Ｔ', u: 'ᵘ',
        v: 'V', w: '𝔴', x: 'ﾜ', y: 'ｙ', z: 'Ƶ'
      }
    },
   
    {
      name: 'Strike Through',
      prefix: '▄︻デ',
      suffix: '══━一',
      map: {
        a: 'a̷', b: 'b̷', c: 'c̷', d: 'd̷', e: 'e̷', f: 'f̷', g: 'g̷',
        h: 'h̷', i: 'i̷', j: 'j̷', k: 'k̷', l: 'l̷', m: 'm̷', n: 'n̷',
        o: 'o̷', p: 'p̷', q: 'q̷', r: 'r̷', s: 's̷', t: 't̷', u: 'u̷',
        v: 'v̷', w: 'w̷', x: 'x̷', y: 'y̷', z: 'z̷'
      }
    },
    {
      name: 'Ornate Script',
      prefix: '꧁༒☬',
      suffix: '☬༒꧂',
      map: {
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Fancy Lowercase name design',
      prefix: '꧁༺',
      suffix: '༻꧂',
      map: {
        a: 'ǟ', b: 'ɮ', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɢ',
        h: 'ɦ', i: 'ɨ', j: 'ʝ', k: 'ӄ', l: 'ʟ', m: 'ʍ', n: 'ռ',
        o: 'օ', p: 'ք', q: 'զ', r: 'ʀ', s: 'ֆ', t: 'ȶ', u: 'ʊ',
        v: 'ʋ', w: 'ա', x: 'Ӽ', y: 'ʏ', z: 'ʐ'
      }
    },
    {
      name: 'design name in Boxed Letters',
      prefix: '꧁𓊈𒆜',
      suffix: '𒆜𓊉꧂',
      map: {
        a: '🅰', b: '🅱', c: '🅲', d: '🅳', e: '🅴', f: '🅵', g: '🅶',
        h: '🅷', i: '🅸', j: '🅹', k: '🅺', l: '🅻', m: '🅼', n: '🅽',
        o: '🅾', p: '🅿', q: '🆀', r: '🆁', s: '🆂', t: '🆃', u: '🆄',
        v: '🆅', w: '🆆', x: '🆇', y: '🆈', z: '🆉'
      }
    },
    {
      name: 'Block Letters',
      prefix: '▀▄▀▄▀▄',
      suffix: '▀▄▀▄▀▄',
      map: {
        a: '🄰', b: '🄱', c: '🄲', d: '🄳', e: '🄴', f: '🄵', g: '🄶',
        h: '🄷', i: '🄸', j: '🄹', k: '🄺', l: '🄻', m: '🄼', n: '🄽',
        o: '🄾', p: '🄿', q: '🅀', r: '🅁', s: '🅂', t: '🅃', u: '🅄',
        v: '🅅', w: '🅆', x: '🅇', y: '🅈', z: '🅉'
      }
    },
    {
      name: 'Cyrillic Styled',
      prefix: '█▓▒­░⡷⠂',
      suffix: '⠐⢾░▒▓█',
      map: {
        a: 'Д', b: 'Б', c: 'C', d: 'D', e: 'Ξ', f: 'F', g: 'G',
        h: 'H', i: 'I', j: 'J', k: 'Ҝ', l: 'L', m: 'M', n: 'И',
        o: 'Ф', p: 'P', q: 'Ǫ', r: 'Я', s: 'S', t: 'Γ', u: 'Ц',
        v: 'V', w: 'Щ', x: 'Ж', y: 'У', z: 'Z'
      }
    },
    {
      name: 'Fraktur',
      prefix: '',
      suffix: '',
      map: {
        a: '𝔞', b: '𝔟', c: '𝔠', d: '𝔡', e: '𝔢', f: '𝔣', g: '𝔤',
        h: '𝔥', i: '𝔦', j: '𝔧', k: '𝔨', l: '𝔩', m: '𝔪', n: '𝔫',
        o: '𝔬', p: '𝔭', q: '𝔮', r: '𝔯', s: '𝔰', t: '𝔱', u: '𝔲',
        v: '𝔳', w: '𝔴', x: '𝔵', y: '𝔶', z: '𝔷'
      }
    },
    {
      name: 'Script',
      prefix: '',
      suffix: '',
      map: {
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Double Struck',
      prefix: '',
      suffix: '',
      map: {
        a: '𝕒', b: '𝕓', c: '𝕔', d: '𝕕', e: '𝕖', f: '𝕗', g: '𝕘',
        h: '𝕙', i: '𝕚', j: '𝕛', k: '𝕜', l: '𝕝', m: '𝕞', n: '𝕟',
        o: '𝕠', p: '𝕡', q: '𝕢', r: '𝕣', s: '𝕤', t: '𝕥', u: '𝕦',
        v: '𝕧', w: '𝕨', x: '𝕩', y: '𝕪', z: '𝕫'
      }
    },
    {
      name: 'Italic',
      prefix: '',
      suffix: '',
      map: {
        a: '𝘢', b: '𝘣', c: '𝘤', d: '𝘥', e: '𝘦', f: '𝘧', g: '𝘨',
        h: '𝘩', i: '𝘪', j: '𝘫', k: '𝘬', l: '𝘭', m: '𝘮', n: '𝘯',
        o: '𝘰', p: '𝘱', q: '𝘲', r: '𝘳', s: '𝘴', t: '𝘵', u: '𝘶',
        v: '𝘷', w: '𝘸', x: '𝘹', y: '𝘺', z: '𝘻'
      }

    },
    {
      name: 'Italic',
      prefix: '',
      suffix: '',
      map: {
        a: '𝘢', b: '𝘣', c: '𝘤', d: '𝘥', e: '𝘦', f: '𝘧', g: '𝘨',
        h: '𝘩', i: '𝘪', j: '𝘫', k: '𝘬', l: '𝘭', m: '𝘮', n: '𝘯',
        o: '𝘰', p: '𝘱', q: '𝘲', r: '𝘳', s: '𝘴', t: '𝘵', u: '𝘶',
        v: '𝘷', w: '𝘸', x: '𝘹', y: '𝘺', z: '𝘻'
      }
    },
    {
      name: 'Bold Italic',
      prefix: '',
      suffix: '',
      map: {
        a: '𝙖', b: '𝙗', c: '𝙘', d: '𝙙', e: '𝙚', f: '𝙛', g: '𝙜',
        h: '𝙝', i: '𝙞', j: '𝙟', k: '𝙠', l: '𝙡', m: '𝙢', n: '𝙣',
        o: '𝙤', p: '𝙥', q: '𝙦', r: '𝙧', s: '𝙨', t: '𝙩', u: '𝙪',
        v: '𝙫', w: '𝙬', x: '𝙭', y: '𝙮', z: '𝙯'
      }
    },
    {
      name: 'Monospace',
      prefix: '',
      suffix: '',
      map: {
        a: '𝚊', b: '𝚋', c: '𝚌', d: '𝚍', e: '𝚎', f: '𝚏', g: '𝚐',
        h: '𝚑', i: '𝚒', j: '𝚓', k: '𝚔', l: '𝚕', m: '𝚖', n: '𝚗',
        o: '𝚘', p: '𝚙', q: '𝚚', r: '𝚛', s: '𝚜', t: '𝚝', u: '𝚞',
        v: '𝚟', w: '𝚠', x: '𝚡', y: '𝚢', z: '𝚣'
      }
    },
    {
      name: 'Circled Letters',
      prefix: '',
      suffix: '',
      map: {
        a: 'ⓐ', b: 'ⓑ', c: 'ⓒ', d: 'ⓓ', e: 'ⓔ', f: 'ⓕ', g: 'ⓖ',
        h: 'ⓗ', i: 'ⓘ', j: 'ⓙ', k: 'ⓚ', l: 'ⓛ', m: 'ⓜ', n: 'ⓝ',
        o: 'ⓞ', p: 'ⓟ', q: 'ⓠ', r: 'ⓡ', s: 'ⓢ', t: 'ⓣ', u: 'ⓤ',
        v: 'ⓥ', w: 'ⓦ', x: 'ⓧ', y: 'ⓨ', z: 'ⓩ'
      }
    },
    {
      name: 'Squared Letters',
      prefix: '',
      suffix: '',
      map: {
        a: '🅰', b: '🅱', c: '🅲', d: '🅳', e: '🅴', f: '🅵', g: '🅶',
        h: '🅷', i: '🅸', j: '🅹', k: '🅺', l: '🅻', m: '🅼', n: '🅽',
        o: '🅾', p: '🅿', q: '🆀', r: '🆁', s: '🆂', t: '🆃', u: '🆄',
        v: '🆅', w: '🆆', x: '🆇', y: '🆈', z: '🆉'
      }
    },
    {
      name: 'Decorative Uppercase',
      prefix: '',
      suffix: '',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑕ', d: 'ᗪ', e: 'ᗴ', f: 'ᖴ', g: 'Ǥ',
        h: 'ᕼ', i: 'Ꭵ', j: 'ᒎ', k: 'ᛕ', l: 'ᒪ', m: 'ᗰ', n: 'ᑎ',
        o: 'ᗝ', p: 'ᑭ', q: 'Ɋ', r: 'ᖇ', s: 'ᔕ', t: '丅', u: 'ᑌ',
        v: 'ᐯ', w: 'ᗯ', x: '᙭', y: 'Ƴ', z: '乙'
      }
    },
    {
      name: 'Fullwidth Lowercase',
      prefix: '',
      suffix: '',
      map: {
        a: 'ａ', b: 'ｂ', c: 'ｃ', d: 'ｄ', e: 'ｅ', f: 'ｆ', g: 'ｇ',
        h: 'ｈ', i: 'ｉ', j: 'ｊ', k: 'ｋ', l: 'ｌ', m: 'ｍ', n: 'ｎ',
        o: 'ｏ', p: 'ｐ', q: 'ｑ', r: 'ｒ', s: 'ｓ', t: 'ｔ', u: 'ｕ',
        v: 'ｖ', w: 'ｗ', x: 'ｘ', y: 'ｙ', z: 'ｚ'
      }
    },
    {
      name: 'Bold Serif',
      prefix: '',
      suffix: '',
      map: {
        a: '𝐚', b: '𝐛', c: '𝐜', d: '𝐝', e: '𝐞', f: '𝐟', g: '𝐠',
        h: '𝐡', i: '𝐢', j: '𝐣', k: '𝐤', l: '𝐥', m: '𝐦', n: '𝐧',
        o: '𝐨', p: '𝐩', q: '𝐪', r: '𝐫', s: '𝐬', t: '𝐭', u: '𝐮',
        v: '𝐯', w: '𝐰', x: '𝐱', y: '𝐲', z: '𝐳'
      }
    },
    {
      name: 'Upside Down',
      prefix: '',
      suffix: '',
      map: {
        a: 'ɐ', b: 'q', c: 'ɔ', d: 'p', e: 'ǝ', f: 'ɟ', g: 'ƃ',
        h: 'ɥ', i: 'ı', j: 'ɾ', k: 'ʞ', l: 'l', m: 'ɯ', n: 'u',
        o: 'o', p: 'd', q: 'b', r: 'ɹ', s: 's', t: 'ʇ', u: 'n',
        v: 'ʌ', w: 'ʍ', x: 'x', y: 'ʎ', z: 'z'
      }
    },
    {
      name: 'Flipped',
      prefix: '',
      suffix: '',
      map: {
        a: 'ɐ', b: 'q', c: 'ɔ', d: 'p', e: 'ǝ', f: 'ɟ', g: 'ƃ',
        h: 'ɥ', i: 'ᴉ', j: 'ɾ', k: 'ʞ', l: 'l', m: 'ɯ', n: 'u',
        o: 'o', p: 'd', q: 'b', r: 'ɹ', s: 's', t: 'ʇ', u: 'n',
        v: 'ʌ', w: 'ʍ', x: 'x', y: 'ʎ', z: 'z'
      }
    },
    {
      name: 'Squared Uppercase',
      prefix: '',
      suffix: '',
      map: {
        a: '🄰', b: '🄱', c: '🄲', d: '🄳', e: '🄴', f: '🄵', g: '🄶',
        h: '🄷', i: '🄸', j: '🄹', k: '🄺', l: '🄻', m: '🄼', n: '🄽',
        o: '🄾', p: '🄿', q: '🅀', r: '🅁', s: '🅂', t: '🅃', u: '🅄',
        v: '🅅', w: '🅆', x: '🅇', y: '🅈', z: '🅉'
      }
    },
    {
      name: 'Inverted',
      prefix: '',
      suffix: '',
      map: {
        a: 'ɒ', b: 'ɔ', c: 'd', d: 'b', e: 'ɘ', f: 'ǫ', g: 'ʜ',
        h: 'i', i: 'ꞁ', j: 'ʞ', k: '|', l: 'm', m: 'ᴎ', n: 'o',
        o: 'p', p: 'q', q: 'ɿ', r: 'ꙅ', s: 'ƚ', t: 'u', u: 'v',
        v: 'w', w: 'x', x: 'y', y: 'ƹ', z: 'Ꮈ'
      }
    },
    {
      name: 'Decorative with Peach',
      prefix: '▀▄▀▄▀▄ 🎀 ',
      suffix: ' 🎀 ▄▀▄▀▄▀',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '🍑', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Decorative with Yin-Yang',
      prefix: '•?((¯°·._.• 🎀 ',
      suffix: ' 🎀 •._.·°¯((?•',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '☯', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Decorative with Donut',
      prefix: '๑۞๑,¸¸,ø¤º°`°๑۩ 🎀 ',
      suffix: ' 🎀 ۩๑°`°º¤ø,¸¸,๑۞๑',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '🍩', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Decorative with Candy',
      prefix: '🕊 🎀 ',
      suffix: ' 🎀 🕊',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '🍬', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Decorative with Flower',
      prefix: '🍓 🎀 ',
      suffix: ' 🎀 🍓',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '💮', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Astrological Symbols and Shapes',
      prefix: '',
      suffix: '',
      map: {
        a: '♋︎', b: '♌︎', c: '♍︎', d: '♎︎', e: '♏︎', f: '♐︎', g: '♑︎',
        h: '♒︎', i: '♓︎', j: '🙰', k: '🙵', l: '●︎', m: '❍︎', n: '■︎',
        o: '□︎', p: '◻︎', q: '❑︎', r: '❒︎', s: '⬧︎', t: '⧫︎', u: '◆︎',
        v: '❖︎', w: '⬥︎', x: '⌧︎', y: '⍓︎', z: '⌘︎'
      }
    },
    {
      name: 'Stylized A-Z',
      prefix: '',
      suffix: '',
      map: {
        a: '卂', b: '乃', c: '匚', d: 'ᗪ', e: '乇', f: '千', g: 'Ꮆ',
        h: '卄', i: '丨', j: 'ﾌ', k: 'Ҝ', l: 'ㄥ', m: '爪', n: '几',
        o: 'ㄖ', p: '卩', q: 'Ɋ', r: '尺', s: '丂', t: 'ㄒ', u: 'ㄩ',
        v: 'ᐯ', w: '山', x: '乂', y: 'ㄚ', z: '乙'
      }
    },
    {
      name: 'Japanese Styled',
      prefix: '',
      suffix: '',
      map: {
        a: 'ﾑ', b: '乃', c: 'ᄃ', d: 'り', e: '乇', f: 'ｷ', g: 'ム',
        h: 'ん', i: 'ﾉ', j: 'ﾌ', k: 'ズ', l: 'ﾚ', m: 'ﾶ', n: '刀',
        o: 'の', p: 'ｱ', q: 'ゐ', r: '尺', s: '丂', t: 'ｲ', u: 'ひ',
        v: '√', w: 'W', x: 'ﾒ', y: 'ﾘ', z: '乙'
      }
    },
    {
      name: 'Stylized with Thai Influence',
      prefix: '',
      suffix: '',
      map: {
        a: 'ค', b: 'ც', c: '८', d: 'ძ', e: '૯', f: 'Բ', g: '૭',
        h: 'Һ', i: 'ɿ', j: 'ʆ', k: 'қ', l: 'Ն', m: 'ɱ', n: 'Ո',
        o: '૦', p: 'ƿ', q: 'ҩ', r: 'Ր', s: 'ς', t: '੮', u: 'υ',
        v: '౮', w: 'ω', x: '૪', y: 'ע', z: 'ઽ'
      }
    },
    {
  name: 'Stylized with Thai Influence',
  prefix: '',
  suffix: '',
  map: {
    a: 'ค', b: 'ც', c: '८', d: 'ძ', e: '૯', f: 'Բ', g: '૭',
    h: 'Һ', i: 'ɿ', j: 'ʆ', k: 'қ', l: 'Ն', m: 'ɱ', n: 'Ո',
    o: '૦', p: 'ƿ', q: 'ҩ', r: 'Ր', s: 'ς', t: '੮', u: 'υ',
    v: '౮', w: 'ω', x: '૪', y: 'ע', z: 'ઽ'
  }
},
{
  name: 'Cyrillic Styled',
  prefix: '',
  suffix: '',
  map: {
    a: 'а', b: 'б', c: 'c', d: 'д', e: 'ё', f: 'f', g: 'g',
    h: 'н', i: 'ї', j: 'j', k: 'к', l: 'г', m: 'ѫ', n: 'п',
    o: 'ѳ', p: 'p', q: 'ф', r: 'я', s: '$', t: 'т', u: 'ц',
    v: 'ѵ', w: 'щ', x: 'ж', y: 'ч', z: 'з'
  }
},
{
  name: 'Cyrillic and Greek Mix',
  prefix: '',
  suffix: '',
  map: {
    a: 'Д', b: 'Б', c: 'C', d: 'D', e: 'Ξ', f: 'F', g: 'G',
    h: 'H', i: 'I', j: 'J', k: 'Ҝ', l: 'L', m: 'M', n: 'И',
    o: 'Ф', p: 'P', q: 'Ǫ', r: 'Я', s: 'S', t: 'Γ', u: 'Ц',
    v: 'V', w: 'Щ', x: 'Ж', y: 'У', z: 'Z'
  }
},
{
  name: 'Decorative Unique Glyphs',
  prefix: '',
  suffix: '',
  map: {
    a: 'ꋫ', b: 'ꃃ', c: 'ꏸ', d: 'ꁕ', e: 'ꍟ', f: 'ꄘ', g: 'ꁍ',
    h: 'ꑛ', i: 'ꂑ', j: 'ꀭ', k: 'ꀗ', l: '꒒', m: 'ꁒ', n: 'ꁹ',
    o: 'ꆂ', p: 'ꉣ', q: 'ꁸ', r: '꒓', s: 'ꌚ', t: '꓅', u: 'ꐇ',
    v: 'ꏝ', w: 'ꅐ', x: 'ꇓ', y: 'ꐟ', z: 'ꁴ'
  }
},
{
  name: 'Gothic Styled',
  prefix: '',
  suffix: '',
  map: {
    a: 'ꍏ', b: 'ꌃ', c: 'ꉓ', d: 'ꀸ', e: 'ꍟ', f: 'ꎇ', g: 'ꁅ',
    h: 'ꃅ', i: 'ꀤ', j: 'ꀭ', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꈤ',
    o: 'ꂦ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꌗ', t: '꓄', u: 'ꀎ',
    v: 'ꃴ', w: 'ꅏ', x: 'ꊼ', y: 'ꌩ', z: 'ꁴ'
  }
},
{
  name: 'Greek Styled',
  prefix: '',
  suffix: '',
  map: {
    a: 'α', b: 'ß', c: 'ς', d: 'd', e: 'ε', f: 'ƒ', g: 'g',
    h: 'h', i: 'ï', j: 'յ', k: 'κ', l: 'ﾚ', m: 'm', n: 'η',
    o: '⊕', p: 'p', q: 'Ω', r: 'r', s: 'š', t: '†', u: 'u',
    v: '∀', w: 'ω', x: 'x', y: 'ψ', z: 'z'
  }
},
{
  name: 'Extended Greek and Cyrillic',
  prefix: '',
  suffix: '',
  map: {
    a: 'Δ', b: 'β', c: 'Ć', d: 'Đ', e: '€', f: '₣', g: 'Ǥ',
    h: 'Ħ', i: 'Ɨ', j: 'Ĵ', k: 'Ҝ', l: 'Ł', m: 'Μ', n: 'Ň',
    o: 'Ø', p: 'Ƥ', q: 'Ω', r: 'Ř', s: 'Ş', t: 'Ŧ', u: 'Ữ',
    v: 'V', w: 'Ŵ', x: 'Ж', y: '¥', z: 'Ž'
  }
},
{
  name: 'Gothic Style Name',
  prefix: '',
  suffix: '',
  map: {
    a: 'ꍏ', b: 'ꌃ', c: 'ꉓ', d: 'ꀸ', e: 'ꍟ', f: 'ꎇ', g: 'ꁅ',
    h: 'ꃅ', i: 'ꀤ', j: 'ꀭ', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꈤ',
    o: 'ꂦ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꌗ', t: '꓄', u: 'ꀎ',
    v: 'ꃴ', w: 'ꅏ', x: 'ꊼ', y: 'ꌩ', z: 'ꁴ'
  }
},
{
  name: 'Fancy Glyphs',
  prefix: '',
  suffix: '',
  map: {
    a: 'ꋬ', b: 'ꃳ', c: 'ꉔ', d: '꒯', e: 'ꏂ', f: 'ꊰ', g: 'ꍌ',
    h: 'ꁝ', i: '꒐', j: '꒻', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꋊ',
    o: 'ꄲ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꇙ', t: '꓄', u: '꒤',
    v: '꒦', w: 'ꅐ', x: 'ꉧ', y: 'ꌦ', z: 'ꁴ'
  }
},
{
  name: 'Superscript',
  prefix: '',
  suffix: '',
  map: {
    a: 'ᵃ', b: 'ᵇ', c: 'ᶜ', d: 'ᵈ', e: 'ᵉ', f: 'ᶠ', g: 'ᵍ',
    h: 'ʰ', i: 'ⁱ', j: 'ʲ', k: 'ᵏ', l: 'ˡ', m: 'ᵐ', n: 'ⁿ',
    o: 'ᵒ', p: 'ᵖ', q: 'q', r: 'ʳ', s: 'ˢ', t: 'ᵗ', u: 'ᵘ',
    v: 'ᵛ', w: 'ʷ', x: 'ˣ', y: 'ʸ', z: 'ᶻ'
  }
},
{
  name: 'Mixed Subscript and Other Styles',
  prefix: '',
  suffix: '',
  map: {
    a: 'ₐ', b: '𝚋', c: '𝚌', d: '𝚍', e: 'ₑ', f: 'f', g: 'g',
    h: '𝓱', i: 'ᵢ', j: 'ⱼ', k: '𝓴', l: 'ᄂ', m: 'ᗰ', n: '𝚗',
    o: 'ₒ', p: '𝐩', q: 'q', r: 'ᵣ', s: '𝘴', t: '𝚝', u: 'ᵤ',
    v: 'ᵥ', w: 'w', x: 'ₓ', y: 'y', z: 'z'
  }
},
{
  name: 'Small Caps',
  prefix: '',
  suffix: '',
  map: {
    a: 'ᴀ', b: 'ʙ', c: 'ᴄ', d: 'ᴅ', e: 'ᴇ', f: 'ꜰ', g: 'ɢ',
    h: 'ʜ', i: 'ɪ', j: 'ᴊ', k: 'ᴋ', l: 'ʟ', m: 'ᴍ', n: 'ɴ',
    o: 'ᴏ', p: 'ᴘ', q: 'Q', r: 'ʀ', s: 'ꜱ', t: 'ᴛ', u: 'ᴜ',
    v: 'ᴠ', w: 'ᴡ', x: 'x', y: 'ʏ', z: 'ᴢ'
  }
},
{
  name: 'Strike Through',
  prefix: '',
  suffix: '',
  map: {
    a: 'a̶', b: 'b̶', c: 'c̶', d: 'd̶', e: 'e̶', f: 'f̶', g: 'g̶',
    h: 'h̶', i: 'i̶', j: 'j̶', k: 'k̶', l: 'l̶', m: 'm̶', n: 'n̶',
    o: 'o̶', p: 'p̶', q: 'q̶', r: 'r̶', s: 's̶', t: 't̶', u: 'u̶',
    v: 'v̶', w: 'w̶', x: 'x̶', y: 'y̶', z: 'z̶'
  }
},
{
  name: 'Tilde Strike Through',
  prefix: '',
  suffix: '',
  map: {
    a: 'a̴', b: 'b̴', c: 'c̴', d: 'd̴', e: 'e̴', f: 'f̴', g: 'g̴',
    h: 'h̴', i: 'i̴', j: 'j̴', k: 'k̴', l: 'l̴', m: 'm̴', n: 'n̴',
    o: 'o̴', p: 'p̴', q: 'q̴', r: 'r̴', s: 's̴', t: 't̴', u: 'u̴',
    v: 'v̴', w: 'w̴', x: 'x̴', y: 'y̴', z: 'z̴'
  }
},
{
  name: 'Slash Through',
  prefix: '',
  suffix: '',
  map: {
    a: '𝚊̷', b: '𝚋̷', c: '𝚌̷', d: '𝚍̷', e: '𝚎̷', f: '𝚏̷', g: '𝚐̷',
    h: '𝚑̷', i: '𝚒̷', j: '𝚓̷', k: '𝚔̷', l: '𝚕̷', m: '𝚖̷', n: '𝚗̷',
    o: '𝚘̷', p: '𝚙̷', q: '𝚚̷', r: '𝚛̷', s: '𝚜̷', t: '𝚝̷', u: '𝚞̷',
    v: '𝚟̷', w: '𝚠̷', x: '𝚡̷', y: '𝚢̷', z: '𝚣̷'
  }
},
{
  name: 'Slash Through 2',
  prefix: '',
  suffix: '',
  map: {
    a: 'a̷', b: 'b̷', c: 'c̷', d: 'd̷', e: 'e̷', f: 'f̷', g: 'g̷',
    h: 'h̷', i: 'i̷', j: 'j̷', k: 'k̷', l: 'l̷', m: 'm̷', n: 'n̷',
    o: 'o̷', p: 'p̷', q: 'q̷', r: 'r̷', s: 's̷', t: 't̷', u: 'u̷',
    v: 'v̷', w: 'w̷', x: 'x̷', y: 'y̷', z: 'z̷'
  }
},
{
  name: 'Underline',
  prefix: '',
  suffix: '',
  map: {
    a: 'a̲', b: 'b̲', c: 'c̲', d: 'd̲', e: 'e̲', f: 'f̲', g: 'g̲',
    h: 'h̲', i: 'i̲', j: 'j̲', k: 'k̲', l: 'l̲', m: 'm̲', n: 'n̲',
    o: 'o̲', p: 'p̲', q: 'q̲', r: 'r̲', s: 's̲', t: 't̲', u: 'u̲',
    v: 'v̲', w: 'w̲', x: 'x̲', y: 'y̲', z: 'z̲'
  }
},
{
  name: 'Double Underline',
  prefix: '',
  suffix: '',
  map: {
    a: 'a̳', b: 'b̳', c: 'c̳', d: 'd̳', e: 'e̳', f: 'f̳', g: 'g̳',
    h: 'h̳', i: 'i̳', j: 'j̳', k: 'k̳', l: 'l̳', m: 'm̳', n: 'n̳',
    o: 'o̳', p: 'p̳', q: 'q̳', r: 'r̳', s: 's̳', t: 't̳', u: 'u̳',
    v: 'v̳', w: 'w̳', x: 'x̳', y: 'y̳', z: 'z̳'
  }
},
{
  name: 'Arrow Underline',
  prefix: '',
  suffix: '',
  map: {
    a: 'a͢', b: 'b͢', c: 'c͢', d: 'd͢', e: 'e͢', f: 'f͢', g: 'g͢',
    h: 'h͢', i: 'i͢', j: 'j͢', k: 'k͢', l: 'l͢', m: 'm͢', n: 'n͢',
    o: 'o͢', p: 'p͢', q: 'q͢', r: 'r͢', s: 's͢', t: 't͢', u: 'u͢',
    v: 'v͢', w: 'w͢', x: 'x͢', y: 'y͢', z: 'z͢'
  }
},
{
  name: 'Thai Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ค', b: '๒', c: 'ς', d: '๔', e: 'є', f: 'Ŧ', g: 'ﻮ',
    h: 'ђ', i: 'เ', j: 'ן', k: 'к', l: 'ɭ', m: '๓', n: 'ภ',
    o: '๏', p: 'ק', q: 'ợ', r: 'г', s: 'ร', t: 'Շ', u: 'ย',
    v: 'ש', w: 'ฬ', x: 'א', y: 'ץ', z: 'չ'
  }
},
{
  name: 'Greek Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'α', b: 'Ⴆ', c: 'ƈ', d: 'ԃ', e: 'ҽ', f: 'ϝ', g: 'ɠ',
    h: 'ԋ', i: 'ι', j: 'ʝ', k: 'ƙ', l: 'ʅ', m: 'ɱ', n: 'ɳ',
    o: 'σ', p: 'ρ', q: 'ϙ', r: 'ɾ', s: 'ʂ', t: 'ƚ', u: 'υ',
    v: 'ʋ', w: 'ɯ', x: 'x', y: 'ყ', z: 'ȥ'
  }
},
{
  name: 'Armenian Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ǟ', b: 'ɮ', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɢ',
    h: 'ɦ', i: 'ɨ', j: 'ʝ', k: 'ӄ', l: 'ʟ', m: 'ʍ', n: 'ռ',
    o: 'օ', p: 'ք', q: 'զ', r: 'ʀ', s: 'ֆ', t: 'ȶ', u: 'ʊ',
    v: 'ʋ', w: 'ա', x: 'Ӽ', y: 'ʏ', z: 'ʐ'
  }
},
{
  name: 'Fancy',
  prefix: '',
  suffix: '',
  map: {
    a: 'Ꮧ', b: 'Ᏸ', c: 'ፈ', d: 'Ꮄ', e: 'Ꮛ', f: 'Ꭶ', g: 'Ꮆ',
    h: 'Ꮒ', i: 'Ꭵ', j: 'Ꮰ', k: 'Ꮶ', l: 'Ꮭ', m: 'Ꮇ', n: 'Ꮑ',
    o: 'Ꭷ', p: 'Ꭾ', q: 'Ꭴ', r: 'Ꮢ', s: 'Ꮥ', t: 'Ꮦ', u: 'Ꮼ',
    v: 'Ꮙ', w: 'Ꮗ', x: 'ጀ', y: 'Ꭹ', z: 'ፚ'
  }
},
{
  name: 'Script Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ą', b: 'ც', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɠ',
    h: 'ɧ', i: 'ı', j: 'ʝ', k: 'ƙ', l: 'Ɩ', m: 'ɱ', n: 'ŋ',
    o: 'ơ', p: '℘', q: 'զ', r: 'ཞ', s: 'ʂ', t: 'ɬ', u: 'ų',
    v: '۷', w: 'ῳ', x: 'ҳ', y: 'ყ', z: 'ʑ'
  }
},
{
  name: 'Thai Script Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ค', b: '๖', c: '¢', d: '໓', e: 'ē', f: 'f', g: 'ງ',
    h: 'h', i: 'i', j: 'ว', k: 'k', l: 'l', m: '๓', n: 'ຖ',
    o: '໐', p: 'p', q: '๑', r: 'r', s: 'Ş', t: 't', u: 'น',
    v: 'ง', w: 'ຟ', x: 'x', y: 'ฯ', z: 'ຊ'
  }
},
{
  name: 'Greek Script Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'α', b: 'ზ', c: 'ƈ', d: 'ԃ', e: 'ҽ', f: 'ϝ', g: 'ɠ',
    h: 'ԋ', i: 'ι', j: 'ʝ', k: 'ƙ', l: 'ʅ', m: 'ɱ', n: 'ɳ',
    o: 'σ', p: 'ρ', q: 'ϙ', r: 'ɾ', s: 'ʂ', t: 'ƚ', u: 'υ',
    v: 'ʋ', w: 'ɯ', x: 'x', y: 'ყ', z: 'ȥ'
  }
},
{
  name: 'Romanian Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'Ă', b: 'β', c: 'Č', d: 'Ď', e: 'Ĕ', f: 'Ŧ', g: 'Ğ',
    h: 'Ĥ', i: 'Ĩ', j: 'Ĵ', k: 'Ķ', l: 'Ĺ', m: 'М', n: 'Ń',
    o: 'Ő', p: 'Р', q: 'Q', r: 'Ŕ', s: 'Ś', t: 'Ŧ', u: 'Ú',
    v: 'V', w: 'Ŵ', x: 'Ж', y: 'Ŷ', z: 'Ź'
  }
},
{
  name: 'Stylized Greek-Latin',
  prefix: '',
  suffix: '',
  map: {
    a: 'Λ', b: 'Ϧ', c: 'ㄈ', d: 'Ð', e: 'Ɛ', f: 'F', g: 'Ɠ',
    h: 'н', i: 'ɪ', j: 'ﾌ', k: 'Қ', l: 'Ł', m: '௱', n: 'Л',
    o: 'Ø', p: 'þ', q: 'Ҩ', r: '尺', s: 'ら', t: 'Ť', u: 'Ц',
    v: 'Ɣ', w: 'Ɯ', x: 'χ', y: 'Ϥ', z: 'Ẕ'
  }
},
{
  name: 'Bold Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ƛ', b: 'Ɓ', c: 'Ƈ', d: 'Ɗ', e: 'Є', f: 'Ƒ', g: 'Ɠ',
    h: 'Ӈ', i: 'Ɩ', j: 'ʆ', k: 'Ƙ', l: 'Լ', m: 'M', n: 'Ɲ',
    o: 'Ơ', p: 'Ƥ', q: 'Ƣ', r: 'Ʀ', s: 'Ƨ', t: 'Ƭ', u: 'Ʋ',
    v: 'Ɣ', w: 'Ɯ', x: 'Ҳ', y: 'Ƴ', z: 'Ȥ'
  }
},
{
  name: 'Armenian',
  prefix: '',
  suffix: '',
  map: {
    a: 'Թ', b: 'Յ', c: 'Շ', d: 'Ժ', e: 'ȝ', f: 'Բ', g: 'Գ',
    h: 'ɧ', i: 'ɿ', j: 'ʝ', k: 'ƙ', l: 'ʅ', m: 'ʍ', n: 'Ռ',
    o: 'Ծ', p: 'ρ', q: 'φ', r: 'Ր', s: 'Տ', t: 'Ե', u: 'Մ',
    v: 'ע', w: 'ա', x: 'Ճ', y: 'Վ', z: 'Հ'
  }
},
{
  name: 'Georgian',
  prefix: '',
  suffix: '',
  map: {
    a: 'მ', b: 'ჩ', c: 'ეძ', d: 'პ', e: 'f', f: 'ც', g: 'h',
    h: 'ἶ', i: 'ქ', j: 'κ', k: 'l', l: 'ო', m: 'ῆ', n: 'õ',
    o: 'ρ', p: 'გ', q: 'Γ', r: 'ჰ', s: 'ན', t: 'υ', u: 'ὗ',
    v: 'w', w: 'ჯ', x: 'ყ', y: 'ɀ', z: ''
  }
},
{
  name: 'Script Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'α', b: 'ɓ', c: '૮', d: '∂', e: 'ε', f: 'ƒ', g: 'ɠ',
    h: 'ɦ', i: 'เ', j: 'ʝ', k: 'ҡ', l: 'ℓ', m: 'ɱ', n: 'ɳ',
    o: 'σ', p: 'ρ', q: 'φ', r: '૨', s: 'ร', t: 'ƭ', u: 'µ',
    v: 'ѵ', w: 'ω', x: 'א', y: 'ყ', z: 'ƶ'
  }
},
{
  name: 'Greek Script Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ά', b: 'в', c: 'ς', d: 'ȡ', e: 'έ', f: 'ғ', g: 'ģ',
    h: 'ħ', i: 'ί', j: 'ј', k: 'ķ', l: 'Ļ', m: 'м', n: 'ή',
    o: 'ό', p: 'ρ', q: 'q', r: 'ŕ', s: 'ş', t: 'ţ', u: 'ù',
    v: 'ν', w: 'ώ', x: 'x', y: 'ч', z: 'ž'
  }
},
{
  name: 'Combining Characters Name Style',
  prefix: '',
  suffix: '',
  map: {
    a: 'ą̶̰͈̩̇', b: 'b̷̜̺͚̺͇̳̟͔̃̓̈́̈́͋͘͘͜͝', c: 'c̸̭̼͓̼̈́͑͋̍̈́̍̄͠͝',
    d: 'ḑ̶̢̜̺̟̽̈́̈́̾̿', e: 'ę̷̣̜̘̘̗̣̠̟͍̆͊͊', f: 'f̸̦̻͉̍̓̈̃͜͜',
    g: 'g̸͎͈̜̤̑̎͌̃', h: 'h̸̡̙͌̕͝͝', i: 'i̴̛͕̬͆', j: 'j̴̩̓͆̅͒̎̈́͗͐̚͠',
    k: 'ḱ̸̢̗͉̹͖̝͙͎̥̅̀͑̉̕͝', l: 'l̶̨̬̻̤͙̉̿ͅ', m: 'ḿ̸̹͖͚͉̤̩̥̒̇̋͑͛͛̊̓̑͜',
    n: 'n̵̮̺̝͓̈́͂͗̓ͅ', o: 'o̴͖̙̜͔̅̏͆͌̑̒̀͋', p: 'p̵̢̦̖̥̞̈́͌͜',
    q: 'q̴̮̳͍̺̯̣̖̊̓́͆͐̚', r: 'r̷̨͈̦̘̳͚̥͓͖̂͛̃̀̅̉̾̐', s: 'ş̴̰̼̩̳̏͆̆̈́̂̎̀̉̕',
    t: 't̵̫̝̙̗̖̾͑͆', u: 'u̵̡̩̾͐̓̀̿͜', v: 'v̴͚̗̠̖̱̩͎̱̌͜', 
    w: 'ẅ̴͙͔̗̱̞̙͝', x: 'x̴̤̪̬̰͙͙͌́́͑̈́̋̽̌̓̈͜', y: 'ȳ̷̧̜̟̰̖̲̳͕̕', z: 'z̶͕͇͒̏͒̈́́̐̀̈́'
  }
},
{
  "name": "Gothic",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝔞", "b": "𝔟", "c": "𝔠", "d": "𝔡", "e": "𝔢", "f": "𝔣", "g": "𝔤",
    "h": "𝔥", "i": "𝔦", "j": "𝔧", "k": "𝔨", "l": "𝔩", "m": "𝔪", "n": "𝔫",
    "o": "𝔬", "p": "𝔭", "q": "𝔮", "r": "𝔯", "s": "𝔰", "t": "𝔱", "u": "𝔲",
    "v": "𝔳", "w": "𝔴", "x": "𝔵", "y": "𝔶", "z": "𝔷"
  }
},
{
  "name": "Fraktur",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝖆", "b": "𝖇", "c": "𝖈", "d": "𝖉", "e": "𝖊", "f": "𝖋", "g": "𝖌",
    "h": "𝖍", "i": "𝖎", "j": "𝖏", "k": "𝖐", "l": "𝖑", "m": "𝖒", "n": "𝖓",
    "o": "𝖔", "p": "𝖕", "q": "𝖖", "r": "𝖗", "s": "𝖘", "t": "𝖙", "u": "𝖚",
    "v": "𝖛", "w": "𝖜", "x": "𝖝", "y": "𝖞", "z": "𝖟"
  }
},
{
  "name": "Fancy Script",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝓪", "b": "𝓫", "c": "𝓬", "d": "𝓭", "e": "𝓮", "f": "𝓯", "g": "𝓰",
    "h": "𝓱", "i": "𝓲", "j": "𝓳", "k": "𝓴", "l": "𝓵", "m": "𝓶", "n": "𝓷",
    "o": "𝓸", "p": "𝓹", "q": "𝓺", "r": "𝓻", "s": "𝓼", "t": "𝓽", "u": "𝓾",
    "v": "𝓿", "w": "𝔀", "x": "𝔁", "y": "𝔂", "z": "𝔃"
  }
},
{
  "name": "Fancy Script 2",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝒶", "b": "𝒷", "c": "𝒸", "d": "𝒹", "e": "𝑒", "f": "𝒻", "g": "𝑔",
    "h": "𝒽", "i": "𝒾", "j": "𝒿", "k": "𝓀", "l": "𝓁", "m": "𝓂", "n": "𝓃",
    "o": "𝑜", "p": "𝓅", "q": "𝓆", "r": "𝓇", "s": "𝓈", "t": "𝓉", "u": "𝓊",
    "v": "𝓋", "w": "𝓌", "x": "𝓍", "y": "𝓎", "z": "𝓏"
  }
},
{
  "name": "Double-Struck",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝕒", "b": "𝕓", "c": "𝕔", "d": "𝕕", "e": "𝕖", "f": "𝕗", "g": "𝕘",
    "h": "𝕙", "i": "𝕚", "j": "𝕛", "k": "𝕜", "l": "𝕝", "m": "𝕞", "n": "𝕟",
    "o": "𝕠", "p": "𝕡", "q": "𝕢", "r": "𝕣", "s": "𝕤", "t": "𝕥", "u": "𝕦",
    "v": "𝕧", "w": "𝕨", "x": "𝕩", "y": "𝕪", "z": "𝕫"
  }
},
{
  "name": "Italic",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝘢", "b": "𝘣", "c": "𝘤", "d": "𝘥", "e": "𝘦", "f": "𝘧", "g": "𝘨",
    "h": "𝘩", "i": "𝘪", "j": "𝘫", "k": "𝘬", "l": "𝘭", "m": "𝘮", "n": "𝘯",
    "o": "𝘰", "p": "𝘱", "q": "𝘲", "r": "𝘳", "s": "𝘴", "t": "𝘵", "u": "𝘶",
    "v": "𝘷", "w": "𝘸", "x": "𝘹", "y": "𝘺", "z": "𝘻"
  }
},
{
  "name": "Bold Italic",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝙖", "b": "𝙗", "c": "𝙘", "d": "𝙙", "e": "𝙚", "f": "𝙛", "g": "𝙜",
    "h": "𝙝", "i": "𝙞", "j": "𝙟", "k": "𝙠", "l": "𝙡", "m": "𝙢", "n": "𝙣",
    "o": "𝙤", "p": "𝙥", "q": "𝙦", "r": "𝙧", "s": "𝙨", "t": "𝙩", "u": "𝙪",
    "v": "𝙫", "w": "𝙬", "x": "𝙭", "y": "𝙮", "z": "𝙯"
  }
},
{
  "name": "Monospace",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝚊", "b": "𝚋", "c": "𝚌", "d": "𝚍", "e": "𝚎", "f": "𝚏", "g": "𝚐",
    "h": "𝚑", "i": "𝚒", "j": "𝚓", "k": "𝚔", "l": "𝚕", "m": "𝚖", "n": "𝚗",
    "o": "𝚘", "p": "𝚙", "q": "𝚚", "r": "𝚛", "s": "𝚜", "t": "𝚝", "u": "𝚞",
    "v": "𝚟", "w": "𝚠", "x": "𝚡", "y": "𝚢", "z": "𝚣"
  }
},

{
  "name": "Circled",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "ⓐ", "b": "ⓑ", "c": "ⓒ", "d": "ⓓ", "e": "ⓔ", "f": "ⓕ", "g": "ⓖ",
    "h": "ⓗ", "i": "ⓘ", "j": "ⓙ", "k": "ⓚ", "l": "ⓛ", "m": "ⓜ", "n": "ⓝ",
    "o": "ⓞ", "p": "ⓟ", "q": "ⓠ", "r": "ⓡ", "s": "ⓢ", "t": "ⓣ", "u": "ⓤ",
    "v": "ⓥ", "w": "ⓦ", "x": "ⓧ", "y": "ⓨ", "z": "ⓩ"
  }
},
{
  "name": "Square",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "🄰", "b": "🄱", "c": "🄲", "d": "🄳", "e": "🄴", "f": "🄵", "g": "🄶",
    "h": "🄷", "i": "🄸", "j": "🄹", "k": "🄺", "l": "🄻", "m": "🄼", "n": "🄽",
    "o": "🄾", "p": "🄿", "q": "🅀", "r": "🅁", "s": "🅂", "t": "🅃", "u": "🅄",
    "v": "🅅", "w": "🅆", "x": "🅇", "y": "🅈", "z": "🅉"
  }
},
{
  "name": "Stylish Name",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "🅐", "b": "🅑", "c": "🅒", "d": "🅓", "e": "🅔", "f": "🅕", "g": "🅖",
    "h": "🅗", "i": "🅘", "j": "🅙", "k": "🅚", "l": "🅛", "m": "🅜", "n": "🅝",
    "o": "🅞", "p": "🅟", "q": "🅠", "r": "🅡", "s": "🅢", "t": "🅣", "u": "🅤",
    "v": "🅥", "w": "🅦", "x": "🅧", "y": "🅨", "z": "🅩"
  }
},
{
  "name": "Fullwidth",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "ａ", "b": "ｂ", "c": "ｃ", "d": "ｄ", "e": "ｅ", "f": "ｆ", "g": "ｇ",
    "h": "ｈ", "i": "ｉ", "j": "ｊ", "k": "ｋ", "l": "ｌ", "m": "ｍ", "n": "ｎ",
    "o": "ｏ", "p": "ｐ", "q": "ｑ", "r": "ｒ", "s": "ｓ", "t": "ｔ", "u": "ｕ",
    "v": "ｖ", "w": "ｗ", "x": "ｘ", "y": "ｙ", "z": "ｚ"
  }
},
{
  "name": "Bold Serif",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "𝐚", "b": "𝐛", "c": "𝐜", "d": "𝐝", "e": "𝐞", "f": "𝐟", "g": "𝐠",
    "h": "𝐡", "i": "𝐢", "j": "𝐣", "k": "𝐤", "l": "𝐥", "m": "𝐦", "n": "𝐧",
    "o": "𝐨", "p": "𝐩", "q": "𝐪", "r": "𝐫", "s": "𝐬", "t": "𝐭", "u": "𝐮",
    "v": "𝐯", "w": "𝐰", "x": "𝐱", "y": "𝐲", "z": "𝐳"
  }
},
{
  "name": "Upside Down",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "ɐ", "b": "q", "c": "ɔ", "d": "p", "e": "ǝ", "f": "ɟ", "g": "ƃ",
    "h": "ɥ", "i": "ᴉ", "j": "ɾ", "k": "ʞ", "l": "ʃ", "m": "ɯ", "n": "u",
    "o": "o", "p": "d", "q": "b", "r": "ɹ", "s": "s", "t": "ʇ", "u": "n",
    "v": "ʌ", "w": "ʍ", "x": "x", "y": "ʎ", "z": "z"
  }
},
{
  "name": "Mirrored",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "ɒ", "b": "d", "c": "Ↄ", "d": "b", "e": "ɘ", "f": "ʇ", "g": "ǫ",
    "h": "ɥ", "i": "i", "j": "ſ", "k": "ʞ", "l": "l", "m": "м", "n": "и",
    "o": "o", "p": "q", "q": "p", "r": "ɿ", "s": "s", "t": "t", "u": "u",
    "v": "v", "w": "ʍ", "x": "x", "y": "ʎ", "z": "z"
  }
},
{
  "name": "Classic Boxed",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "🄰", "b": "🄱", "c": "🄲", "d": "🄳", "e": "🄴", "f": "🄵", "g": "🄶",
    "h": "🄷", "i": "🄸", "j": "🄹", "k": "🄺", "l": "🄻", "m": "🄼", "n": "🄽",
    "o": "🄾", "p": "🄿", "q": "🅀", "r": "🅁", "s": "🅂", "t": "🅃", "u": "🅄",
    "v": "🅅", "w": "🅆", "x": "🅇", "y": "🅈", "z": "🅉"
  }
},
{
  "name": "Reversed",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "ɒ", "b": "d", "c": "Ↄ", "d": "b", "e": "ɘ", "f": "ʇ", "g": "ǫ",
    "h": "ɥ", "i": "i", "j": "ſ", "k": "ʞ", "l": "l", "m": "м", "n": "и",
    "o": "o", "p": "q", "q": "p", "r": "ɿ", "s": "s", "t": "t", "u": "u",
    "v": "v", "w": "ʍ", "x": "x", "y": "ʎ", "z": "z"
  }
},
{
  "name": "Greek Script Style",
  "prefix": "",
  "suffix": "",
  "map": {
    "a": "ά", "b": "в", "c": "ς", "d": "ȡ", "e": "έ", "f": "ғ", "g": "ģ",
    "h": "ħ", "i": "ί", "j": "ј", "k": "ķ", "l": "Ļ", "m": "м", "n": "ή",
    "o": "ό", "p": "ρ", "q": "q", "r": "ŕ", "s": "ş", "t": "ţ", "u": "ù",
    "v": "ν", "w": "ώ", "x": "x", "y": "ч", "z": "ž"
  }
}






































    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
        
    
    
        
    
    
    
    
    
    
    
    
  ];

  const convertText = (text, map) => {
    return text.split('').map(char => map[char] || char).join('');
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  const applyStyle = (text, font) => {
    const prefix = font.prefix || '';
    const suffix = font.suffix || '';
    return `${prefix}${convertText(text, font.map)}${suffix}`;
  };

  return (
    <div className="font-changer">
    
  
      
    {fonts.slice(0, maxDisplay || fonts.length).map((font, index) => (
          <div key={index} className="font-container">
            <h2>{font.name}</h2>
            <div 
              className="styled-text" 
              style={{ color: textColor, fontSize: `${fontSize}px` }}
            >
              {applyStyle(inputText, font)}
            </div>
            <button 
              className="copy-button" 
              onClick={() => handleCopy(applyStyle(inputText, font), index)}
            >
              {copiedIndex === index ? 'Copied' : 'Copy'}
            </button>
          </div>
        ))}
      
      <section class="content-section">
      <strong>Generate Stunning Stylish Names Effortlessly</strong>
        <p>Welcome to our <strong>Stylish Name Generator</strong> on thefontchanger.com! Do you want to make a name that would create an impression on the first sight? Introducing the <a href="https://thefontchanger.com">Name Generator</a> tool – a wonderful helping hand when it comes to designing memorable and attractive names for brands, products or services. If you are looking for an addition to your social networks, if you work on creating inviting graphics, or if you just like creating with text, you have a wide selection of beautiful fonts to customize with at Once Upon a Typeface.</p>
        <h2>Why Use Our Stylish Name Generator?</h2>
        <h3>1. Easy to Use:</h3>
        <p>Just as we mentioned earlier, our <strong>Stylish Name Generator</strong> is as simple as possible. All you have to do is type the desired text, select from one of several eye-catching fonts, and witness the change.</p>
        <h3>2. Variety of Styles:</h3>
        <p>From elegant scripts to bold typography, our <a href="https://thefontchanger.com">Name Generator</a> offers a wide range of font styles to suit your needs. Find the perfect style that makes your name unique and stylish.</p>
        <h3>3. Quick Results:</h3>
        <p>Experience real-time text transformation. Our generator provides instant results, allowing you to see your stylish name immediately.</p>
        <h3>4. Perfect for Various Applications:</h3>
        <p>If you need to change the avatar, write under the nickname, come up with a unique and memorable username or add the text with an incredible color and style to the design, <b>Stylish Name Generator</b> would be exactly what you have been looking for!</p>
     
      
        <h2>How to Use the Stylish Name Generator</h2>
        <p>Enter Your Text: Start by typing the text you want to stylize into the input box on our <a href="https://thefontchanger.com">Name Generator</a>.</p>
        <p>Choose Your Font Style: Browse through our diverse collection of fonts and select the style that best fits your needs.</p>
        <p>Copy and Use: Once you’re pleased with your stylish name, simply copy it and use it anywhere you like.</p>
        <h2>Applications of Stylish Names</h2>
        <p>Social Media Profiles: Stand out on Instagram, Facebook, and Twitter with unique <a href="https://thefontchanger.com/decorated-text">Decorated Text</a> and <strong>stylish names</strong>.</p>
        <p>Usernames: Create distinctive and memorable usernames for games like PUBG or Free Fire, forums, and other online platforms.</p>
        <p>Graphic Design: Enhance your design projects with visually appealing text styles that capture attention.</p>
        <p>Messages and Emails: Add a fun and aesthetic element to your messages and emails using our <a href="https://thefontchanger.com">stylish name generator</a>.</p>
        <h2>Features of TheFontChanger.com’s Stylish Name Generator</h2>
        <p>Mobile Responsive: Employ our <a href="https://thefontchanger.com/font-changer">Font Changer</a> as a mobile app on gadgets of any kind including smartphones, tablets, or PCs.</p>
        <p>Secure and Reliable: It is very safe and smooth to generate wonderful names while being assured of the above points.</p>
        <h2>Start Generating Stylish Names Today!</h2>
        <p>Why settle for ordinary text when you can create extraordinary <a href="https://thefontchanger.com/glitch-text-generator">Glitch Text</a> with our generator.Make your names stand out with ease. Transform your text into something spectacular and see the difference it can make!</p>
      </section>
    </div>
  );
};

export default StylishNameGenerator;