import React, { useState } from 'react';
import './FontChanger.css';

const FontChanger = ({ inputText, textColor, fontSize }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const fonts = [
    {
      name: 'Fraktur',
      prefix: '',
      suffix: '',
      map: {
        a: '𝔞', b: '𝔟', c: '𝔠', d: '𝔡', e: '𝔢', f: '𝔣', g: '𝔤',
        h: '𝔥', i: '𝔦', j: '𝔧', k: '𝔨', l: '𝔩', m: '𝔪', n: '𝔫',
        o: '𝔬', p: '𝔭', q: '𝔮', r: '𝔯', s: '𝔰', t: '𝔱', u: '𝔲',
        v: '𝔳', w: '𝔴', x: '𝔵', y: '𝔶', z: '𝔷'
      }
    },
    {
      name: 'Blackletter',
      prefix: '',
      suffix: '',
      map: {
        a: '𝖆', b: '𝖇', c: '𝖈', d: '𝖉', e: '𝖊', f: '𝖋', g: '𝖌',
        h: '𝖍', i: '𝖎', j: '𝖏', k: '𝖐', l: '𝖑', m: '𝖒', n: '𝖓',
        o: '𝖔', p: '𝖕', q: '𝖖', r: '𝖗', s: '𝖘', t: '𝖙', u: '𝖚',
        v: '𝖛', w: '𝖜', x: '𝖝', y: '𝖞', z: '𝖟'
      }
    },
    {
      name: 'Fancy Mix',
      prefix: '♘♚ ',
      suffix: ' ♞🐻',
      map: {
        a: '𝐚', b: 'ᗷ', c: '℃', d: 'ᗪ', e: 'є', f: '𝔽', g: 'ᵍ',
        h: '𝓗', i: '𝒾', j: 'ⓙ', k: '𝓴', l: '𝐥', m: 'Μ', n: 'ภ',
        o: 'Ø', p: 'Ƥ', q: 'Ω', r: 'Ř', s: '丂', t: 'ⓣ', u: 'Ữ',
        v: 'ⓥ', w: 'Ŵ', x: 'ˣ', y: 'ʸ', z: '𝔃'
      }
    },
    {
      name: 'Cursive',
      prefix: '',
      suffix: '',
      map: {
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Bold Script',
      prefix: '',
      suffix: '',
      map: {
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '𝑜', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Monospace',
      prefix: '',
      suffix: '',
      map: {
        a: '𝕒', b: '𝕓', c: '𝕔', d: '𝕕', e: '𝕖', f: '𝕗', g: '𝕘',
        h: '𝕙', i: '𝕚', j: '𝕛', k: '𝕜', l: '𝕝', m: '𝕞', n: '𝕟',
        o: '𝕠', p: '𝕡', q: '𝕢', r: '𝕣', s: '𝕤', t: '𝕥', u: '𝕦',
        v: '𝕧', w: '𝕨', x: '𝕩', y: '𝕪', z: '𝕫'
      }
    },
    {
      name: 'Fullwidth',
      prefix: '',
      suffix: '',
      map: {
        a: 'ａ',
        b: '𝒃', c: '𝒄', d: '𝒅', e: '𝒆', f: '𝒇', g: '𝒈',
        h: '𝒉', i: '𝒊', j: '𝒋', k: '𝒌', l: '𝒍', m: '𝒎', n: '𝒏',
        o: '𝒐', p: '𝒑', q: '𝒒', r: '𝒓', s: '𝒔', t: '𝒕', u: '𝒖',
        v: '𝒗', w: '𝒘', x: '𝒙', y: '𝒚', z: '𝒛'
      }
    },
    {
      name: 'Small Caps',
      prefix: '',
      suffix: '',
      map: {
        a: 'ᴀ', b: 'ʙ', c: 'ᴄ', d: 'ᴅ', e: 'ᴇ', f: 'ꜰ', g: 'ɢ',
        h: 'ʜ', i: 'ɪ', j: 'ᴊ', k: 'ᴋ', l: 'ʟ', m: 'ᴍ', n: 'ɴ',
        o: 'ᴏ', p: 'ᴘ', q: 'Q', r: 'ʀ', s: 'ꜱ', t: 'ᴛ', u: 'ᴜ',
        v: 'ᴠ', w: 'ᴡ', x: 'x', y: 'ʏ', z: 'ᴢ'
      }
    },
    {
      name: 'Upside Down',
      prefix: '',
      suffix: '',
      map: {
        a: 'ɐ', b: 'q', c: 'ɔ', d: 'p', e: 'ǝ', f: 'ɟ', g: 'ƃ',
        h: 'ɥ', i: 'ı', j: 'ɾ', k: 'ʞ', l: 'l', m: 'ɯ', n: 'u',
        o: 'o', p: 'd', q: 'b', r: 'ɹ', s: 's', t: 'ʇ', u: 'n',
        v: 'ʌ', w: 'ʍ', x: 'x', y: 'ʎ', z: 'z'
      }
    },
    {
      name: 'Cool Emoticon',
      prefix: '(⌐■_■)',
      suffix: '(⌐■_■)',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Random Emoticons 1',
      prefix: '(ʘ‿ʘ)',
      suffix: '(ʘ‿ʘ)',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    }
    
    // Add other font mappings here...
  ];

 
  const convertText = (text, map) => {
    return text.split('').map(char => map[char] || char).join('');
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  const applyStyle = (text, font) => {
    const prefix = font.prefix || '';
    const suffix = font.suffix || '';
    return `${prefix}${convertText(text, font.map)}${suffix}`;
  };

  return (
    <div className="font-changer">
      <div className="styled-texts">
        {fonts.map((font, index) => (
          <div key={index} className="font-container">
            <h2>{font.name}</h2>
            <div 
              className="styled-text" 
              style={{ color: textColor, fontSize: `${fontSize}px` }}
            >
              {applyStyle(inputText, font)}
            </div>
            <button 
              className="copy-button" 
              onClick={() => handleCopy(applyStyle(inputText, font), index)}
            >
              {copiedIndex === index ? 'Copied' : 'Copy'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FontChanger;