import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import FontChanger from './components/FontChanger';
import FontsForFacebook from './components/FontsForFacebook';
import StylishNameGenerator from './components/StylishNameGenerator';
import CalligraphyFontGenerator from './components/CalligraphyFontGenerator';
import DecoratedText from './components/DecoratedText';
import SquiggleFancyFonts from './components/SquiggleFancyFonts';
import FancyFonts from './components/FancyFonts';
import FancyTextGenerator from './components/FancyTextGenerator';
import GlitchTextGenerator from './components/GlitchTextGenerator';
import TextFonts from './components/TextFonts';
import FancyLetters from './components/FancyLetters';
import CursiveTextGenerator from './components/CursiveTextGenerator';
import CommentSection from './components/CommentSection';
import FontSlider from './components/FontSlider';
import FontHistory from './components/FontHistory';

const App = () => {
  return (
    <Router>
      <MainApp />
    </Router>
  );
};

const MainApp = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const [inputText, setInputText] = useState('Font Changer');
  const [textColor, setTextColor] = useState('#000000');
  const [fontSize, setFontSize] = useState(20);
  const [headerText, setHeaderText] = useState('Font Changer App');
  const [fonts, setFonts] = useState(['Arial', 'Times New Roman', 'Courier New']); // Example fonts
  const [fontHistory, setFontHistory] = useState([]);
  const [copied, setCopied] = useState(false);

  const fontMappings = [
    {
      name: 'Random 1',
      prefix: '☞♟',
      suffix: '✊☺',
      map: {
        a: '₳', b: '฿', c: '₵', d: 'Đ', e: 'Ɇ', f: '₣', g: '₲',
        h: 'Ⱨ', i: 'ł', j: 'J', k: '₭', l: 'Ⱡ', m: '₥', n: '₦',
        o: 'Ø', p: '₱', q: 'Q', r: 'Ɽ', s: '₴', t: '₮', u: 'Ʉ',
        v: 'V', w: '₩', x: 'Ӿ', y: 'Ɏ', z: 'Ⱬ'
      }
    },
    {
      name: 'Random 2',
      prefix: '♚☺',
      suffix: '🎯♪',
      map: {
        a: 'ⓐ', b: '𝓫', c: '𝓒', d: '๔', e: 'ⓔ', f: 'ᶠ', g: 'ﻮ',
        h: 'Ⓗ', i: '𝒾', j: 'נ', k: 'к', l: '𝕝', m: '𝓶', n: 'ℕ',
        o: '𝓞', p: '𝕡', q: 'ᵠ', r: '尺', s: 'ş', t: '𝓽', u: '𝐮',
        v: '𝕧', w: 'Ⓦ', x: 'א', y: 'Ⓨ', z: '𝔷'
      }
    },
{
      name: 'Random 3',
      prefix: '•.¸¸.•',
      suffix: '•.¸¸.•',
      map: {
        a: 'ꍏ', b: '♭', c: '☾', d: '◗', e: '€', f: 'Ϝ', g: '❡',
        h: '♄', i: '♗', j: '♪', k: 'ϰ', l: '↳', m: '♔', n: '♫',
        o: '🌸', p: 'ρ', q: '☭', r: '☈', s: 'ⓢ', t: '☂', u: '☋',
        v: '✓', w: 'ω', x: '⌘', y: '☿', z: '☡'
      }
    },
    {
      name: 'Random 4',
      prefix: '🐫 ⋆ 🐠',
      suffix: '🐠 ⋆ 🐫',
      map: {
        a: '𝚊', b: '𝚋', c: '𝚌', d: '𝚍', e: '𝚎', f: '𝚏', g: '𝚐',
        h: '𝚑', i: '𝚒', j: '𝚓', k: '𝚔', l: '𝚕', m: '𝚖', n: '𝚗',
        o: '❤', p: '𝚙', q: '𝚚', r: '𝚛', s: '𝚜', t: '𝚝', u: '𝚞',
        v: '𝚟', w: '𝚠', x: '𝚡', y: '𝚢', z: '𝚣'
      }
    },
    {
      name: 'Random 5',
      prefix: '«--(¯`v´¯)--»',
      suffix: '«--(¯`v´¯)--»',
      map: {
        a: 'ค', b: '๖', c: '¢', d: '໓', e: 'ē', f: 'f', g: 'ງ',
        h: 'hi', i: 'ว', j: 'k', k: '๓', l: 'ຖ', m: '໐', n: 'p',
        o: '๑', p: 'r', q: 'Ş', r: 't', s: 'น', t: 'ง', u: 'ຟ',
        v: 'x', w: 'ฯ', x: 'ຊ', y: '«--(¯`v´¯)--»', z: '«--(¯`v´¯)--»'
      }
    },
    {
      name: 'Random 6',
      prefix: '↤↤↤↤↤',
      suffix: '↦↦↦↦↦',
      map: {
        a: 'a̴', b: 'b̴', c: 'c̴', d: 'd̴', e: 'e̴', f: 'f̴', g: 'g̴',
        h: 'h̴', i: 'i̴', j: 'j̴', k: 'k̴', l: 'l̴', m: 'm̴', n: 'n̴',
        o: 'o̴', p: 'p̴', q: 'q̴', r: 'r̴', s: 's̴', t: 't̴', u: 'u̴',
        v: 'v̴', w: 'w̴', x: 'x̴', y: 'y̴', z: 'z̴'
      }
    },

    {
      name: 'Fraktur',
      map: {
        A: '𝔄', B: '𝔅', C: 'ℭ', D: '𝔇', E: '𝔈', F: '𝔉', G: '𝔊',
        H: 'ℌ', I: 'ℑ', J: '𝔍', K: '𝔎', L: '𝔏', M: '𝔐', N: '𝔑',
        O: '𝔒', P: '𝔓', Q: '𝔔', R: 'ℜ', S: '𝔖', T: '𝔗', U: '𝔘',
        V: '𝔙', W: '𝔚', X: '𝔛', Y: '𝔜', Z: 'ℨ',
        a: '𝔞', b: '𝔟', c: '𝔠', d: '𝔡', e: '𝔢', f: '𝔣', g: '𝔤',
        h: '𝔥', i: '𝔦', j: '𝔧', k: '𝔨', l: '𝔩', m: '𝔪', n: '𝔫',
        o: '𝔬', p: '𝔭', q: '𝔮', r: '𝔯', s: '𝔰', t: '𝔱', u: '𝔲',
        v: '𝔳', w: '𝔴', x: '𝔵', y: '𝔶', z: '𝔷'
      }
    },
    {
      name: 'Bold Fraktur',
      map: {
        A: '𝕬', B: '𝕭', C: '𝕮', D: '𝕯', E: '𝕰', F: '𝕱', G: '𝕲',
        H: '𝕳', I: '𝕴', J: '𝕵', K: '𝕶', L: '𝕷', M: '𝕸', N: '𝕹',
        O: '𝕺', P: '𝕻', Q: '𝕼', R: '𝕽', S: '𝕾', T: '𝕿', U: '𝖀',
        V: '𝖁', W: '𝖂', X: '𝖃', Y: '𝖄', Z: '𝖅',
        a: '𝖆', b: '𝖇', c: '𝖈', d: '𝖉', e: '𝖊', f: '𝖋', g: '𝖌',
        h: '𝖍', i: '𝖎', j: '𝖏', k: '𝖐', l: '𝖑', m: '𝖒', n: '𝖓',
        o: '𝖔', p: '𝖕', q: '𝖖', r: '𝖗', s: '𝖘', t: '𝖙', u: '𝖚',
        v: '𝖛', w: '𝖜', x: '𝖝', y: '𝖞', z: '𝖟'
      }
    },
    {
      name: 'Double Struck',
      map: {
        A: '𝔸', B: '𝔹', C: 'ℂ', D: '𝔻', E: '𝔼', F: '𝔽', G: '𝔾',
        H: 'ℍ', I: '𝕀', J: '𝕁', K: '𝕂', L: '𝕃', M: '𝕄', N: 'ℕ',
        O: '𝕆', P: 'ℙ', Q: 'ℚ', R: 'ℝ', S: '𝕊', T: '𝕋', U: '𝕌',
        V: '𝕍', W: '𝕎', X: '𝕏', Y: '𝕐', Z: 'ℤ',
        a: '𝕒', b: '𝕓', c: '𝕔', d: '𝕕', e: '𝕖', f: '𝕗', g: '𝕘',
        h: '𝕙', i: '𝕚', j: '𝕛', k: '𝕜', l: '𝕝', m: '𝕞', n: '𝕟',
        o: '𝕠', p: '𝕡', q: '𝕢', r: '𝕣', s: '𝕤', t: '𝕥', u: '𝕦',
        v: '𝕧', w: '𝕨', x: '𝕩', y: '𝕪', z: '𝕫'
      }
    },
    {
      name: 'Cursive',
      map: {
        A: '𝒜', B: '𝐵', C: '𝒞', D: '𝒟', E: '𝐸', F: '𝐹', G: '𝒢',
        H: '𝐻', I: '𝐼', J: '𝒥', K: '𝒦', L: '𝐿', M: '𝑀', N: '𝒩',
        O: '𝒪', P: '𝒫', Q: '𝒬', R: '𝑅', S: '𝒮', T: '𝒯', U: '𝒰',
        V: '𝒱', W: '𝒲', X: '𝒳', Y: '𝒴', Z: '𝒵',
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '𝑜', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Bold Cursive',
      map: {
        A: '𝓐', B: '𝓑', C: '𝓒', D: '𝓓', E: '𝓔', F: '𝓕', G: '𝓖',
        H: '𝓗', I: '𝓘', J: '𝓙', K: '𝓚', L: '𝓛', M: '𝓜', N: '𝓝',
        O: '𝓞', P: '𝓟', Q: '𝓠', R: '𝓡', S: '𝓢', T: '𝓣', U: '𝓤',
        V: '𝓥', W: '𝓦', X: '𝓧', Y: '𝓨', Z: '𝓩',
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Monospace',
      map: {
        A: '𝙰', B: '𝙱', C: '𝙲', D: '𝙳', E: '𝙴', F: '𝙵', G: '𝙶',
        H: '𝙷', I: '𝙸', J: '𝙹', K: '𝙺', L: '𝙻', M: '𝙼', N: '𝙽',
        O: '𝙾', P: '𝙿', Q: '𝚀', R: '𝚁', S: '𝚂', T: '𝚃', U: '𝚄',
        V: '𝚅', W: '𝚆', X: '𝚇', Y: '𝚈', Z: '𝚉',
        a: '𝚊', b: '𝚋', c: '𝚌', d: '𝚍', e: '𝚎', f: '𝚏', g: '𝚐',
        h: '𝚑', i: '𝚒', j: '𝚓', k: '𝚔', l: '𝚕', m: '𝚖', n: '𝚗',
        o: '𝚘', p: '𝚙', q: '𝚚', r: '𝚛', s: '𝚜', t: '𝚝', u: '𝚞',
        v: '𝚟', w: '𝚠', x: '𝚡', y: '𝚢', z: '𝚣'
      }
    },
    {
      name: 'Bubbles',
      map: {
        A: 'Ⓐ', B: 'Ⓑ', C: 'Ⓒ', D: 'Ⓓ', E: 'Ⓔ', F: 'Ⓕ', G: 'Ⓖ',
        H: 'Ⓗ', I: 'Ⓘ', J: 'Ⓙ', K: 'Ⓚ', L: 'Ⓛ', M: 'Ⓜ', N: 'Ⓝ',
        O: 'Ⓞ', P: 'Ⓟ', Q: 'Ⓠ', R: 'Ⓡ', S: 'Ⓢ', T: 'Ⓣ', U: 'Ⓤ',
        V: 'Ⓥ', W: 'Ⓦ', X: 'Ⓧ', Y: 'Ⓨ', Z: 'Ⓩ',
        a: 'ⓐ', b: 'ⓑ', c: 'ⓒ', d: 'ⓓ', e: 'ⓔ', f: 'ⓕ', g: 'ⓖ',
        h: 'ⓗ', i: 'ⓘ', j: 'ⓙ', k: 'ⓚ', l: 'ⓛ', m: 'ⓜ', n: 'ⓝ',
        o: 'ⓞ', p: 'ⓟ', q: 'ⓠ', r: 'ⓡ', s: 'ⓢ', t: 'ⓣ', u: 'ⓤ',
        v: 'ⓥ', w: 'ⓦ', x: 'ⓧ', y: 'ⓨ', z: 'ⓩ'
      }
    },
    {
      name: 'Dark Bubbles',
      map: {
        A: '🅐', B: '🅑', C: '🅒', D: '🅓', E: '🅔', F: '🅕', G: '🅖',
        H: '🅗', I: '🅘', J: '🅙', K: '🅚', L: '🅛', M: '🅜', N: '🅝',
        O: '🅞', P: '🅟', Q: '🅠', R: '🅡', S: '🅢', T: '🅣', U: '🅤',
        V: '🅥', W: '🅦', X: '🅧', Y: '🅨', Z: '🅩',
        a: '🅐', b: '🅑', c: '🅒', d: '🅓', e: '🅔', f: '🅕', g: '🅖',
        h: '🅗', i: '🅘', j: '🅙', k: '🅚', l: '🅛', m: '🅜', n: '🅝',
        o: '🅞', p: '🅟', q: '🅠', r: '🅡', s: '🅢', t: '🅣', u: '🅤',
        v: '🅥', w: '🅦', x: '🅧', y: '🅨', z: '🅩'
      }
    },
    {
      name: 'Squares',
      map: {
        A: '🄰', B: '🄱', C: '🄲', D: '🄳', E: '🄴', F: '🄵', G: '🄶',
        H: '🄷', I: '🄸', J: '🄹', K: '🄺', L: '🄻', M: '🄼', N: '🄽',
        O: '🄾', P: '🄿', Q: '🅀', R: '🅁', S: '🅂', T: '🅃', U: '🅄',
        V: '🅅', W: '🅆', X: '🅇', Y: '🅈', Z: '🅉',
        a: '🄰', b: '🄱', c: '🄲', d: '🄳', e: '🄴', f: '🄵', g: '🄶',
        h: '🄷', i: '🄸', j: '🄹', k: '🄺', l: '🄻', m: '🄼', n: '🄽',
        o: '🄾', p: '🄿', q: '🅀', r: '🅁', s: '🅂', t: '🅃', u: '🅄',
        v: '🅅', w: '🅆', x: '🅇', y: '🅈', z: '🅉'
      }
    },
    {
      name: 'Dark Squares',
      map: {
        A: '🅰', B: '🅱', C: '🅲', D: '🅳', E: '🅴', F: '🅵', G: '🅶',
        H: '🅷', I: '🅸', J: '🅹', K: '🅺', L: '🅻', M: '🅼', N: '🅽',
        O: '🅾', P: '🅿', Q: '🆀', R: '🆁', S: '🆂', T: '🆃', U: '🆄',
        V: '🆅', W: '🆆', X: '🆇', Y: '🆈', Z: '🆉',
        a: '🅰', b: '🅱', c: '🅲', d: '🅳', e: '🅴', f: '🅵', g: '🅶',
        h: '🅷', i: '🅸', j: '🅹', k: '🅺', l: '🅻', m: '🅼', n: '🅽',
        o: '🅾', p: '🅿', q: '🆀', r: '🆁', s: '🆂', t: '🆃', u: '🆄',
        v: '🆅', w: '🆆', x: '🆇', y: '🆈', z: '🆉'
      }
    },
    {
      name: 'Sky Blue',
      map: {
        A: '🅐', B: '🅑', C: '🅒', D: '🅓', E: '🅔', F: '🅕', G: '🅖',
        H: '🅗', I: '🅘', J: '🅙', K: '🅚', L: '🅛', M: '      🅜', N: '🅝',
        O: '🅞', P: '🅟', Q: '🅠', R: '🅡', S: '🅢', T: '🅣', U: '🅤',
        V: '🅥', W: '🅦', X: '🅧', Y: '🅨', Z: '🅩',
        a: '🅐', b: '🅑', c: '🅒', d: '🅓', e: '🅔', f: '🅕', g: '🅖',
        h: '🅗', i: '🅘', j: '🅙', k: '🅚', l: '🅛', m: '🅜', n: '🅝',
        o: '🅞', p: '🅟', q: '🅠', r: '🅡', s: '🅢', t: '🅣', u: '🅤',
        v: '🅥', w: '🅦', x: '🅧', y: '🅨', z: '🅩'
      }
    }
  ];
  useEffect(() => {
    switch (location.pathname) {
      case '/font-changer':
        setHeaderText('Font Changer');
        setInputText('Font Changer');
        break;
        case '/fonts-for-facebook':
          setHeaderText('Fonts For Facebook');
          setInputText('Fonts For Facebook');
          break;
      case '/stylish-name-generator':
        setHeaderText('Stylish Name Generator');
        setInputText('Stylish Name Generator');
        break;
      case '/calligraphy-font-generator':
        setHeaderText('Calligraphy Font Generator');
        setInputText('Calligraphy Font Generator');
        break;
      case '/decorated-text':
        setHeaderText('Decorated Text');
        setInputText('Decorated Text');
        break;
      case '/squiggle-fancy-fonts':
        setHeaderText('Squiggle Fancy Fonts');
        setInputText('Squiggle Fancy Fonts');
        break;
      case '/fancy-fonts':
        setHeaderText('Fancy Fonts');
        setInputText('Fancy Fonts');
        break;
      case '/fancy-text-generator':
        setHeaderText('Fancy Text Generator');
        setInputText('Fancy Text Generator');
        break;
      case '/glitch-text-generator':
        setHeaderText('Glitch Text Generator');
        setInputText('Glitch Text Generator');
        break;
      case '/text-fonts':
        setHeaderText('Text Fonts');
        setInputText('Text Fonts');
        break;
      case '/fancy-letters':
        setHeaderText('Fancy Letters');
        setInputText('Fancy Letters');
        break;
      case '/cursive-text-generator':
        setHeaderText('Cursive Text Generator');
        setInputText('Cursive Text Generator');
        break;
      default:
        setHeaderText('Font Style Changer');
        setInputText('Font Changer App');
        break;
    }
  }, [location.pathname]);

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleColorChange = (event) => {
    setTextColor(event.target.value);
  };

  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
  };
const trackFontUsage = (font) => {
    setFontHistory((prevHistory) => {
      const newHistory = [...prevHistory];
      const fontIndex = newHistory.findIndex((item) => item.font === font);

      if (fontIndex !== -1) {
        newHistory[fontIndex].count += 1;
      } else {
        newHistory.push({ font, count: 1 });
      }

      return newHistory.sort((a, b) => b.count - a.count);
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };


  
  return (
    <div className="App">
      <Helmet>
        <title>{headerText.includes('Font Changer') ? headerText : 'Font Changer App - ' + headerText}</title>
        <meta name="description" content={`Discover the ${headerText} tool. Transform your text with our powerful ${headerText} tool for creating stylish and fancy fonts.`} />
        <meta name="keywords" content="Font Changer, Stylish Name, text transformation, calligraphy font, fancy fonts" />
        <meta name="author" content="Salahudin Gorchani" />
        <link rel="canonical" href="https://thefontchanger.com" />
      </Helmet>
      {!isHomePage && <Menu />}
      <header className="App-header">
        <h1>{headerText}</h1>
        {!isHomePage && <Link to="/" className="home-button">Home</Link>}
        <div className="input-container">
 
          <input
            type="text"
            value={inputText}
            onChange={handleChange}
            placeholder="Type something here..."
            aria-label="Input text for font transformation"
          />
          <label className="color-label">
            Select Text Color:
            <input
              type="color"
              value={textColor}
              onChange={handleColorChange}
              className="color-picker"
              aria-label="Select text color"
            />
          </label>
          <label className="font-size-label">
            Font Size:
            <input
              type="range"
              min="12"
              max="50"
              value={fontSize}
              onChange={handleFontSizeChange}
              className="font-size-slider"
              aria-label="Adjust font size"
            />
            <span className="font-size-value">{fontSize}</span>
          </label>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/font-changer" element={<FontChanger inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/fonts-for-facebook" element={<FontsForFacebook inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
      
          <Route path="/stylish-name-generator" element={<StylishNameGenerator inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/calligraphy-font-generator" element={<CalligraphyFontGenerator inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/decorated-text" element={<DecoratedText inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/squiggle-fancy-fonts" element={<SquiggleFancyFonts inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/fancy-fonts" element={<FancyFonts inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/fancy-text-generator" element={<FancyTextGenerator inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/glitch-text-generator" element={<GlitchTextGenerator inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/text-fonts" element={<TextFonts inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/fancy-letters" element={<FancyLetters inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
          <Route path="/cursive-text-generator" element={<CursiveTextGenerator inputText={inputText} textColor={textColor} fontSize={fontSize} />} />
        </Routes>
      </header>



<div>   <FontSlider inputText={inputText} fontMappings={fontMappings} onCopy={handleCopy} copied={copied} />
<div>
<h2> Font Changer </h2>
<FontChanger
        inputText={inputText}
        fontMappings={fontMappings}
        onCopy={handleCopy}
        copied={copied}
        textColor={textColor} 
        fontSize={fontSize}// Passing color prop
        maxDisplay={4} 
      />
      <h2> Stylish Name Generator </h2>
      <StylishNameGenerator
        inputText={inputText}
        fontMappings={fontMappings}
        onCopy={handleCopy}
        copied={copied}
        textColor={textColor} 
        fontSize={fontSize}// Passing color prop
        maxDisplay={4} 
      />
      
      </div> 
      


</div>   <div>
      <FontHistory history={fontHistory} /></div>
      {isHomePage && (
        <section className="content-section">
                 </section>
      )}

  
    </div>
  );
};


const Menu = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="menu-bar">
      <button className="menu-toggle" onClick={handleMenuToggle}>
        ☰
      </button>
      <div className={`menu ${menuVisible ? 'show' : ''}`}>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <a href="https://thefontchanger.com">Home</a>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/font-changer">Font Changer</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/fonts-for-facebook">Fonts For Facebook</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/stylish-name-generator">Stylish Name Generator</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/calligraphy-font-generator">Calligraphy Font Generator</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/decorated-text">Decorated Text</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/squiggle-fancy-fonts">Squiggle Fancy Fonts</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/fancy-fonts">Fancy Fonts</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/fancy-text-generator">Fancy Text Generator</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/glitch-text-generator">Glitch Text Generator</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/text-fonts">Text Fonts</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/fancy-letters">Fancy Letters</Link>
        </div>
        <div className="menu-item" onClick={() => setMenuVisible(false)}>
          <Link to="/cursive-text-generator">Cursive Text Generator</Link>
        </div>
      </div>
    </div>
  );
};

const Home = () => (
  <div className="home-links">
    <Link to="/font-changer" className="home-link">Font Changer</Link>
    <Link to="/fonts-for-facebook" className="home-link">Fonts For Facebook</Link>
    <Link to="/stylish-name-generator" className="home-link">Stylish Name Generator</Link>
    <Link to="/calligraphy-font-generator" className="home-link">Calligraphy Font Generator</Link>
    <Link to="/decorated-text" className="home-link">Decorated Text</Link>
    <Link to="/squiggle-fancy-fonts" className="home-link">Squiggle Fancy Fonts</Link>
    <Link to="/fancy-fonts" className="home-link">Fancy Fonts</Link>
    <Link to="/fancy-text-generator" className="home-link">Fancy Text Generator</Link>
    <Link to="/glitch-text-generator" className="home-link">Glitch Text Generator</Link>
    <Link to="/text-fonts" className="home-link">Text Fonts</Link>
    <Link to="/fancy-letters" className="home-link">Fancy Letters</Link>
    <Link to="/cursive-text-generator" className="home-link">Cursive Text Generator</Link>
  </div>
);

export default App;
