import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './DecoratedText.css';

const templates = [
  "•°¯`•• {text} ••´¯°•",
  "(¯`*•.¸,¤°´✿.｡.:* {text} *.:｡.✿`°¤,¸.•*´¯)",
  "|!¤*'~``~'*¤!| {text} |!¤*'~``~'*¤!|",
  "•._.••´¯``•.¸¸.•` {text} `•.¸¸.•´´¯`••._.•",
  "¸„.-•~¹°”ˆ˜¨ {text} ¨˜ˆ”°¹~•-.„¸",
  "(¯´•._.• {text} •._.•´¯)",
  "••¤(`×[¤ {text} ¤]×´)¤••",
  "•´¯`•» {text} «•´¯`•",
  ".o0×X×0o. {text} .o0×X×0o.",
  "¤¸¸.•´¯`•¸¸.•..>> {text} <<..•.¸¸•´¯`•.¸¸¤",
  "—(••÷[ {text} ]÷••)—",
  "¸,ø¤º°`°º¤ø,¸ {text} ¸,ø¤º°`°º¤ø,¸",
  "`•.¸¸.•´´¯`••._.• {text} •._.••`¯´´•.¸¸.•`",
  ",-*' ^ '~*-.,_,.-*~ {text} ~*-.,_,.-*~' ^ '*-",
  "`•.,¸¸,.•´¯ {text} ¯`•.,¸¸,.•´",
  "↤↤↤↤↤ {text} ↦↦↦↦↦",
  "➶➶➶➶➶ {text} ➷➷➷➷➷",
  "↫↫↫↫↫ {text} ↬↬↬↬↬",
  "·.¸¸.·♩♪♫ {text} ♫♪♩·.¸¸.·",
  "【｡_｡】 {text} 【｡_｡】",
  "]|I{•------» {text} «------•}I|[",
  "▀▄▀▄▀▄ {text} ▄▀▄▀▄▀",
  "(-_-) {text} (-_-)",
  "•´¯`•. {text} .•´¯`•",
  "-漫~*'¨¯¨'*·舞~ {text} ~舞*'¨¯¨'*·~漫-",
  "๑۞๑,¸¸,ø¤º°`°๑۩ {text} ๑۩ ,¸¸,ø¤º°`°๑۞๑",
  ".•°¤*(¯`★´¯)*¤° {text} °¤*(¯´★`¯)*¤°•.",
  "••.•´¯`•.•• {text} ••.•´¯`•.••",
  "¤¸¸.•´¯`•¸¸.•..>> {text} <<..•.¸¸•´¯`•.¸¸¤",
  "◦•●◉✿ {text} ✿◉●•◦",
  "╚»★«╝ {text} ╚»★«╝",
  "-·=»‡«=·- {text} -·=»‡«=·-",
  "∙∙·▫▫ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ {text} ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ▫ₒₒ▫ᵒᴼᵒ▫▫·∙∙",
  "¸¸♬·¯·♩¸¸♪·¯·♫¸¸ {text} ¸¸♫·¯·♪¸¸♩·¯·♬¸¸",
  "ஜ۩۞۩ஜ {text} ஜ۩۞۩ஜ",
  "¤ (¯´☆✭.¸_)¤ {text} ¤(_¸.✭☆´¯) ¤",
  "(¯`·.¸¸.·´¯`·.¸¸.-> {text} <-.¸¸.·´¯`·.¸¸.·´¯)",
  "✿.｡.:* ☆:**:. {text} .:**:.☆*.:｡.✿",
  "•♫•♬• {text} •♬•♫•.",
  "ღ(¯`◕‿◕´¯) ♫ ♪ ♫ {text} ♫ ♪ ♫ (¯`◕‿◕´¯)ღ",
  "«-(¯`v´¯)-« {text} »-(¯`v´¯)-»",
  "🌸🌼🌺 {text} 🌺🌼🌸",
  "⭐✨💫 {text} 💫✨⭐",
  "🌟💖💝 {text} 💝💖🌟",
  "🎀🌷🍀 {text} 🍀🌷🎀",
  "🌹❀✿ {text} ✿❀🌹",
  "💎🌸🌼 {text} 🌼🌸💎",
];

const fonts = [
  'Arial, sans-serif',
  '"Courier New", monospace',
  '"Comic Sans MS", cursive, sans-serif',
  '"Lucida Console", Monaco, monospace',
  '"Impact", Charcoal, sans-serif',
  '"Georgia", serif',
  '"Palatino Linotype", "Book Antiqua", Palatino, serif',
  '"Times New Roman", Times, serif',
  '"Trebuchet MS", Helvetica, sans-serif',
  '"Verdana", Geneva, sans-serif',
  '"Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif',
  '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
  '"Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif',
  '"Brush Script MT", cursive'
];

const DecoratedText = ({ inputText, textColor, fontSize }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);
  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [decoratedText, setDecoratedText] = useState('');

  const handleGenerate = () => {
    const newText = selectedTemplate.replace('{text}', inputText);
    setDecoratedText(newText);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(decoratedText);
    alert('Text copied to clipboard!');
  };

  return (
    <div className="decorated-text-generator">
      <Helmet>
        <title>ஜ۩۞۩ஜDecorated Text Generatorஜ۩۞۩ஜ</title>
        <meta name="description" content="Create beautiful and stylish text effortlessly with FontChanger’s Stylish Text Decorator ~舞*'¨¯¨'*·~漫-. Select from a variety of templates and fonts to enhance your text now." />
      </Helmet>
      <h2>Decorated Text Generator</h2>
      <div className="controls">
        <label>Template:</label>
        <select value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
          {templates.map((template, index) => (
            <option key={index} value={template}>{template.replace('{text}', '...')}</option>
          ))}
        </select>
        <label>Font Style:</label>
        <select value={selectedFont} onChange={(e) => setSelectedFont(e.target.value)}>
          {fonts.map((font, index) => (
            <option key={index} value={font}>{font}</option>
          ))}
        </select>
        <button onClick={handleGenerate}>Generate</button>
      </div>
      {decoratedText && (
        <div className="result">
          <div
            className="decorated-text"
            style={{
              fontFamily: selectedFont,
              color: textColor,
              fontSize: `${fontSize}px`,
            }}
          >
            {decoratedText}
          </div>
          <button onClick={handleCopy}>Copy Decorated Text</button>
        </div>
      )}
      <div className="content-section">
        <h3>Stylish Text Decorator - Create Beautiful Text Decorations with FontChanger</h3>
        <p>Enhance Your Text with Our Stylish Text Decorator</p>
        <p>Welcome to the Stylish Text Decorator module of FontChanger app!</p>
        <p>
          Do you want to amplify your text lying under the genre of the written word? Our Text Decoration tool is a simple yet effective way to help you make your boring plain text stunning and stylish. Regardless of whether you are preparing posts on social networks as a decoration, creating striking graphics, or enjoying the use of text decorations, our tool has an impressive choice of beautiful templates and fonts available.
        </p>
        <h4>What is Stylish Text?</h4>
        <p>
          Stylish Text is a technique that is applied to add designs and formats on the text in order to make it look creative. Our Text Decoration tool is fabulous, allowing you to change the plain text into something outstanding and memorable quickly and without effort at all.
        </p>
        <h4>Why Use Our Stylish Text Decorator?</h4>
        <ul>
          <li><strong>User-Friendly Interface:</strong> Our tool is rather easy to use. You just have to type in your text, choose one of the numerous styles, and click on the button to obtain crafted stylish text.</li>
          <li><strong>Variety of Templates:</strong> The decoration templates include many choices which suit various occasions or styles.</li>
          <li><strong>Custom Fonts:</strong> This approach allows you to push a wider library of fonts that will let the text decorations look good.</li>
        </ul>
        <p>
          To get your stylish text, click the “Generate” button. A button for copying the decorated text is also available so that you can copy the text and paste it wherever you want.
        </p>
        <h4>Example Templates and Fonts</h4>
        <ul>
          <li><strong>Templates:</strong> From elegant borders to fun and quirky designs, our templates will fit any mood or purpose.</li>
          <li><strong>Fonts:</strong> Access fonts like Arial, Verdana, Times New Roman, and more to pair with your decorated text.</li>
        </ul>
        <h4>Perfect Uses for Stylish Text</h4>
        <ul>
          <li><Link to="https://thefontchanger.com/font-changer">Font Changer For Social Media</Link>: Make your social media updates stand out with stylish text decorations.</li>
          <li><Link to="https://thefontchanger.com/calligraphy-font-generator">Use calligraphy Font in Graphic Design</Link>: Enhance your graphic design projects with unique text styles.</li>
          <li><Link to="https://thefontchanger.com/stylish-name-generator">Stylish Text Messaging</Link>: Add a creative touch to your messages and emails using decorated text.</li>
        </ul>
        <h4>Features of FontChanger's Stylish Text Decorator</h4>
        <ul>
          <li><strong>Instant Results:</strong> Generate stylish text in real-time and see the transformation instantly.</li>
          <li><strong>Mobile-Friendly:</strong> Doing stylish text is easy with our tool, and we have designed it to work on any device so that you can style your texts on the go.</li>
          <li><strong>Wide Range of Options:</strong> Combined with many templates and fonts, there are so many choices that anybody could easily choose the options that they like best.</li>
          <li><strong>Secure and Reliable:</strong> Enjoy a secure and smooth user experience with our decorated text generator.</li>
        </ul>
        <h4>How to Use the Stylish Text Decorator</h4>
        <ol>
          <li><strong>Enter Your Text:</strong> Start by typing the text you want to decorate into the input box.</li>
          <li><strong>Select Template:</strong> Choose from an array of decoration templates that wrap around your text.</li>
          <li><strong>Select Font Style:</strong> Pick a font style from our list of fonts to match your text decoration.</li>
          <li><strong>Customize Color and Size:</strong> Adjust the text color and font size to your preference.</li>
          <li><strong>Generate and Copy:</strong> Click the "Generate" button to create your decorated text. Use the "Copy" button to copy it to your clipboard and paste it wherever you want.</li>
        </ol>
        <p>
          Start Creating Stylish Text Today! Do not write plain text and be ordinary when you have the opportunity to make your text look fantastic with the help of Text Decorator. Visit FontChanger app now and explore the endless possibilities of creating beautiful text decorations. Transform your text with ease and make a lasting impression!
        </p>
      </div>
    </div>
  );
};

export default DecoratedText;
