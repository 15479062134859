import React, { useState, useEffect } from 'react';
import './FontSlider.css';

const FontSlider = ({ inputText, fontMappings, onCopy }) => {
  const [currentFontIndex, setCurrentFontIndex] = useState(0);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment currentFontIndex circularly
      setCurrentFontIndex((prevIndex) => (prevIndex + 1) % fontMappings.length);
    }, 3000); // Change font every 3 seconds (adjust interval as needed)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [fontMappings]);

  const transformText = (text, mapping) => {
    let transformedText = '';
    for (let char of text) {
      transformedText += mapping[char.toLowerCase()] || char;
    }
    return transformedText;
  };

  const handleCopy = (copiedText) => {
    navigator.clipboard.writeText(copiedText);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500); // Reset copied state after 1.5 seconds
  };

  return (
    <div className="font-slider">
      {fontMappings.map((font, index) => (
        <div
          key={index}
          className={`font-item ${index === currentFontIndex ? 'active' : ''}`}
          onClick={() => handleCopy(font.prefix + transformText(inputText, font.map) + font.suffix)}
        >
          <p>
            {font.prefix}
            {transformText(inputText, font.map)}
            {font.suffix}
          </p>
          {index === currentFontIndex && copied && <span className="copied-dialog">Copied!</span>}
        </div>
      ))}
    </div>
  );
};

export default FontSlider;
