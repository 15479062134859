import React, { useState } from 'react';
import './FontChanger.css';

const FancyFonts = ({ inputText, textColor, fontSize }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const fonts = [
    {
      name: 'Fancy',
      map: {
        A: '𝓐', B: '𝓑', C: '𝓒', D: '𝓓', E: '𝓔', F: '𝓕', G: '𝓖',
        H: '𝓗', I: '𝓘', J: '𝓙', K: '𝓚', L: '𝓛', M: '𝓜', N: '𝓝',
        O: '𝓞', P: '𝓟', Q: '𝓠', R: '𝓡', S: '𝓢', T: '𝓣', U: '𝓤',
        V: '𝓥', W: '𝓦', X: '𝓧', Y: '𝓨', Z: '𝓩',
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    }
    
    // Add other font mappings here...
  ];

  const convertText = (text, map) => {
    return text.split('').map(char => map[char] || char).join('');
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  return (
    <div className="fancy-fonts">
      <div className="styled-texts">
        {fonts.map((font, index) => (
          <div key={index} className="styled-text-wrapper">
            <div className="styled-text" style={{ color: textColor, fontSize: `${fontSize}px` }}>
              {convertText(inputText, font.map)}
            </div>
            <button 
              className="copy-button" 
              onClick={() => handleCopy(convertText(inputText, font.map), index)}
            >
              {copiedIndex === index ? 'Copied' : 'Copy'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FancyFonts;
