import React, { useState } from 'react';
import './FontChanger.css';

const FontsForFacebook = ({ inputText, textColor, fontSize }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const fonts = [

    {
      name: 'facebook font changer Bold',

      map: {
        A: '𝗔', B: '𝗕', C: '𝗖', D: '𝗗', E: '𝗘', F: '𝗙', G: '𝗚',
        H: '𝗛', I: '𝗜', J: '𝗝', K: '𝗞', L: '𝗟', M: '𝗠', N: '𝗡',
        O: '𝗢', P: '𝗣', Q: '𝗤', R: '𝗥', S: '𝗦', T: '𝗧', U: '𝗨',
        V: '𝗩', W: '𝗪', X: '𝗫', Y: '𝗬', Z: '𝗭',
        a: '𝗮', b: '𝗯', c: '𝗰', d: '𝗱', e: '𝗲', f: '𝗳', g: '𝗴',
        h: '𝗵', i: '𝗶', j: '𝗷', k: '𝗸', l: '𝗹', m: '𝗺', n: '𝗻',
        o: '𝗼', p: '𝗽', q: '𝗾', r: '𝗿', s: '𝘀', t: '𝘁', u: '𝘂',
        v: '𝘃', w: '𝘄', x: '𝘅', y: '𝘆', z: '𝘇'
      }
    },
    {
      name: 'facebook font changer Bold Italic',
      map: {
        A: '𝘼', B: '𝘽', C: '𝘾', D: '𝘿', E: '𝙀', F: '𝙁', G: '𝙂',
        H: '𝙃', I: '𝙄', J: '𝙅', K: '𝙆', L: '𝙇', M: '𝙈', N: '𝙉',
        O: '𝙊', P: '𝙋', Q: '𝙌', R: '𝙍', S: '𝙎', T: '𝙏', U: '𝙐',
        V: '𝙑', W: '𝙒', X: '𝙓', Y: '𝙔', Z: '𝙕',
        a: '𝙖', b: '𝙗', c: '𝙘', d: '𝙙', e: '𝙚', f: '𝙛', g: '𝙜',
        h: '𝙝', i: '𝙞', j: '𝙟', k: '𝙠', l: '𝙡', m: '𝙢', n: '𝙣',
        o: '𝙤', p: '𝙥', q: '𝙦', r: '𝙧', s: '𝙨', t: '𝙩', u: '𝙪',
        v: '𝙫', w: '𝙬', x: '𝙭', y: '𝙮', z: '𝙯'
      }
    },
    {
      name: 'Italic',
      map: {
        A: '𝘈', B: '𝘉', C: '𝘊', D: '𝘋', E: '𝘌', F: '𝘍', G: '𝘎',
        H: '𝘏', I: '𝘐', J: '𝘑', K: '𝘒', L: '𝘓', M: '𝘔', N: '𝘕',
        O: '𝘖', P: '𝘗', Q: '𝘘', R: '𝘙', S: '𝘚', T: '𝘛', U: '𝘜',
        V: '𝘝', W: '𝘞', X: '𝘟', Y: '𝘠', Z: '𝘡',
        a: '𝘢', b: '𝘣', c: '𝘤', d: '𝘥', e: '𝘦', f: '𝘧', g: '𝘨',
        h: '𝘩', i: '𝘪', j: '𝘫', k: '𝘬', l: '𝘭', m: '𝘮', n: '𝘯',
        o: '𝘰', p: '𝘱', q: '𝘲', r: '𝘳', s: '𝘴', t: '𝘵', u: '𝘶',
        v: '𝘷', w: '𝘸', x: '𝘹', y: '𝘺', z: '𝘻'
      }
    },
    {
      name: 'Math Italic',
      map: {
        A: '𝐴', B: '𝐵', C: '𝐶', D: '𝐷', E: '𝐸', F: '𝐹', G: '𝐺',
        H: '𝐻', I: '𝐼', J: '𝐽', K: '𝐾', L: '𝐿', M: '𝑀', N: '𝑁',
        O: '𝑂', P: '𝑃', Q: '𝑄', R: '𝑅', S: '𝑆', T: '𝑇', U: '𝑈',
        V: '𝑉', W: '𝑊', X: '𝑋', Y: '𝑌', Z: '𝑍',
        a: '𝑎', b: '𝑏', c: '𝑐', d: '𝑑', e: '𝑒', f: '𝑓', g: '𝑔',
        h: 'ℎ', i: '𝑖', j: '𝑗', k: '𝑘', l: '𝑙', m: '𝑚', n: '𝑛',
        o: '𝑜', p: '𝑝', q: '𝑞', r: '𝑟', s: '𝑠', t: '𝑡', u: '𝑢',
        v: '𝑣', w: '𝑤', x: '𝑥', y: '𝑦', z: '𝑧'
      }
    },
    {
      name: 'font changer for facebook Calligraphic',
      map: {
        A: '𝒜', B: 'ℬ', C: '𝒞', D: '𝒟', E: 'ℰ', F: 'ℱ', G: '𝒢',
        H: 'ℋ', I: 'ℐ', J: '𝒥', K: '𝒦', L: 'ℒ', M: 'ℳ', N: '𝒩',
        O: '𝒪', P: '𝒫', Q: '𝒬', R: 'ℛ', S: '𝒮', T: '𝒯', U: '𝒰',
        V: '𝒱', W: '𝒲', X: '𝒳', Y: '𝒴', Z: '𝒵',
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: 'ℯ', f: '𝒻', g: 'ℊ',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: 'ℴ', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'font changer for facebook Bold Calligraphic',
      map: {
        A: '𝓐', B: '𝓑', C: '𝓒', D: '𝓓', E: '𝓔', F: '𝓕', G: '𝓖',
        H: '𝓗', I: '𝓘', J: '𝓙', K: '𝓚', L: '𝓛', M: '𝓜', N: '𝓝',
        O: '𝓞', P: '𝓟', Q: '𝓠', R: '𝓡', S: '𝓢', T: '𝓣', U: '𝓤',
        V: '𝓥', W: '𝓦', X: '𝓧', Y: '𝓨', Z: '𝓩',
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Sans Serif Bold',
      map: {
        A: '𝐀', B: '𝐁', C: '𝐂', D: '𝐃', E: '𝐄', F: '𝐅', G: '𝐆',
        H: '𝐇', I: '𝐈', J: '𝐉', K: '𝐊', L: '𝐋', M: '𝐌', N: '𝐍',
        O: '𝐎', P: '𝐏', Q: '𝐐', R: '𝐑', S: '𝐒', T: '𝐓', U: '𝐔',
        V: '𝐕', W: '𝐖', X: '𝐗', Y: '𝐘', Z: '𝐙',
        a: '𝐚', b: '𝐛', c: '𝐜', d: '𝐝', e: '𝐞', f: '𝐟', g: '𝐠',
        h: '𝐡', i: '𝐢', j: '𝐣', k: '𝐤', l: '𝐥', m: '𝐦', n: '𝐧',
        o: '𝐨', p: '𝐩', q: '𝐪', r: '𝐫', s: '𝐬', t: '𝐭', u: '𝐮',
        v: '𝐯', w: '𝐰', x: '𝐱', y: '𝐲', z: '𝐳'
      }
    },
    {
      name: 'font maker for facebook Superscript',
      map: {
        A: 'ᴬ', B: 'ᴮ', C: 'ᶜ', D: 'ᴰ', E: 'ᴱ', F: 'ᶠ', G: 'ᴳ',
        H: 'ᴴ', I: 'ᴵ', J: 'ᴶ', K: 'ᴷ', L: 'ᴸ', M: 'ᴹ', N: 'ᴺ',
        O: 'ᴼ', P: 'ᴾ', Q: 'Q', R: 'ᴿ', S: 'ˢ', T: 'ᵀ', U: 'ᵁ',
        V: 'ⱽ', W: 'ᵂ', X: 'ˣ', Y: 'ʸ', Z: 'ᶻ',
        a: 'ᵃ', b: 'ᵇ', c: 'ᶜ', d: 'ᵈ', e: 'ᵉ', f: 'ᶠ', g: 'ᵍ',
        h: 'ʰ', i: 'ⁱ', j: 'ʲ', k: 'ᵏ', l: 'ˡ', m: 'ᵐ', n: 'ⁿ',
        o: 'ᵒ', p: 'ᵖ', q: 'q', r: 'ʳ', s: 'ˢ', t: 'ᵗ', u: 'ᵘ',
        v: 'ᵛ', w: 'ʷ', x: 'ˣ', y: 'ʸ', z: 'ᶻ'
      }
    },
    {
      name: 'facebook font generator Small Caps',
      map: {
        A: 'ᴀ', B: 'ʙ', C: 'ᴄ', D: 'ᴅ', E: 'ᴇ', F: 'ꜰ', G: 'ɢ',
        H: 'ʜ', I: 'ɪ', J: 'ᴊ', K: 'ᴋ', L: 'ʟ', M: 'ᴍ', N: 'ɴ',
        O: 'ᴏ', P: 'ᴘ', Q: 'Q', R: 'ʀ', S: 'ꜱ', T: 'ᴛ', U: 'ᴜ',
        V: 'ᴠ', W: 'ᴡ', X: 'x', Y: 'ʏ', Z: 'ᴢ',
        a: 'ᴀ', b: 'ʙ', c: 'ᴄ', d: 'ᴅ', e: 'ᴇ', f: 'ꜰ', g: 'ɢ',
        h: 'ʜ', i: 'ɪ', j: 'ᴊ', k: 'ᴋ', l: 'ʟ', m: 'ᴍ', n: 'ɴ',
        o: 'ᴏ', p: 'ᴘ', q: 'Q', r: 'ʀ', s: 'ꜱ', t: 'ᴛ', u: 'ᴜ',
        v: 'ᴠ', w: 'ᴡ', x: 'x', y: 'ʏ', z: 'ᴢ'
      }
    },
    {
      name: 'Fullwidth',
      map: {
        A: 'Ａ', B: 'Ｂ', C: 'Ｃ', D: 'Ｄ', E: 'Ｅ', F: 'Ｆ', G: 'Ｇ',
        H: 'Ｈ', I: 'Ｉ', J: 'Ｊ', K: 'Ｋ', L: 'Ｌ', M: 'Ｍ', N: 'Ｎ',
        O: 'Ｏ', P: 'Ｐ', Q: 'Ｑ', R: 'Ｒ', S: 'Ｓ', T: 'Ｔ', U: 'Ｕ',
        V: 'Ｖ', W: 'Ｗ', X: 'Ｘ', Y: 'Ｙ', Z: 'Ｚ',
        a: 'ａ', b: 'ｂ', c: 'ｃ', d: 'ｄ', e: 'ｅ', f: 'ｆ', g: 'ｇ',
        h: 'ｈ', i: 'ｉ', j: 'ｊ', k: 'ｋ', l: 'ｌ', m: 'ｍ', n: 'ｎ',
        o: 'ｏ', p: 'ｐ', q: 'ｑ', r: 'ｒ', s: 'ｓ', t: 'ｔ', u: 'ｕ',
        v: 'ｖ', w: 'ｗ', x: 'ｘ', y: 'ｙ', z: 'ｚ'
      }
    },




    //Squiggle Fancy Fonts


    {
      name: 'facebook font style SQUIGGLE Font 1',
      map: {
        a: 'α', b: 'ճ', c: 'c', d: 'ժ', e: 'ҽ', f: 'բ', g: 'ց',
        h: 'հ', i: 'í', j: 'յ', k: 'k', l: 'l', m: 'ต', n: 'ղ',
        o: 'օ', p: 'թ', q: 'զ', r: 'ɾ', s: 's', t: 'Ե', u: 'մ',
        v: 'ѵ', w: 'ա', x: 'x', y: 'ա', z: 'z'
      }
    },
    {
      name: 'SQUIGGLE Font 2',
      map: {
        a: 'Թ', b: 'Յ', c: 'Շ', d: 'Ժ', e: 'e', f: 'Բ', g: 'Գ',
        h: 'ɧ', i: 'ɿ', j: 'ʝ', k: 'k', l: 'ʅ', m: 'ʍ', n: 'Ռ',
        o: 'Ծ', p: 'ρ', q: 'φ', r: 'Ր', s: 'Տ', t: 'Ե', u: 'Մ',
        v: 'ע', w: 'ա', x: 'Ճ', y: 'ա', z: 'Հ'
      }
    },
    {
      name: 'facebook font style SQUIGGLE Font 3',
      map: {
        a: 'ค', b: '๒', c: 'ς', d: '๔', e: 'є', f: 'Ŧ', g: 'ﻮ',
        h: 'ђ', i: 'เ', j: 'ן', k: 'к', l: 'ɭ', m: '๓', n: 'ภ',
        o: '๏', p: 'ק', q: 'ợ', r: 'г', s: 'ร', t: 'Շ', u: 'ย',
        v: 'ש', w: 'ฬ', x: 'א', y: 'ฬ', z: 'չ'
      }
    },
    {
      name: 'SQUIGGLE Font 4',
      map: {
        a: 'α', b: 'Ⴆ', c: 'ƈ', d: 'ԃ', e: 'ҽ', f: 'ϝ', g: 'ɠ',
        h: 'ԋ', i: 'ι', j: 'ʝ', k: 'ƙ', l: 'ʅ', m: 'ɱ', n: 'ɳ',
        o: 'σ', p: 'ρ', q: 'ϙ', r: 'ɾ', s: 'ʂ', t: 'ƚ', u: 'υ',
        v: 'ʋ', w: 'ɯ', x: 'x', y: 'ɯ', z: 'ȥ'
      }
    },
    {
      name: 'facebook font style SQUIGGLE Font 5',
      map: {
        a: 'ǟ', b: 'ɮ', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɢ',
        h: 'ɦ', i: 'ɨ', j: 'ʝ', k: 'ӄ', l: 'ʟ', m: 'ʍ', n: 'ռ',
        o: 'օ', p: 'ք', q: 'զ', r: 'ʀ', s: 'ֆ', t: 'ȶ', u: 'ʊ',
        v: 'ʋ', w: 'ա', x: 'Ӽ', y: 'ա', z: 'ʐ'
      }
    },
    {
      name: 'SQUIGGLE Font 6',
      map: {
        a: 'Ꮧ', b: 'Ᏸ', c: 'ፈ', d: 'Ꮄ', e: 'Ꮛ', f: 'Ꭶ', g: 'Ꮆ',
        h: 'Ꮒ', i: 'Ꭵ', j: 'Ꮰ', k: 'Ꮶ', l: 'Ꮭ', m: 'Ꮇ', n: 'Ꮑ',
        o: 'Ꭷ', p: 'Ꭾ', q: 'Ꭴ', r: 'Ꮢ', s: 'Ꮥ', t: 'Ꮦ', u: 'Ꮼ',
        v: 'Ꮙ', w: 'Ꮗ', x: 'ጀ', y: 'Ꮗ', z: 'ፚ'
      }
    },
    {
      name: 'SQUIGGLE Font 7',
      map: {
        a: 'Ꭺ', b: 'b', c: 'Ꮯ', d: 'Ꭰ', e: 'Ꭼ', f: 'f', g: 'Ꮆ',
        h: 'h', i: 'Ꭵ', j: 'j', k: 'Ꮶ', l: 'Ꮮ', m: 'm', n: 'Ꮑ',
        o: 'Ꮎ', p: 'Ꮲ', q: 'q', r: 'Ꮢ', s: 's', t: 'Ꮖ', u: 'u',
        v: 'Ꮙ', w: 'Ꮃ', x: 'x', y: 'Ꮃ', z: 'Ꮓ'
      }
    },
    {
      name: 'SQUIGGLE Font 8',
      map: {
        a: 'ค', b: '๖', c: '¢', d: '໓', e: 'ē', f: 'f', g: 'ງ',
        h: 'h', i: 'i', j: 'ว', k: 'k', l: 'l', m: '๓', n: 'ຖ',
        o: '໐', p: 'p', q: '๑', r: 'r', s: 'Ş', t: 't', u: 'น',
        v: 'ง', w: 'ຟ', x: 'x', y: 'ຟ', z: 'ຊ'
      }
    },
    {
      name: 'SQUIGGLE Font 9',
      map: {
        a: 'α', b: 'в', c: 'c', d: '∂', e: 'є', f: 'f', g: 'g',
        h: 'н', i: 'ι', j: 'נ', k: 'к', l: 'ℓ', m: 'м', n: 'n',
        o: 'σ', p: 'ρ', q: 'q', r: 'я', s: 'ѕ', t: 'т', u: 'υ',
        v: 'ν', w: 'ω', x: 'х', y: 'ω', z: 'z'
      }
    },
    {
      name: 'SQUIGGLE Font 10',
      map: {
        a: 'ą', b: 'ც', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɠ',
        h: 'ɧ', i: 'ı', j: 'ʝ', k: 'ƙ', l: 'ʟ', m: 'ɱ', n: 'ŋ',
        o: 'ơ', p: '℘', q: 'զ', r: 'ཞ', s: 'ʂ', t: 'ƭ', u: 'ų',
        v: '۷', w: 'ῳ', x: 'ҳ', y: 'ῳ', z: 'ʑ'
      }
    },
    {
      name: 'SQUIGGLE Font style of facebook',
      map: {
        a: 'α', b: 'ɓ', c: '૮', d: '∂', e: 'ε', f: 'ƒ', g: 'ɠ',
        h: 'ɦ', i: 'เ', j: 'ʝ', k: 'ҡ', l: 'ℓ', m: 'ɱ', n: 'ɳ',
        o: 'σ', p: 'ρ', q: 'φ', r: '૨', s: 'ร', t: 'ƭ', u: 'µ',
        v: 'ѵ', w: 'ω', x: 'א', y: 'ω', z: 'ƶ'
      }
    },
    {
      name: 'SQUIGGLE Font 12',
      map: {
        a: 'ƛ', b: 'Ɓ', c: 'Ƈ', d: 'Ɗ', e: 'Є', f: 'Ƒ', g: 'Ɠ',
        h: 'Ӈ', i: 'Ɩ', j: 'ʆ', k: 'Ƙ', l: 'Լ', m: 'M', n: 'Ɲ',
        o: 'Ơ', p: 'Ƥ', q: 'Ƣ', r: 'Ʀ', s: 'Ƨ', t: 'Ƭ', u: 'Ʋ',
        v: 'Ɣ', w: 'Ɯ', x: 'Ҳ', y: 'Ɯ', z: 'Ȥ'
      }
    },
    {
      name: 'SQUIGGLE Font style for facebook',
      map: {
        a: 'ค', b: 'ც', c: '८', d: 'ძ', e: '૯', f: 'Բ', g: '૭',
        h: 'Һ', i: 'ɿ', j: 'ʆ', k: 'қ', l: 'Ն', m: 'ɱ', n: 'Ո',
        o: '૦', p: 'ƿ', q: 'ҩ', r: 'Ր', s: 'ς', t: '੮', u: 'υ',
        v: '౮', w: 'ω', x: '૪', y: 'ω', z: 'ઽ'
      }
    },
    {
      name: 'SQUIGGLE Font 14',
      map: {
        a: 'α', b: 'ß', c: 'ς', d: 'd', e: 'ε', f: 'ƒ', g: 'g',
        h: 'h', i: 'ï', j: 'յ', k: 'κ', l: 'ﾚ', m: 'm', n: 'η',
        o: '⊕', p: 'p', q: 'Ω', r: 'я', s: 'š', t: '†', u: 'u',
        v: '∀', w: 'ω', x: 'x', y: 'ω', z: 'z'
      }
    },
    {
      name: 'UPPER ANGLES Font',
      map: {
        a: 'Λ', b: 'B', c: 'ᄃ', d: 'D', e: 'Σ', f: 'F', g: 'G',
        h: 'Ή', i: 'I', j: 'J', k: 'K', l: 'ᄂ', m: 'M', n: 'П',
        o: 'Ө', p: 'P', q: 'Q', r: 'Я', s: 'Ƨ', t: 'Ƭ', u: 'Ц',
        v: 'V', w: 'Щ', x: 'X', y: 'Щ', z: 'Z'
      }
    },
    {
      name: 'Greek Font',
      map: {
        a: 'α', b: 'в', c: '¢', d: '∂', e: 'є', f: 'ƒ', g: 'g',
        h: 'н', i: 'ι', j: 'נ', k: 'к', l: 'ℓ', m: 'м', n: 'η',
        o: 'σ', p: 'ρ', q: 'q', r: 'я', s: 'ѕ', t: 'т', u: 'υ',
        v: 'ν', w: 'ω', x: 'χ', y: 'ω', z: 'z'
      }
    },
    {
      name: 'BENT TEXT Font',
      map: {
        a: 'ą', b: 'ҍ', c: 'ç', d: 'ժ', e: 'ҽ', f: 'ց', g: 'հ',
        h: 'ì', i: 'ʝ', j: 'ҟ', k: 'Ӏ', l: 'ʍ', m: 'ռ', n: 'օ',
        o: 'ք', p: 'զ', q: 'ʀ', r: 'ʂ', s: 'է', t: 'մ', u: 'ѵ',
        v: 'ա', w: '×', x: 'ա', y: 'Հ', z: 'Հ'
      }
    },
    {
      name: 'NEON Font',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑕ', d: 'ᗪ', e: 'E', f: 'ᖴ', g: 'G',
        h: 'ᕼ', i: 'I', j: 'ᒍ', k: 'K', l: 'ᒪ', m: 'ᗰ', n: 'ᑎ',
        o: 'O', p: 'ᑭ', q: 'ᑫ', r: 'ᖇ', s: 'ᔕ', t: 'T', u: 'ᑌ',
        v: 'ᐯ', w: 'ᗯ', x: '᙭', y: 'ᗯ', z: 'ᘔ'
      }
    },
    {
      name: 'FUTURE ALIEN Font',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑢ', d: 'ᕲ', e: 'ᘿ', f: 'ᖴ', g: 'ᘜ',
        h: 'ᕼ', i: 'ᓰ', j: 'ᒚ', k: 'ᖽᐸ', l: 'ᒪ', m: 'ᘻ', n: 'ᘉ',
        o: 'ᓍ', p: 'ᕵ', q: 'ᕴ', r: 'ᖇ', s: 'S', t: 'ᖶ', u: 'ᑘ',
        v: 'ᐺ', w: 'ᘺ', x: '᙭', y: 'ᘺ', z: 'ᗱ'
      }
    },





    {
      name: 'Stylish Symbols 1',
      map: {
        a: 'ꍏ', b: '♭', c: '☾', d: '◗', e: '€', f: 'Ϝ', g: '❡',
        h: '♄', i: '♗', j: '♪', k: 'ϰ', l: '↳', m: '♔', n: '♫',
        o: '⊙', p: 'ρ', q: '☭', r: '☈', s: 'ⓢ', t: '☂', u: '☋',
        v: '✓', w: 'ω', x: '⌘', y: 'ω', z: '☡'
      }
    },
    {
      name: 'Stylish Symbols 2',
      map: {
        a: '♬', b: 'ᖲ', c: '¢', d: 'ᖱ', e: '៩', f: '⨏', g: '❡',
        h: 'Ϧ', i: 'ɨ', j: 'ɉ', k: 'ƙ', l: 'ɭ', m: '៣', n: '⩎',
        o: '០', p: 'ᖰ', q: 'ᖳ', r: 'Ʀ', s: 'ន', t: 'Ƭ', u: '⩏',
        v: '⩔', w: 'Ɯ', x: '✗', y: 'Ɯ', z: 'Ȥ'
      }
    },
    {
      name: 'Stylish Symbols 3',
      map: {
        a: 'ꪖ', b: '᥇', c: 'ᥴ', d: 'ᦔ', e: 'ꫀ', f: 'ᠻ', g: 'ᧁ',
        h: 'ꫝ', i: '𝓲', j: '𝓳', k: '𝘬', l: 'ꪶ', m: 'ꪑ', n: 'ꪀ',
        o: 'ꪮ', p: 'ρ', q: '𝘲', r: '𝘳', s: '𝘴', t: '𝓽', u: 'ꪊ',
        v: 'ꪜ', w: '᭙', x: '᥊', y: '᭙', z: 'ɀ'
      }
    },
    {
      name: 'Stylish Symbols 4',
      map: {
        a: 'ａ', b: 'ᵇ', c: 'ｃ', d: 'Ď', e: '𝔢', f: 'ᶠ', g: 'Ｇ',
        h: 'ⓗ', i: 'ι', j: 'Ⓙ', k: '𝐊', l: 'ˡ', m: '爪', n: '𝐍',
        o: '𝔬', p: 'ᑭ', q: '𝐐', r: 'ᖇ', s: 'Ş', t: 't', u: '𝐔',
        v: '𝔳', w: '𝔴', x: 'x', y: '𝕨', z: 'ℤ'
      }
    },
    {
      name: 'Stylish Symbols 5',
      map: {
        a: 'ꍏ', b: 'ꌃ', c: 'ꉓ', d: 'ꀸ', e: 'ꍟ', f: 'ꎇ', g: 'ꁅ',
        h: 'ꃅ', i: 'ꀤ', j: 'ꀭ', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꈤ',
        o: 'ꂦ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꌗ', t: '꓄', u: 'ꀎ',
        v: 'ꃴ', w: 'ꅏ', x: 'ꊼ', y: 'ꅏ', z: 'ꁴ'
      }
    },
    {
      name: 'Stylish Symbols 6',
      map: {
        a: 'ꋬ', b: 'ꃳ', c: 'ꉔ', d: '꒯', e: 'ꏂ', f: 'ꊰ', g: 'ꍌ',
        h: 'ꁝ', i: '꒐', j: '꒻', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꋊ',
        o: 'ꄲ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꇙ', t: '꓄', u: '꒤',
        v: '꒦', w: 'ꅐ', x: 'ꉧ', y: 'ꅐ', z: 'ꁴ'
      }
    },
    {
      name: 'Stylish Symbols 7',
      map: {
        a: 'ꋫ', b: 'ꃃ', c: 'ꏸ', d: 'ꁕ', e: 'ꍟ', f: 'ꄘ', g: 'ꁍ',
        h: 'ꑛ', i: 'ꂑ', j: 'ꀭ', k: 'ꀗ', l: '꒒', m: 'ꁒ', n: 'ꁹ',
        o: 'ꆂ', p: 'ꉣ', q: 'ꁸ', r: '꒓', s: 'ꌚ', t: '꓅', u: 'ꐇ',
        v: 'ꏝ', w: 'ꅐ', x: 'ꇓ', y: 'ꅐ', z: 'ꁴ'
      }
    },
    {
      name: 'Stylish Symbols 8',
      map: {
        a: 'ꋬ', b: 'ꍗ', c: 'ꏳ', d: 'ꂟ', e: 'ꏂ', f: 'ꄟ', g: 'ꍌ',
        h: 'ꃬ', i: '꒐', j: '꒻', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꂚ',
        o: 'ꉻ', p: 'ꉣ', q: 'ꋠ', r: 'ꋪ', s: 'ꑄ', t: '꓄', u: 'ꀎ',
        v: '꒦', w: 'ꅐ', x: 'ꉼ', y: 'ꅐ', z: 'ꑓ'
      }
    },
    {
      name: 'Stylish Symbols 9',
      map: {
        a: 'ꁲ', b: 'ꃃ', c: 'ꇃ', d: 'ꂡ', e: 'ꏹ', f: 'ꄙ', g: 'ꁍ',
        h: 'ꀍ', i: 'ꀤ', j: 'ꀭ', k: 'ꈵ', l: '꒒', m: 'ꂵ', n: 'ꋊ',
        o: 'ꁏ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꌗ', t: 'ꋖ', u: 'ꌈ',
        v: 'ꃴ', w: 'ꅏ', x: 'ꋚ', y: 'ꅏ', z: 'ꁴ'
      }
    },
    {
      name: 'Ethiopic',
      map: {
        a: 'ል', b: 'ጌ', c: 'ር', d: 'ዕ', e: 'ቿ', f: 'ቻ', g: 'ኗ',
        h: 'ዘ', i: 'ጎ', j: 'ጋ', k: 'ጕ', l: 'ረ', m: 'ጠ', n: 'ክ',
        o: 'ዐ', p: 'የ', q: 'ዒ', r: 'ዪ', s: 'ነ', t: 'ፕ', u: 'ሁ',
        v: 'ሀ', w: 'ሠ', x: 'ሸ', y: 'ሠ', z: 'ጊ'
      }
    },
    {
      name: 'Cyrillic',
      map: {
        a: 'а', b: 'б', c: 'ц', d: 'д', e: 'ё', f: 'f', g: 'г',
        h: 'h', i: 'и', j: 'й', k: 'к', l: 'л', m: 'м', n: 'н',
        o: 'о', p: 'п', q: 'щ', r: 'р', s: 'с', t: 'т', u: 'у',
        v: 'в', w: 'щ', x: 'ж', y: 'ы', z: 'з'
      }
    },
    {
      name: 'Greek',
      map: {
        a: 'Д', b: 'Б', c: 'C', d: '∂', e: 'Ξ', f: 'ƒ', g: 'g',
        h: 'h', i: 'ι', j: 'ј', k: 'к', l: 'л', m: 'м', n: 'и',
        o: 'о', p: 'р', q: 'Q', r: 'я', s: 'Ѕ', t: 't', u: 'ц',
        v: 'v', w: 'щ', x: 'χ', y: 'У', z: 'z'
      }
    },
    {
      name: 'Stylish Greek',
      map: {
        a: 'Д', b: 'Б', c: 'C', d: 'D', e: 'Ξ', f: 'F', g: 'G',
        h: 'H', i: 'I', j: 'J', k: 'K', l: 'L', m: 'M', n: 'Н',
        o: 'Ф', p: 'P', q: 'Q', r: 'Я', s: 'S', t: 'T', u: 'Ц',
        v: 'V', w: 'Щ', x: 'X', y: 'Υ', z: 'Z'
      }
    },
    {
      name: 'Fancy',
      map: {
        a: '卂', b: '乃', c: '匚', d: 'ᗪ', e: '乇', f: '千', g: 'Ꮆ',
        h: '卄', i: '丨', j: 'ﾌ', k: 'Ҝ', l: 'ㄥ', m: '爪', n: '几',
        o: 'ㄖ', p: '卩', q: 'Ɋ', r: '尺', s: '丂', t: 'ㄒ', u: 'ㄩ',
        v: 'ᐯ', w: '山', x: '乂', y: '山', z: '乙'
      }
    },
    {
      name: 'Currency',
      map: {
        a: '₳', b: '฿', c: '₵', d: 'Đ', e: 'Ɇ', f: '₣', g: '₲',
        h: 'Ⱨ', i: 'ł', j: 'J', k: '₭', l: 'Ⱡ', m: '₥', n: '₦',
        o: 'Ø', p: '₱', q: 'Q', r: 'Ɽ', s: '₴', t: '₮', u: 'Ʉ',
        v: 'V', w: '₩', x: 'Ӿ', y: '₩', z: 'Ⱬ'
      }
    },
    {
      name: 'Flipped',
      map: {
        a: 'z', b: 'ʍ', c: 'x', d: 'ʍ', e: 'ʌ', f: 'n', g: 'ʇ',
        h: 's', i: 'ɹ', j: 'b', k: 'd', l: 'o', m: 'u', n: 'ɯ',
        o: 'l', p: 'ʞ', q: 'ɾ', r: 'ı', s: 'ɥ', t: 'ɓ', u: 'ɟ',
        v: 'ǝ', w: 'p', x: 'ɔ', y: 'q', z: 'ɐ'
      }
    },
    {
      name: 'Fancy Sparkle',
      map: {
        a: 'a҉', b: 'b҉', c: 'c҉', d: 'd҉', e: 'e҉', f: 'f҉', g: 'g҉',
        h: 'h҉', i: 'i҉', j: 'j҉', k: 'k҉', l: 'l҉', m: 'm҉', n: 'n҉',
        o: 'o҉', p: 'p҉', q: 'q҉', r: 'r҉', s: 's҉', t: 't҉', u: 'u҉',
        v: 'v҉', w: 'w҉', x: 'x҉', y: 'w҉', z: 'z҉'
      }
    },







    {
      name: 'Thunder',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̶', b: 'b̶', c: 'c̶', d: 'd̶', e: 'e̶', f: 'f̶', g: 'g̶',
        h: 'h̶', i: 'i̶', j: 'j̶', k: 'k̶', l: 'l̶', m: 'm̶', n: 'n̶',
        o: 'o̶', p: 'p̶', q: 'q̶', r: 'r̶', s: 's̶', t: 't̶', u: 'u̶',
        v: 'v̶', w: 'w̶', x: 'x̶', y: 'y̶', z: 'z̶'
      }
    },
    {
      name: 'Strikethrough',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̴', b: 'b̴', c: 'c̴', d: 'd̴', e: 'e̴', f: 'f̴', g: 'g̴',
        h: 'h̴', i: 'i̴', j: 'j̴', k: 'k̴', l: 'l̴', m: 'm̴', n: 'n̴',
        o: 'o̴', p: 'p̴', q: 'q̴', r: 'r̴', s: 's̴', t: 't̴', u: 'u̴',
        v: 'v̴', w: 'w̴', x: 'x̴', y: 'y̴', z: 'z̴'
      }
    },
    {
      name: 'Slash',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̷', b: 'b̷', c: 'c̷', d: 'd̷', e: 'e̷', f: 'f̷', g: 'g̷',
        h: 'h̷', i: 'i̷', j: 'j̷', k: 'k̷', l: 'l̷', m: 'm̷', n: 'n̷',
        o: 'o̷', p: 'p̷', q: 'q̷', r: 'r̷', s: 's̷', t: 't̷', u: 'u̷',
        v: 'v̷', w: 'w̷', x: 'x̷', y: 'y̷', z: 'z̷'
      }
    },
    {
      name: 'Underline',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̲', b: 'b̲', c: 'c̲', d: 'd̲', e: 'e̲', f: 'f̲', g: 'g̲',
        h: 'h̲', i: 'i̲', j: 'j̲', k: 'k̲', l: 'l̲', m: 'm̲', n: 'n̲',
        o: 'o̲', p: 'p̲', q: 'q̲', r: 'r̲', s: 's̲', t: 't̲', u: 'u̲',
        v: 'v̲', w: 'w̲', x: 'x̲', y: 'y̲', z: 'z̲'
      }
    },
    {
      name: 'Stinky',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a͛', b: 'b͛', c: 'c͛', d: 'd͛', e: 'e͛', f: 'f͛', g: 'g͛',
        h: 'h͛', i: 'i͛', j: 'j͛', k: 'k͛', l: 'l͛', m: 'm͛', n: 'n͛',
        o: 'o͛', p: 'p͛', q: 'q͛', r: 'r͛', s: 's͛', t: 't͛', u: 'u͛',
        v: 'v͛', w: 'w͛', x: 'x͛', y: 'y͛', z: 'z͛'
      }
    },
    {
      name: 'Seagull',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̾', b: 'b̾', c: 'c̾', d: 'd̾', e: 'e̾', f: 'f̾', g: 'g̾',
        h: 'h̾', i: 'i̾', j: 'j̾', k: 'k̾', l: 'l̾', m: 'm̾', n: 'n̾',
        o: 'o̾', p: 'p̾', q: 'q̾', r: 'r̾', s: 's̾', t: 't̾', u: 'u̾',
        v: 'v̾', w: 'w̾', x: 'x̾', y: 'y̾', z: 'z̾'
      }
    },
    {
      name: 'Arrow',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a͎', b: 'b͎', c: 'c͎', d: 'd͎', e: 'e͎', f: 'f͎', g: 'g͎',
        h: 'h͎', i: 'i͎', j: 'j͎', k: 'k͎', l: 'l͎', m: 'm͎', n: 'n͎',
        o: 'o͎', p: 'p͎', q: 'q͎', r: 'r͎', s: 's͎', t: 't͎', u: 'u͎',
        v: 'v͎', w: 'w͎', x: 'x͎', y: 'y͎', z: 'z͎'
      }
    },
    {
      name: 'Frame',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̺͆', b: 'b̺͆', c: 'c̺͆', d: 'd̺͆', e: 'e̺͆', f: 'f̺͆', g: 'g̺͆',
        h: 'h̺͆', i: 'i̺͆', j: 'j̺͆', k: 'k̺͆', l: 'l̺͆', m: 'm̺͆', n: 'n̺͆',
        o: 'o̺͆', p: 'p̺͆', q: 'q̺͆', r: 'r̺͆', s: 's̺͆', t: 't̺͆', u: 'u̺͆',
        v: 'v̺͆', w: 'w̺͆', x: 'x̺͆', y: 'y̺͆', z: 'z̺͆'
      }
    },
    {
      name: 'font type facebook',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'ȃ̮', b: 'b̮̑', c: 'c̮̑', d: 'd̮̑', e: 'ȇ̮', f: 'f̮̑', g: 'g̮̑',
        h: 'ḫ̑', i: 'ȋ̮', j: 'j̮̑', k: 'k̮̑', l: 'l̮̑', m: 'm̮̑', n: 'n̮̑',
        o: 'ȏ̮', p: 'p̮̑', q: 'q̮̑', r: 'ȓ̮', s: 's̮̑', t: 't̮̑', u: 'ȗ̮',
        v: 'v̮̑', w: 'w̮̑', x: 'x̮̑', y: 'y̮̑', z: 'z̮̑'
      }
    },
    {
      name: 'facebook font style generator',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a͓̽', b: 'b͓̽', c: 'c͓̽', d: 'd͓̽', e: 'e͓̽', f: 'f͓̽', g: 'g͓̽',
        h: 'h͓̽', i: 'i͓̽', j: 'j͓̽', k: 'k͓̽', l: 'l͓̽', m: 'm͓̽', n: 'n    n͓̽',
        o: 'o͓̽', p: 'p͓̽', q: 'q͓̽', r: 'r͓̽', s: 's͓̽', t: 't͓̽', u: 'u͓̽',
        v: 'v͓̽', w: 'w͓̽', x: 'x͓̽', y: 'y͓̽', z: 'z͓̽'
      }
    },
    {
      name: 'Hearts Between',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a♥', b: 'b♥', c: 'c♥', d: 'd♥', e: 'e♥', f: 'f♥', g: 'g♥',
        h: 'h♥', i: 'i♥', j: 'j♥', k: 'k♥', l: 'l♥', m: 'm♥', n: 'n♥',
        o: 'o♥', p: 'p♥', q: 'q♥', r: 'r♥', s: 's♥', t: 't♥', u: 'u♥',
        v: 'v♥', w: 'w♥', x: 'x♥', y: 'y♥', z: 'z♥'
      }
    },
    {
      name: 'Wavy Joiner',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '≋a≋', b: '≋b≋', c: '≋c≋', d: '≋d≋', e: '≋e≋', f: '≋f≋', g: '≋g≋',
        h: '≋h≋', i: '≋i≋', j: '≋j≋', k: '≋k≋', l: '≋l≋', m: '≋m≋', n: '≋n≋',
        o: '≋o≋', p: '≋p≋', q: '≋q≋', r: '≋r≋', s: '≋s≋', t: '≋t≋', u: '≋u≋',
        v: '≋v≋', w: '≋w≋', x: '≋x≋', y: '≋y≋', z: '≋z≋'
      }
    },
    {
      name: 'Curly Joiner',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '⊰a⊱', b: '⊰b⊱', c: '⊰c⊱', d: '⊰d⊱', e: '⊰e⊱', f: '⊰f⊱', g: '⊰g⊱',
        h: '⊰h⊱', i: '⊰i⊱', j: '⊰j⊱', k: '⊰k⊱', l: '⊰l⊱', m: '⊰m⊱', n: '⊰n⊱',
        o: '⊰o⊱', p: '⊰p⊱', q: '⊰q⊱', r: '⊰r⊱', s: '⊰s⊱', t: '⊰t⊱', u: '⊰u⊱',
        v: '⊰v⊱', w: '⊰w⊱', x: '⊰x⊱', y: '⊰y⊱', z: '⊰z⊱'
      }
    },
    {
      name: 'fancy font facebook',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '░a░', b: '░b░', c: '░c░', d: '░d░', e: '░e░', f: '░f░', g: '░g░',
        h: '░h░', i: '░i░', j: '░j░', k: '░k░', l: '░l░', m: '░m░', n: '░n░',
        o: '░o░', p: '░p░', q: '░q░', r: '░r░', s: '░s░', t: '░t░', u: '░u░',
        v: '░v░', w: '░w░', x: '░x░', y: '░y░', z: '░z░'
      }
    },
    {
      name: 'facebook font editor',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a⊶', b: 'b⊶', c: 'c⊶', d: 'd⊶', e: 'e⊶', f: 'f⊶', g: 'g⊶',
        h: 'h⊶', i: 'i⊶', j: 'j⊶', k: 'k⊶', l: 'l⊶', m: 'm⊶', n: 'n⊶',
        o: 'o⊶', p: 'p⊶', q: 'q⊶', r: 'r⊶', s: 's⊶', t: 't⊶', u: 'u⊶',
        v: 'v⊶', w: 'w⊶', x: 'x⊶', y: 'y⊶', z: 'z⊶'
      }
    },
    {
      name: 'facebook font color changer',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '⦅a⦆', b: '⦅b⦆', c: '⦅c⦆', d: '⦅d⦆', e: '⦅e⦆', f: '⦅f⦆', g: '⦅g⦆',
        h: '⦅h⦆', i: '⦅i⦆', j: '⦅j⦆', k: '⦅k⦆', l: '⦅l⦆', m: '⦅m⦆', n: '⦅n⦆',
        o: '⦅o⦆', p: '⦅p⦆', q: '⦅q⦆', r: '⦅r⦆', s: '⦅s⦆', t: '⦅t⦆', u: '⦅u⦆',
        v: '⦅v⦆', w: '⦅w⦆', x: '⦅x⦆', y: '⦅y⦆', z: '⦅z⦆'
      }
    },
    {
      name: 'free font facebook',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '╰a╯', b: '╰b╯', c: '╰c╯', d: '╰d╯', e: '╰e╯', f: '╰f╯', g: '╰g╯',
        h: '╰h╯', i: '╰i╯', j: '╰j╯', k: '╰k╯', l: '╰l╯', m: '╰m╯', n: '╰n╯',
        o: '╰o╯', p: '╰p╯', q: '╰q╯', r: '╰r╯', s: '╰s╯', t: '╰t╯', u: '╰u╯',
        v: '╰v╯', w: '╰w╯', x: '╰x╯', y: '╰y╯', z: '╰z╯'
      }
    },
    {
      name: 'Double Up Box',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '╚a╝', b: '╚b╝', c: '╚c╝', d: '╚d╝', e: '╚e╝', f: '╚f╝', g: '╚g╝',
        h: '╚h╝', i: '╚i╝', j: '╚j╝', k: '╚k╝', l: '╚l╝', m: '╚m╝', n: '╚n╝',
        o: '╚o╝', p: '╚p╝', q: '╚q╝', r: '╚r╝', s: '╚s╝', t: '╚t╝', u: '╚u╝',
        v: '╚v╝', w: '╚w╝', x: '╚x╝', y: '╚y╝', z: '╚z╝'
      }
    },
    {
      name: 'font style online for facebook Double Vertical Box',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '╠a╣', b: '╠b╣', c: '╠c╣', d: '╠d╣', e: '╠e╣', f: '╠f╣', g: '╠g╣',
        h: '╠h╣', i: '╠i╣', j: '╠j╣', k: '╠k╣', l: '╠l╣', m: '╠m╣', n: '╠n╣',
        o: '╠o╣', p: '╠p╣', q: '╠q╣', r: '╠r╣', s: '╠s╣', t: '╠t╣', u: '╠u╣',
        v: '╠v╣', w: '╠w╣', x: '╠x╣', y: '╠y╣', z: '╠z╣'
      }
    },
    {
      name: 'White Bracket',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '『a』', b: '『b』', c: '『c』', d: '『d』', e: '『e』', f: '『f』', g: '『g』',
        h: '『h』', i: '『i』', j: '『j』', k: '『k』', l: '『l』', m: '『m』', n: '『n』',
        o: '『o』', p: '『p』', q: '『q』', r: '『r』', s: '『s』', t: '『t』', u: '『u』',
        v: '『v』', w: '『w』', x: '『x』', y: '『y』', z: '『z』'
      }
    },
    {
      name: 'facebook generator font Dark Bracket',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '【a】', b: '【b】', c: '【c】', d: '【d】', e: '【e】', f: '【f】', g: '【g】',
        h: '【h】', i: '【i】', j: '【j】', k: '【k】', l: '【l】', m: '【m】', n: '【n】',
        o: '【o】', p: '【p】', q: '【q】', r: '【r】', s: '【s】', t: '【t】', u: '【u】',
        v: '【v】', w: '【w】', x: '【x】', y: '【y】', z: '【z】'
      }
    },
    {
      name: 'Decoration 1',
      prefix: '★彡',
      suffix: '彡★',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 2',
      prefix: '(★)',
      suffix: '(★)',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 3',
      prefix: 'ıllıllı',
      suffix: 'ıllıllı',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'facebook font style change',
      prefix: '▄︻┻',
      suffix: '︻┳═─-',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'facebook font converter',
      prefix: '✎﹏﹏',
      suffix: '﹏﹏',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'facebook font changer bold Decoration',
      prefix: '꧁༒༺',
      suffix: '༻༒꧂',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'change font style on facebook',
      prefix: '♫ ♪ ♫',
      suffix: '♫ ♪ ♫',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'change facebook font style Decoration',
      prefix: '▀▄▀▄▀▄',
      suffix: '▄▀▄▀▄▀',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Bold',
      map: {
        A: '𝗔', B: '𝗕', C: '𝗖', D: '𝗗', E: '𝗘', F: '𝗙', G: '𝗚',
        H: '𝗛', I: '𝗜', J: '𝗝', K: '𝗞', L: '𝗟', M: '𝗠', N: '𝗡',
        O: '𝗢', P: '𝗣', Q: '𝗤', R: '𝗥', S: '𝗦', T: '𝗧', U: '𝗨',
        V: '𝗩', W: '𝗪', X: '𝗫', Y: '𝗬', Z: '𝗭',
        a: '𝗮', b: '𝗯', c: '𝗰', d: '𝗱', e: '𝗲', f: '𝗳', g: '𝗴',
        h: '𝗵', i: '𝗶', j: '𝗷', k: '𝗸', l: '𝗹', m: '𝗺', n: '𝗻',
        o: '𝗼', p: '𝗽', q: '𝗾', r: '𝗿', s: '𝘀', t: '𝘁', u: '𝘂',
        v: '𝘃', w: '𝘄', x: '𝘅', y: '𝘆', z: '𝘇'
      }
    },
    {
      name: 'change font in facebook post to Bold Italic',
      map: {
        A: '𝘼', B: '𝘽', C: '𝘾', D: '𝘿', E: '𝙀', F: '𝙁', G: '𝙂',
        H: '𝙃', I: '𝙄', J: '𝙅', K: '𝙆', L: '𝙇', M: '𝙈', N: '𝙉',
        O: '𝙊', P: '𝙋', Q: '𝙌', R: '𝙍', S: '𝙎', T: '𝙏', U: '𝙐',
        V: '𝙑', W: '𝙒', X: '𝙓', Y: '𝙔', Z: '𝙕',
        a: '𝙖', b: '𝙗', c: '𝙘', d: '𝙙', e: '𝙚', f: '𝙛', g: '𝙜',
        h: '𝙝', i: '𝙞', j: '𝙟', k: '𝙠', l: '𝙡', m: '𝙢', n: '𝙣',
        o: '𝙤', p: '𝙥', q: '𝙦', r: '𝙧', s: '𝙨', t: '𝙩', u: '𝙪',
        v: '𝙫', w: '𝙬', x: '𝙭', y: '𝙮', z: '𝙯'
      }
    },
    {
      name: 'change font facebook post to Italic',
      map: {
        A: '𝘈', B: '𝘉', C: '𝘊', D: '𝘋', E: '𝘌', F: '𝘍', G: '𝘎',
        H: '𝘏', I: '𝘐', J: '𝘑', K: '𝘒', L: '𝘓', M: '𝘔', N: '𝘕',
        O: '𝘖', P: '𝘗', Q: '𝘘', R: '𝘙', S: '𝘚', T: '𝘛', U: '𝘜',
        V: '𝘝', W: '𝘞', X: '𝘟', Y: '𝘠', Z: '𝘡',
        a: '𝘢', b: '𝘣', c: '𝘤', d: '𝘥', e: '𝘦', f: '𝘧', g: '𝘨',
        h: '𝘩', i: '𝘪', j: '𝘫', k: '𝘬', l: '𝘭', m: '𝘮', n: '𝘯',
        o: '𝘰', p: '𝘱', q: '𝘲', r: '𝘳', s: '𝘴', t: '𝘵', u: '𝘶',
        v: '𝘷', w: '𝘸', x: '𝘹', y: '𝘺', z: '𝘻'
      }
    },
    {
      name: 'Math Italic',
      map: {
        A: '𝐴', B: '𝐵', C: '𝐶', D: '𝐷', E: '𝐸', F: '𝐹', G: '𝐺',
        H: '𝐻', I: '𝐼', J: '𝐽', K: '𝐾', L: '𝐿', M: '𝑀', N: '𝑁',
        O: '𝑂', P: '𝑃', Q: '𝑄', R: '𝑅', S: '𝑆', T: '𝑇', U: '𝑈',
        V: '𝑉', W: '𝑊', X: '𝑋', Y: '𝑌', Z: '𝑍',
        a: '𝑎', b: '𝑏', c: '𝑐', d: '𝑑', e: '𝑒', f: '𝑓', g: '𝑔',
        h: 'ℎ', i: '𝑖', j: '𝑗', k: '𝑘', l: '𝑙', m: '𝑚', n: '𝑛',
        o: '𝑜', p: '𝑝', q: '𝑞', r: '𝑟', s: '𝑠', t: '𝑡', u: '𝑢',
        v: '𝑣', w: '𝑤', x: '𝑥', y: '𝑦', z: '𝑧'
      }
    },
    {
      name: 'Calligraphic',
      map: {
        A: '𝒜', B: 'ℬ', C: '𝒞', D: '𝒟', E: 'ℰ', F: 'ℱ', G: '𝒢',
        H: 'ℋ', I: 'ℐ', J: '𝒥', K: '𝒦', L: 'ℒ', M: 'ℳ', N: '𝒩',
        O: '𝒪', P: '𝒫', Q: '𝒬', R: 'ℛ', S: '𝒮', T: '𝒯', U: '𝒰',
        V: '𝒱', W: '𝒲', X: '𝒳', Y: '𝒴', Z: '𝒵',
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: 'ℯ', f: '𝒻', g: 'ℊ',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: 'ℴ', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Bold Calligraphic',
      map: {
        A: '𝓐', B: '𝓑', C: '𝓒', D: '𝓓', E: '𝓔', F: '𝓕', G: '𝓖',
        H: '𝓗', I: '𝓘', J: '𝓙', K: '𝓚', L: '𝓛', M: '𝓜', N: '𝓝',
        O: '𝓞', P: '𝓟', Q: '𝓠', R: '𝓡', S: '𝓢', T: '𝓣', U: '𝓤',
        V: '𝓥', W: '𝓦', X: '𝓧', Y: '𝓨', Z: '𝓩',
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Sans Serif Bold',
      map: {
        A: '𝐀', B: '𝐁', C: '𝐂', D: '𝐃', E: '𝐄', F: '𝐅', G: '𝐆',
        H: '𝐇', I: '𝐈', J: '𝐉', K: '𝐊', L: '𝐋', M: '𝐌', N: '𝐍',
        O: '𝐎', P: '𝐏', Q: '𝐐', R: '𝐑', S: '𝐒', T: '𝐓', U: '𝐔',
        V: '𝐕', W: '𝐖', X: '𝐗', Y: '𝐘', Z: '𝐙',
        a: '𝐚', b: '𝐛', c: '𝐜', d: '𝐝', e: '𝐞', f: '𝐟', g: '𝐠',
        h: '𝐡', i: '𝐢', j: '𝐣', k: '𝐤', l: '𝐥', m: '𝐦', n: '𝐧',
        o: '𝐨', p: '𝐩', q: '𝐪', r: '𝐫', s: '𝐬', t: '𝐭', u: '𝐮',
        v: '𝐯', w: '𝐰', x: '𝐱', y: '𝐲', z: '𝐳'
      }
    },
    {
      name: 'Superscript',
      map: {
        A: 'ᴬ', B: 'ᴮ', C: 'ᶜ', D: 'ᴰ', E: 'ᴱ', F: 'ᶠ', G: 'ᴳ',
        H: 'ᴴ', I: 'ᴵ', J: 'ᴶ', K: 'ᴷ', L: 'ᴸ', M: 'ᴹ', N: 'ᴺ',
        O: 'ᴼ', P: 'ᴾ', Q: 'Q', R: 'ᴿ', S: 'ˢ', T: 'ᵀ', U: 'ᵁ',
        V: 'ⱽ', W: 'ᵂ', X: 'ˣ', Y: 'ʸ', Z: 'ᶻ',
        a: 'ᵃ', b: 'ᵇ', c: 'ᶜ', d: 'ᵈ', e: 'ᵉ', f: 'ᶠ', g: 'ᵍ',
        h: 'ʰ', i: 'ⁱ', j: 'ʲ', k: 'ᵏ', l: 'ˡ', m: 'ᵐ', n: 'ⁿ',
        o: 'ᵒ', p: 'ᵖ', q: 'q', r: 'ʳ', s: 'ˢ', t: 'ᵗ', u: 'ᵘ',
        v: 'ᵛ', w: 'ʷ', x: 'ˣ', y: 'ʸ', z: 'ᶻ'
      }
    },
    {
      name: 'Small Caps',
      map: {
        A: 'ᴀ', B: 'ʙ', C: 'ᴄ', D: 'ᴅ', E: 'ᴇ', F: 'ꜰ', G: 'ɢ',
        H: 'ʜ', I: 'ɪ', J: 'ᴊ', K: 'ᴋ', L: 'ʟ', M: 'ᴍ', N: 'ɴ',
        O: 'ᴏ', P: 'ᴘ', Q: 'Q', R: 'ʀ', S: 'ꜱ', T: 'ᴛ', U: 'ᴜ',
        V: 'ᴠ', W: 'ᴡ', X: 'x', Y: 'ʏ', Z: 'ᴢ',
        a: 'ᴀ', b: 'ʙ', c: 'ᴄ', d: 'ᴅ', e: 'ᴇ', f: 'ꜰ', g: 'ɢ',
        h: 'ʜ', i: 'ɪ', j: 'ᴊ', k: 'ᴋ', l: 'ʟ', m: 'ᴍ', n: 'ɴ',
        o: 'ᴏ', p: 'ᴘ', q: 'Q', r: 'ʀ', s: 'ꜱ', t: 'ᴛ', u: 'ᴜ',
        v: 'ᴠ', w: 'ᴡ', x: 'x', y: 'ʏ', z: 'ᴢ'
      }
    },
    {
      name: 'Fullwidth',
      map: {
        A: 'Ａ', B: 'Ｂ', C: 'Ｃ', D: 'Ｄ', E: 'Ｅ', F: 'Ｆ', G: 'Ｇ',
        H: 'Ｈ', I: 'Ｉ', J: 'Ｊ', K: 'Ｋ', L: 'Ｌ', M: 'Ｍ', N: 'Ｎ',
        O: 'Ｏ', P: 'Ｐ', Q: 'Ｑ', R: 'Ｒ', S: 'Ｓ', T: 'Ｔ', U: 'Ｕ',
        V: 'Ｖ', W: 'Ｗ', X: 'Ｘ', Y: 'Ｙ', Z: 'Ｚ',
        a: 'ａ', b: 'ｂ', c: 'ｃ', d: 'ｄ', e: 'ｅ', f: 'ｆ', g: 'ｇ',
        h: 'ｈ', i: 'ｉ', j: 'ｊ', k: 'ｋ', l: 'ｌ', m: 'ｍ', n: 'ｎ',
        o: 'ｏ', p: 'ｐ', q: 'ｑ', r: 'ｒ', s: 'ｓ', t: 'ｔ', u: 'ｕ',
        v: 'ｖ', w: 'ｗ', x: 'ｘ', y: 'ｙ', z: 'ｚ'
      }
    },




    //Squiggle Fancy Fonts


    {
      name: 'SQUIGGLE Font 1',
      map: {
        a: 'α', b: 'ճ', c: 'c', d: 'ժ', e: 'ҽ', f: 'բ', g: 'ց',
        h: 'հ', i: 'í', j: 'յ', k: 'k', l: 'l', m: 'ต', n: 'ղ',
        o: 'օ', p: 'թ', q: 'զ', r: 'ɾ', s: 's', t: 'Ե', u: 'մ',
        v: 'ѵ', w: 'ա', x: 'x', y: 'ա', z: 'z'
      }
    },
    {
      name: 'SQUIGGLE Font 2',
      map: {
        a: 'Թ', b: 'Յ', c: 'Շ', d: 'Ժ', e: 'e', f: 'Բ', g: 'Գ',
        h: 'ɧ', i: 'ɿ', j: 'ʝ', k: 'k', l: 'ʅ', m: 'ʍ', n: 'Ռ',
        o: 'Ծ', p: 'ρ', q: 'φ', r: 'Ր', s: 'Տ', t: 'Ե', u: 'Մ',
        v: 'ע', w: 'ա', x: 'Ճ', y: 'ա', z: 'Հ'
      }
    },
    {
      name: 'SQUIGGLE Font 3',
      map: {
        a: 'ค', b: '๒', c: 'ς', d: '๔', e: 'є', f: 'Ŧ', g: 'ﻮ',
        h: 'ђ', i: 'เ', j: 'ן', k: 'к', l: 'ɭ', m: '๓', n: 'ภ',
        o: '๏', p: 'ק', q: 'ợ', r: 'г', s: 'ร', t: 'Շ', u: 'ย',
        v: 'ש', w: 'ฬ', x: 'א', y: 'ฬ', z: 'չ'
      }
    },
    {
      name: 'SQUIGGLE Font 4',
      map: {
        a: 'α', b: 'Ⴆ', c: 'ƈ', d: 'ԃ', e: 'ҽ', f: 'ϝ', g: 'ɠ',
        h: 'ԋ', i: 'ι', j: 'ʝ', k: 'ƙ', l: 'ʅ', m: 'ɱ', n: 'ɳ',
        o: 'σ', p: 'ρ', q: 'ϙ', r: 'ɾ', s: 'ʂ', t: 'ƚ', u: 'υ',
        v: 'ʋ', w: 'ɯ', x: 'x', y: 'ɯ', z: 'ȥ'
      }
    },
    {
      name: 'SQUIGGLE Font 5',
      map: {
        a: 'ǟ', b: 'ɮ', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɢ',
        h: 'ɦ', i: 'ɨ', j: 'ʝ', k: 'ӄ', l: 'ʟ', m: 'ʍ', n: 'ռ',
        o: 'օ', p: 'ք', q: 'զ', r: 'ʀ', s: 'ֆ', t: 'ȶ', u: 'ʊ',
        v: 'ʋ', w: 'ա', x: 'Ӽ', y: 'ա', z: 'ʐ'
      }
    },
    {
      name: 'SQUIGGLE Font 6',
      map: {
        a: 'Ꮧ', b: 'Ᏸ', c: 'ፈ', d: 'Ꮄ', e: 'Ꮛ', f: 'Ꭶ', g: 'Ꮆ',
        h: 'Ꮒ', i: 'Ꭵ', j: 'Ꮰ', k: 'Ꮶ', l: 'Ꮭ', m: 'Ꮇ', n: 'Ꮑ',
        o: 'Ꭷ', p: 'Ꭾ', q: 'Ꭴ', r: 'Ꮢ', s: 'Ꮥ', t: 'Ꮦ', u: 'Ꮼ',
        v: 'Ꮙ', w: 'Ꮗ', x: 'ጀ', y: 'Ꮗ', z: 'ፚ'
      }
    },
    {
      name: 'SQUIGGLE Font 7',
      map: {
        a: 'Ꭺ', b: 'b', c: 'Ꮯ', d: 'Ꭰ', e: 'Ꭼ', f: 'f', g: 'Ꮆ',
        h: 'h', i: 'Ꭵ', j: 'j', k: 'Ꮶ', l: 'Ꮮ', m: 'm', n: 'Ꮑ',
        o: 'Ꮎ', p: 'Ꮲ', q: 'q', r: 'Ꮢ', s: 's', t: 'Ꮖ', u: 'u',
        v: 'Ꮙ', w: 'Ꮃ', x: 'x', y: 'Ꮃ', z: 'Ꮓ'
      }
    },
    {
      name: 'SQUIGGLE Font 8',
      map: {
        a: 'ค', b: '๖', c: '¢', d: '໓', e: 'ē', f: 'f', g: 'ງ',
        h: 'h', i: 'i', j: 'ว', k: 'k', l: 'l', m: '๓', n: 'ຖ',
        o: '໐', p: 'p', q: '๑', r: 'r', s: 'Ş', t: 't', u: 'น',
        v: 'ง', w: 'ຟ', x: 'x', y: 'ຟ', z: 'ຊ'
      }
    },
    {
      name: 'SQUIGGLE Font 9',
      map: {
        a: 'α', b: 'в', c: 'c', d: '∂', e: 'є', f: 'f', g: 'g',
        h: 'н', i: 'ι', j: 'נ', k: 'к', l: 'ℓ', m: 'м', n: 'n',
        o: 'σ', p: 'ρ', q: 'q', r: 'я', s: 'ѕ', t: 'т', u: 'υ',
        v: 'ν', w: 'ω', x: 'х', y: 'ω', z: 'z'
      }
    },
    {
      name: 'SQUIGGLE Font 10',
      map: {
        a: 'ą', b: 'ც', c: 'ƈ', d: 'ɖ', e: 'ɛ', f: 'ʄ', g: 'ɠ',
        h: 'ɧ', i: 'ı', j: 'ʝ', k: 'ƙ', l: 'ʟ', m: 'ɱ', n: 'ŋ',
        o: 'ơ', p: '℘', q: 'զ', r: 'ཞ', s: 'ʂ', t: 'ƭ', u: 'ų',
        v: '۷', w: 'ῳ', x: 'ҳ', y: 'ῳ', z: 'ʑ'
      }
    },
    {
      name: 'SQUIGGLE Font 11',
      map: {
        a: 'α', b: 'ɓ', c: '૮', d: '∂', e: 'ε', f: 'ƒ', g: 'ɠ',
        h: 'ɦ', i: 'เ', j: 'ʝ', k: 'ҡ', l: 'ℓ', m: 'ɱ', n: 'ɳ',
        o: 'σ', p: 'ρ', q: 'φ', r: '૨', s: 'ร', t: 'ƭ', u: 'µ',
        v: 'ѵ', w: 'ω', x: 'א', y: 'ω', z: 'ƶ'
      }
    },
    {
      name: 'SQUIGGLE Font 12',
      map: {
        a: 'ƛ', b: 'Ɓ', c: 'Ƈ', d: 'Ɗ', e: 'Є', f: 'Ƒ', g: 'Ɠ',
        h: 'Ӈ', i: 'Ɩ', j: 'ʆ', k: 'Ƙ', l: 'Լ', m: 'M', n: 'Ɲ',
        o: 'Ơ', p: 'Ƥ', q: 'Ƣ', r: 'Ʀ', s: 'Ƨ', t: 'Ƭ', u: 'Ʋ',
        v: 'Ɣ', w: 'Ɯ', x: 'Ҳ', y: 'Ɯ', z: 'Ȥ'
      }
    },
    {
      name: 'SQUIGGLE Font 13',
      map: {
        a: 'ค', b: 'ც', c: '८', d: 'ძ', e: '૯', f: 'Բ', g: '૭',
        h: 'Һ', i: 'ɿ', j: 'ʆ', k: 'қ', l: 'Ն', m: 'ɱ', n: 'Ո',
        o: '૦', p: 'ƿ', q: 'ҩ', r: 'Ր', s: 'ς', t: '੮', u: 'υ',
        v: '౮', w: 'ω', x: '૪', y: 'ω', z: 'ઽ'
      }
    },
    {
      name: 'SQUIGGLE Font 14',
      map: {
        a: 'α', b: 'ß', c: 'ς', d: 'd', e: 'ε', f: 'ƒ', g: 'g',
        h: 'h', i: 'ï', j: 'յ', k: 'κ', l: 'ﾚ', m: 'm', n: 'η',
        o: '⊕', p: 'p', q: 'Ω', r: 'я', s: 'š', t: '†', u: 'u',
        v: '∀', w: 'ω', x: 'x', y: 'ω', z: 'z'
      }
    },
    {
      name: 'UPPER ANGLES Font',
      map: {
        a: 'Λ', b: 'B', c: 'ᄃ', d: 'D', e: 'Σ', f: 'F', g: 'G',
        h: 'Ή', i: 'I', j: 'J', k: 'K', l: 'ᄂ', m: 'M', n: 'П',
        o: 'Ө', p: 'P', q: 'Q', r: 'Я', s: 'Ƨ', t: 'Ƭ', u: 'Ц',
        v: 'V', w: 'Щ', x: 'X', y: 'Щ', z: 'Z'
      }
    },
    {
      name: 'fonts for facebook posts',
      map: {
        a: 'α', b: 'в', c: '¢', d: '∂', e: 'є', f: 'ƒ', g: 'g',
        h: 'н', i: 'ι', j: 'נ', k: 'к', l: 'ℓ', m: 'м', n: 'η',
        o: 'σ', p: 'ρ', q: 'q', r: 'я', s: 'ѕ', t: 'т', u: 'υ',
        v: 'ν', w: 'ω', x: 'χ', y: 'ω', z: 'z'
      }
    },
    {
      name: 'BENT TEXT Font',
      map: {
        a: 'ą', b: 'ҍ', c: 'ç', d: 'ժ', e: 'ҽ', f: 'ց', g: 'հ',
        h: 'ì', i: 'ʝ', j: 'ҟ', k: 'Ӏ', l: 'ʍ', m: 'ռ', n: 'օ',
        o: 'ք', p: 'զ', q: 'ʀ', r: 'ʂ', s: 'է', t: 'մ', u: 'ѵ',
        v: 'ա', w: '×', x: 'ա', y: 'Հ', z: 'Հ'
      }
    },
    {
      name: 'NEON Font - change font on facebook post',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑕ', d: 'ᗪ', e: 'E', f: 'ᖴ', g: 'G',
        h: 'ᕼ', i: 'I', j: 'ᒍ', k: 'K', l: 'ᒪ', m: 'ᗰ', n: 'ᑎ',
        o: 'O', p: 'ᑭ', q: 'ᑫ', r: 'ᖇ', s: 'ᔕ', t: 'T', u: 'ᑌ',
        v: 'ᐯ', w: 'ᗯ', x: '᙭', y: 'ᗯ', z: 'ᘔ'
      }
    },
    {
      name: 'facebook text changer - FUTURE ALIEN Font',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑢ', d: 'ᕲ', e: 'ᘿ', f: 'ᖴ', g: 'ᘜ',
        h: 'ᕼ', i: 'ᓰ', j: 'ᒚ', k: 'ᖽᐸ', l: 'ᒪ', m: 'ᘻ', n: 'ᘉ',
        o: 'ᓍ', p: 'ᕵ', q: 'ᕴ', r: 'ᖇ', s: 'S', t: 'ᖶ', u: 'ᑘ',
        v: 'ᐺ', w: 'ᘺ', x: '᙭', y: 'ᘺ', z: 'ᗱ'
      }
    },





    {
      name: 'facebook text changer',
      map: {
        a: 'ꍏ', b: '♭', c: '☾', d: '◗', e: '€', f: 'Ϝ', g: '❡',
        h: '♄', i: '♗', j: '♪', k: 'ϰ', l: '↳', m: '♔', n: '♫',
        o: '⊙', p: 'ρ', q: '☭', r: '☈', s: 'ⓢ', t: '☂', u: '☋',
        v: '✓', w: 'ω', x: '⌘', y: 'ω', z: '☡'
      }
    },
    {
      name: 'change font for facebook post',
      map: {
        a: '♬', b: 'ᖲ', c: '¢', d: 'ᖱ', e: '៩', f: '⨏', g: '❡',
        h: 'Ϧ', i: 'ɨ', j: 'ɉ', k: 'ƙ', l: 'ɭ', m: '៣', n: '⩎',
        o: '០', p: 'ᖰ', q: 'ᖳ', r: 'Ʀ', s: 'ន', t: 'Ƭ', u: '⩏',
        v: '⩔', w: 'Ɯ', x: '✗', y: 'Ɯ', z: 'Ȥ'
      }
    },
    {
      name: 'Stylish Symbols 3',
      map: {
        a: 'ꪖ', b: '᥇', c: 'ᥴ', d: 'ᦔ', e: 'ꫀ', f: 'ᠻ', g: 'ᧁ',
        h: 'ꫝ', i: '𝓲', j: '𝓳', k: '𝘬', l: 'ꪶ', m: 'ꪑ', n: 'ꪀ',
        o: 'ꪮ', p: 'ρ', q: '𝘲', r: '𝘳', s: '𝘴', t: '𝓽', u: 'ꪊ',
        v: 'ꪜ', w: '᭙', x: '᥊', y: '᭙', z: 'ɀ'
      }
    },
    {
      name: 'Stylish Symbols 4',
      map: {
        a: 'ａ', b: 'ᵇ', c: 'ｃ', d: 'Ď', e: '𝔢', f: 'ᶠ', g: 'Ｇ',
        h: 'ⓗ', i: 'ι', j: 'Ⓙ', k: '𝐊', l: 'ˡ', m: '爪', n: '𝐍',
        o: '𝔬', p: 'ᑭ', q: '𝐐', r: 'ᖇ', s: 'Ş', t: 't', u: '𝐔',
        v: '𝔳', w: '𝔴', x: 'x', y: '𝕨', z: 'ℤ'
      }
    },
    {
      name: 'Stylish Symbols 5',
      map: {
        a: 'ꍏ', b: 'ꌃ', c: 'ꉓ', d: 'ꀸ', e: 'ꍟ', f: 'ꎇ', g: 'ꁅ',
        h: 'ꃅ', i: 'ꀤ', j: 'ꀭ', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꈤ',
        o: 'ꂦ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꌗ', t: '꓄', u: 'ꀎ',
        v: 'ꃴ', w: 'ꅏ', x: 'ꊼ', y: 'ꅏ', z: 'ꁴ'
      }
    },
    {
      name: 'different fonts for facebook',
      map: {
        a: 'ꋬ', b: 'ꃳ', c: 'ꉔ', d: '꒯', e: 'ꏂ', f: 'ꊰ', g: 'ꍌ',
        h: 'ꁝ', i: '꒐', j: '꒻', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꋊ',
        o: 'ꄲ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꇙ', t: '꓄', u: '꒤',
        v: '꒦', w: 'ꅐ', x: 'ꉧ', y: 'ꅐ', z: 'ꁴ'
      }
    },
    {
      name: 'different fonts for facebook',
      map: {
        a: 'ꋫ', b: 'ꃃ', c: 'ꏸ', d: 'ꁕ', e: 'ꍟ', f: 'ꄘ', g: 'ꁍ',
        h: 'ꑛ', i: 'ꂑ', j: 'ꀭ', k: 'ꀗ', l: '꒒', m: 'ꁒ', n: 'ꁹ',
        o: 'ꆂ', p: 'ꉣ', q: 'ꁸ', r: '꒓', s: 'ꌚ', t: '꓅', u: 'ꐇ',
        v: 'ꏝ', w: 'ꅐ', x: 'ꇓ', y: 'ꅐ', z: 'ꁴ'
      }
    },
    {
      name: 'different fonts for facebook',
      map: {
        a: 'ꋬ', b: 'ꍗ', c: 'ꏳ', d: 'ꂟ', e: 'ꏂ', f: 'ꄟ', g: 'ꍌ',
        h: 'ꃬ', i: '꒐', j: '꒻', k: 'ꀘ', l: '꒒', m: 'ꂵ', n: 'ꂚ',
        o: 'ꉻ', p: 'ꉣ', q: 'ꋠ', r: 'ꋪ', s: 'ꑄ', t: '꓄', u: 'ꀎ',
        v: '꒦', w: 'ꅐ', x: 'ꉼ', y: 'ꅐ', z: 'ꑓ'
      }
    },
    {
      name: 'Stylish facebook fonts copy paste',
      map: {
        a: 'ꁲ', b: 'ꃃ', c: 'ꇃ', d: 'ꂡ', e: 'ꏹ', f: 'ꄙ', g: 'ꁍ',
        h: 'ꀍ', i: 'ꀤ', j: 'ꀭ', k: 'ꈵ', l: '꒒', m: 'ꂵ', n: 'ꋊ',
        o: 'ꁏ', p: 'ꉣ', q: 'ꆰ', r: 'ꋪ', s: 'ꌗ', t: 'ꋖ', u: 'ꌈ',
        v: 'ꃴ', w: 'ꅏ', x: 'ꋚ', y: 'ꅏ', z: 'ꁴ'
      }
    },
    {
      name: 'Ethiopic facebook fonts copy paste',
      map: {
        a: 'ል', b: 'ጌ', c: 'ር', d: 'ዕ', e: 'ቿ', f: 'ቻ', g: 'ኗ',
        h: 'ዘ', i: 'ጎ', j: 'ጋ', k: 'ጕ', l: 'ረ', m: 'ጠ', n: 'ክ',
        o: 'ዐ', p: 'የ', q: 'ዒ', r: 'ዪ', s: 'ነ', t: 'ፕ', u: 'ሁ',
        v: 'ሀ', w: 'ሠ', x: 'ሸ', y: 'ሠ', z: 'ጊ'
      }
    },
    {
      name: 'Cyrillic facebook fonts copy paste',
      map: {
        a: 'а', b: 'б', c: 'ц', d: 'д', e: 'ё', f: 'f', g: 'г',
        h: 'h', i: 'и', j: 'й', k: 'к', l: 'л', m: 'м', n: 'н',
        o: 'о', p: 'п', q: 'щ', r: 'р', s: 'с', t: 'т', u: 'у',
        v: 'в', w: 'щ', x: 'ж', y: 'ы', z: 'з'
      }
    },
    {
      name: 'special fonts for facebook',
      map: {
        a: 'Д', b: 'Б', c: 'C', d: '∂', e: 'Ξ', f: 'ƒ', g: 'g',
        h: 'h', i: 'ι', j: 'ј', k: 'к', l: 'л', m: 'м', n: 'и',
        o: 'о', p: 'р', q: 'Q', r: 'я', s: 'Ѕ', t: 't', u: 'ц',
        v: 'v', w: 'щ', x: 'χ', y: 'У', z: 'z'
      }
    },
    {
      name: 'Stylish Greek special fonts for facebook',
      map: {
        a: 'Д', b: 'Б', c: 'C', d: 'D', e: 'Ξ', f: 'F', g: 'G',
        h: 'H', i: 'I', j: 'J', k: 'K', l: 'L', m: 'M', n: 'Н',
        o: 'Ф', p: 'P', q: 'Q', r: 'Я', s: 'S', t: 'T', u: 'Ц',
        v: 'V', w: 'Щ', x: 'X', y: 'Υ', z: 'Z'
      }
    },
    {
      name: 'Fancy facebook font',
      map: {
        a: '卂', b: '乃', c: '匚', d: 'ᗪ', e: '乇', f: '千', g: 'Ꮆ',
        h: '卄', i: '丨', j: 'ﾌ', k: 'Ҝ', l: 'ㄥ', m: '爪', n: '几',
        o: 'ㄖ', p: '卩', q: 'Ɋ', r: '尺', s: '丂', t: 'ㄒ', u: 'ㄩ',
        v: 'ᐯ', w: '山', x: '乂', y: '山', z: '乙'
      }
    },
    {
      name: 'text font for facebook Currency',
      map: {
        a: '₳', b: '฿', c: '₵', d: 'Đ', e: 'Ɇ', f: '₣', g: '₲',
        h: 'Ⱨ', i: 'ł', j: 'J', k: '₭', l: 'Ⱡ', m: '₥', n: '₦',
        o: 'Ø', p: '₱', q: 'Q', r: 'Ɽ', s: '₴', t: '₮', u: 'Ʉ',
        v: 'V', w: '₩', x: 'Ӿ', y: '₩', z: 'Ⱬ'
      }
    },
    {
      name: 'text font for facebook flipped',
      map: {
        a: 'z', b: 'ʍ', c: 'x', d: 'ʍ', e: 'ʌ', f: 'n', g: 'ʇ',
        h: 's', i: 'ɹ', j: 'b', k: 'd', l: 'o', m: 'u', n: 'ɯ',
        o: 'l', p: 'ʞ', q: 'ɾ', r: 'ı', s: 'ɥ', t: 'ɓ', u: 'ɟ',
        v: 'ǝ', w: 'p', x: 'ɔ', y: 'q', z: 'ɐ'
      }
    },
    {
      name: 'Fancy Sparkle',
      map: {
        a: 'a҉', b: 'b҉', c: 'c҉', d: 'd҉', e: 'e҉', f: 'f҉', g: 'g҉',
        h: 'h҉', i: 'i҉', j: 'j҉', k: 'k҉', l: 'l҉', m: 'm҉', n: 'n҉',
        o: 'o҉', p: 'p҉', q: 'q҉', r: 'r҉', s: 's҉', t: 't҉', u: 'u҉',
        v: 'v҉', w: 'w҉', x: 'x҉', y: 'w҉', z: 'z҉'
      }
    },







    {
      name: 'Thunder',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̶', b: 'b̶', c: 'c̶', d: 'd̶', e: 'e̶', f: 'f̶', g: 'g̶',
        h: 'h̶', i: 'i̶', j: 'j̶', k: 'k̶', l: 'l̶', m: 'm̶', n: 'n̶',
        o: 'o̶', p: 'p̶', q: 'q̶', r: 'r̶', s: 's̶', t: 't̶', u: 'u̶',
        v: 'v̶', w: 'w̶', x: 'x̶', y: 'y̶', z: 'z̶'
      }
    },
    {
      name: 'Strikethrough',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̴', b: 'b̴', c: 'c̴', d: 'd̴', e: 'e̴', f: 'f̴', g: 'g̴',
        h: 'h̴', i: 'i̴', j: 'j̴', k: 'k̴', l: 'l̴', m: 'm̴', n: 'n̴',
        o: 'o̴', p: 'p̴', q: 'q̴', r: 'r̴', s: 's̴', t: 't̴', u: 'u̴',
        v: 'v̴', w: 'w̴', x: 'x̴', y: 'y̴', z: 'z̴'
      }
    },
    {
      name: 'Slash',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̷', b: 'b̷', c: 'c̷', d: 'd̷', e: 'e̷', f: 'f̷', g: 'g̷',
        h: 'h̷', i: 'i̷', j: 'j̷', k: 'k̷', l: 'l̷', m: 'm̷', n: 'n̷',
        o: 'o̷', p: 'p̷', q: 'q̷', r: 'r̷', s: 's̷', t: 't̷', u: 'u̷',
        v: 'v̷', w: 'w̷', x: 'x̷', y: 'y̷', z: 'z̷'
      }
    },
    {
      name: 'Underline',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̲', b: 'b̲', c: 'c̲', d: 'd̲', e: 'e̲', f: 'f̲', g: 'g̲',
        h: 'h̲', i: 'i̲', j: 'j̲', k: 'k̲', l: 'l̲', m: 'm̲', n: 'n̲',
        o: 'o̲', p: 'p̲', q: 'q̲', r: 'r̲', s: 's̲', t: 't̲', u: 'u̲',
        v: 'v̲', w: 'w̲', x: 'x̲', y: 'y̲', z: 'z̲'
      }
    },
    {
      name: 'Stinky',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a͛', b: 'b͛', c: 'c͛', d: 'd͛', e: 'e͛', f: 'f͛', g: 'g͛',
        h: 'h͛', i: 'i͛', j: 'j͛', k: 'k͛', l: 'l͛', m: 'm͛', n: 'n͛',
        o: 'o͛', p: 'p͛', q: 'q͛', r: 'r͛', s: 's͛', t: 't͛', u: 'u͛',
        v: 'v͛', w: 'w͛', x: 'x͛', y: 'y͛', z: 'z͛'
      }
    },
    {
      name: 'Seagull',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̾', b: 'b̾', c: 'c̾', d: 'd̾', e: 'e̾', f: 'f̾', g: 'g̾',
        h: 'h̾', i: 'i̾', j: 'j̾', k: 'k̾', l: 'l̾', m: 'm̾', n: 'n̾',
        o: 'o̾', p: 'p̾', q: 'q̾', r: 'r̾', s: 's̾', t: 't̾', u: 'u̾',
        v: 'v̾', w: 'w̾', x: 'x̾', y: 'y̾', z: 'z̾'
      }
    },
    {
      name: 'Arrow',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a͎', b: 'b͎', c: 'c͎', d: 'd͎', e: 'e͎', f: 'f͎', g: 'g͎',
        h: 'h͎', i: 'i͎', j: 'j͎', k: 'k͎', l: 'l͎', m: 'm͎', n: 'n͎',
        o: 'o͎', p: 'p͎', q: 'q͎', r: 'r͎', s: 's͎', t: 't͎', u: 'u͎',
        v: 'v͎', w: 'w͎', x: 'x͎', y: 'y͎', z: 'z͎'
      }
    },
    {
      name: 'Frame',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a̺͆', b: 'b̺͆', c: 'c̺͆', d: 'd̺͆', e: 'e̺͆', f: 'f̺͆', g: 'g̺͆',
        h: 'h̺͆', i: 'i̺͆', j: 'j̺͆', k: 'k̺͆', l: 'l̺͆', m: 'm̺͆', n: 'n̺͆',
        o: 'o̺͆', p: 'p̺͆', q: 'q̺͆', r: 'r̺͆', s: 's̺͆', t: 't̺͆', u: 'u̺͆',
        v: 'v̺͆', w: 'w̺͆', x: 'x̺͆', y: 'y̺͆', z: 'z̺͆'
      }
    },
    {
      name: 'Arch',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'ȃ̮', b: 'b̮̑', c: 'c̮̑', d: 'd̮̑', e: 'ȇ̮', f: 'f̮̑', g: 'g̮̑',
        h: 'ḫ̑', i: 'ȋ̮', j: 'j̮̑', k: 'k̮̑', l: 'l̮̑', m: 'm̮̑', n: 'n̮̑',
        o: 'ȏ̮', p: 'p̮̑', q: 'q̮̑', r: 'ȓ̮', s: 's̮̑', t: 't̮̑', u: 'ȗ̮',
        v: 'v̮̑', w: 'w̮̑', x: 'x̮̑', y: 'y̮̑', z: 'z̮̑'
      }
    },
    {
      name: 'Cross',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a͓̽', b: 'b͓̽', c: 'c͓̽', d: 'd͓̽', e: 'e͓̽', f: 'f͓̽', g: 'g͓̽',
        h: 'h͓̽', i: 'i͓̽', j: 'j͓̽', k: 'k͓̽', l: 'l͓̽', m: 'm͓̽', n: 'n    n͓̽',
        o: 'o͓̽', p: 'p͓̽', q: 'q͓̽', r: 'r͓̽', s: 's͓̽', t: 't͓̽', u: 'u͓̽',
        v: 'v͓̽', w: 'w͓̽', x: 'x͓̽', y: 'y͓̽', z: 'z͓̽'
      }
    },
    {
      name: 'Hearts Between',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a♥', b: 'b♥', c: 'c♥', d: 'd♥', e: 'e♥', f: 'f♥', g: 'g♥',
        h: 'h♥', i: 'i♥', j: 'j♥', k: 'k♥', l: 'l♥', m: 'm♥', n: 'n♥',
        o: 'o♥', p: 'p♥', q: 'q♥', r: 'r♥', s: 's♥', t: 't♥', u: 'u♥',
        v: 'v♥', w: 'w♥', x: 'x♥', y: 'y♥', z: 'z♥'
      }
    },
    {
      name: 'Wavy Joiner',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '≋a≋', b: '≋b≋', c: '≋c≋', d: '≋d≋', e: '≋e≋', f: '≋f≋', g: '≋g≋',
        h: '≋h≋', i: '≋i≋', j: '≋j≋', k: '≋k≋', l: '≋l≋', m: '≋m≋', n: '≋n≋',
        o: '≋o≋', p: '≋p≋', q: '≋q≋', r: '≋r≋', s: '≋s≋', t: '≋t≋', u: '≋u≋',
        v: '≋v≋', w: '≋w≋', x: '≋x≋', y: '≋y≋', z: '≋z≋'
      }
    },
    {
      name: 'Curly Joiner',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '⊰a⊱', b: '⊰b⊱', c: '⊰c⊱', d: '⊰d⊱', e: '⊰e⊱', f: '⊰f⊱', g: '⊰g⊱',
        h: '⊰h⊱', i: '⊰i⊱', j: '⊰j⊱', k: '⊰k⊱', l: '⊰l⊱', m: '⊰m⊱', n: '⊰n⊱',
        o: '⊰o⊱', p: '⊰p⊱', q: '⊰q⊱', r: '⊰r⊱', s: '⊰s⊱', t: '⊰t⊱', u: '⊰u⊱',
        v: '⊰v⊱', w: '⊰w⊱', x: '⊰x⊱', y: '⊰y⊱', z: '⊰z⊱'
      }
    },
    {
      name: 'Dotty Joiner',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '░a░', b: '░b░', c: '░c░', d: '░d░', e: '░e░', f: '░f░', g: '░g░',
        h: '░h░', i: '░i░', j: '░j░', k: '░k░', l: '░l░', m: '░m░', n: '░n░',
        o: '░o░', p: '░p░', q: '░q░', r: '░r░', s: '░s░', t: '░t░', u: '░u░',
        v: '░v░', w: '░w░', x: '░x░', y: '░y░', z: '░z░'
      }
    },
    {
      name: 'Connected Joiner',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: 'a⊶', b: 'b⊶', c: 'c⊶', d: 'd⊶', e: 'e⊶', f: 'f⊶', g: 'g⊶',
        h: 'h⊶', i: 'i⊶', j: 'j⊶', k: 'k⊶', l: 'l⊶', m: 'm⊶', n: 'n⊶',
        o: 'o⊶', p: 'p⊶', q: 'q⊶', r: 'r⊶', s: 's⊶', t: 't⊶', u: 'u⊶',
        v: 'v⊶', w: 'w⊶', x: 'x⊶', y: 'y⊶', z: 'z⊶'
      }
    },
    {
      name: 'White Parenthesis',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '⦅a⦆', b: '⦅b⦆', c: '⦅c⦆', d: '⦅d⦆', e: '⦅e⦆', f: '⦅f⦆', g: '⦅g⦆',
        h: '⦅h⦆', i: '⦅i⦆', j: '⦅j⦆', k: '⦅k⦆', l: '⦅l⦆', m: '⦅m⦆', n: '⦅n⦆',
        o: '⦅o⦆', p: '⦅p⦆', q: '⦅q⦆', r: '⦅r⦆', s: '⦅s⦆', t: '⦅t⦆', u: '⦅u⦆',
        v: '⦅v⦆', w: '⦅w⦆', x: '⦅x⦆', y: '⦅y⦆', z: '⦅z⦆'
      }
    },
    {
      name: 'Arch Up',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '╰a╯', b: '╰b╯', c: '╰c╯', d: '╰d╯', e: '╰e╯', f: '╰f╯', g: '╰g╯',
        h: '╰h╯', i: '╰i╯', j: '╰j╯', k: '╰k╯', l: '╰l╯', m: '╰m╯', n: '╰n╯',
        o: '╰o╯', p: '╰p╯', q: '╰q╯', r: '╰r╯', s: '╰s╯', t: '╰t╯', u: '╰u╯',
        v: '╰v╯', w: '╰w╯', x: '╰x╯', y: '╰y╯', z: '╰z╯'
      }
    },
    {
      name: 'Double Up Box',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '╚a╝', b: '╚b╝', c: '╚c╝', d: '╚d╝', e: '╚e╝', f: '╚f╝', g: '╚g╝',
        h: '╚h╝', i: '╚i╝', j: '╚j╝', k: '╚k╝', l: '╚l╝', m: '╚m╝', n: '╚n╝',
        o: '╚o╝', p: '╚p╝', q: '╚q╝', r: '╚r╝', s: '╚s╝', t: '╚t╝', u: '╚u╝',
        v: '╚v╝', w: '╚w╝', x: '╚x╝', y: '╚y╝', z: '╚z╝'
      }
    },
    {
      name: 'Double Vertical Box',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '╠a╣', b: '╠b╣', c: '╠c╣', d: '╠d╣', e: '╠e╣', f: '╠f╣', g: '╠g╣',
        h: '╠h╣', i: '╠i╣', j: '╠j╣', k: '╠k╣', l: '╠l╣', m: '╠m╣', n: '╠n╣',
        o: '╠o╣', p: '╠p╣', q: '╠q╣', r: '╠r╣', s: '╠s╣', t: '╠t╣', u: '╠u╣',
        v: '╠v╣', w: '╠w╣', x: '╠x╣', y: '╠y╣', z: '╠z╣'
      }
    },
    {
      name: 'White Bracket',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '『a』', b: '『b』', c: '『c』', d: '『d』', e: '『e』', f: '『f』', g: '『g』',
        h: '『h』', i: '『i』', j: '『j』', k: '『k』', l: '『l』', m: '『m』', n: '『n』',
        o: '『o』', p: '『p』', q: '『q』', r: '『r』', s: '『s』', t: '『t』', u: '『u』',
        v: '『v』', w: '『w』', x: '『x』', y: '『y』', z: '『z』'
      }
    },
    {
      name: 'Dark Bracket',
      prefix: ' ',
      suffix: ' ',
      map: {
        a: '【a】', b: '【b】', c: '【c】', d: '【d】', e: '【e】', f: '【f】', g: '【g】',
        h: '【h】', i: '【i】', j: '【j】', k: '【k】', l: '【l】', m: '【m】', n: '【n】',
        o: '【o】', p: '【p】', q: '【q】', r: '【r】', s: '【s】', t: '【t】', u: '【u】',
        v: '【v】', w: '【w】', x: '【x】', y: '【y】', z: '【z】'
      }
    },
    {
      name: 'Decoration 1',
      prefix: '★彡',
      suffix: '彡★',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 2',
      prefix: '(★)',
      suffix: '(★)',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 3',
      prefix: 'ıllıllı',
      suffix: 'ıllıllı',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 4',
      prefix: '▄︻┻',
      suffix: '︻┳═─-',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 5',
      prefix: '✎﹏﹏',
      suffix: '﹏﹏',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Decoration 6',
      prefix: '꧁༒༺',
      suffix: '༻༒꧂',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'font style name for facebook',
      prefix: '♫ ♪ ♫',
      suffix: '♫ ♪ ♫',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Random Decoration 2',
      prefix: '▀▄▀▄▀▄',
      suffix: '▄▀▄▀▄▀',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'font online facebook',
      prefix: '┑(￣▽￣)┍',
      suffix: '┑(￣▽￣)┍',
      map: {
        a: 'ᗩ', b: 'ᗷ', c: 'ᑕ', d: 'ᗪ', e: 'E', f: 'ᖴ', g: 'G',
        h: 'ᕼ', i: 'I', j: 'ᒍ', k: 'K', l: 'ᒪ', m: 'ᗰ', n: 'ᑎ',
        o: 'O', p: 'ᑭ', q: 'ᑫ', r: 'ᖇ', s: 'ᔕ', t: 'T', u: 'ᑌ',
        v: 'ᐯ', w: 'ᗯ', x: '᙭', y: 'Y', z: 'ᘔ'
      }
    },



    {
      name: 'font for facebook name random',
      prefix: '☞♟',
      suffix: '✊☺',
      map: {
        a: '₳', b: '฿', c: '₵', d: 'Đ', e: 'Ɇ', f: '₣', g: '₲',
        h: 'Ⱨ', i: 'ł', j: 'J', k: '₭', l: 'Ⱡ', m: '₥', n: '₦',
        o: 'Ø', p: '₱', q: 'Q', r: 'Ɽ', s: '₴', t: '₮', u: 'Ʉ',
        v: 'V', w: '₩', x: 'Ӿ', y: 'Ɏ', z: 'Ⱬ'
      }
    },
    {
      name: 'Random 2',
      prefix: '♚☺',
      suffix: '🎯♪',
      map: {
        a: 'ⓐ', b: '𝓫', c: '𝓒', d: '๔', e: 'ⓔ', f: 'ᶠ', g: 'ﻮ',
        h: 'Ⓗ', i: '𝒾', j: 'נ', k: 'к', l: '𝕝', m: '𝓶', n: 'ℕ',
        o: '𝓞', p: '𝕡', q: 'ᵠ', r: '尺', s: 'ş', t: '𝓽', u: '𝐮',
        v: '𝕧', w: 'Ⓦ', x: 'א', y: 'Ⓨ', z: '𝔷'
      }
    },
    {
      name: 'Random 3',
      prefix: '•.¸¸.•',
      suffix: '•.¸¸.•',
      map: {
        a: 'ꍏ', b: '♭', c: '☾', d: '◗', e: '€', f: 'Ϝ', g: '❡',
        h: '♄', i: '♗', j: '♪', k: 'ϰ', l: '↳', m: '♔', n: '♫',
        o: '🌸', p: 'ρ', q: '☭', r: '☈', s: 'ⓢ', t: '☂', u: '☋',
        v: '✓', w: 'ω', x: '⌘', y: '☿', z: '☡'
      }
    },
    {
      name: 'Random 4',
      prefix: '🐫 ⋆ 🐠',
      suffix: '🐠 ⋆ 🐫',
      map: {
        a: '𝚊', b: '𝚋', c: '𝚌', d: '𝚍', e: '𝚎', f: '𝚏', g: '𝚐',
        h: '𝚑', i: '𝚒', j: '𝚓', k: '𝚔', l: '𝚕', m: '𝚖', n: '𝚗',
        o: '❤', p: '𝚙', q: '𝚚', r: '𝚛', s: '𝚜', t: '𝚝', u: '𝚞',
        v: '𝚟', w: '𝚠', x: '𝚡', y: '𝚢', z: '𝚣'
      }
    },
    {
      name: 'Random 5',
      prefix: '«--(¯`v´¯)--»',
      suffix: '«--(¯`v´¯)--»',
      map: {
        a: 'ค', b: '๖', c: '¢', d: '໓', e: 'ē', f: 'f', g: 'ງ',
        h: 'hi', i: 'ว', j: 'k', k: '๓', l: 'ຖ', m: '໐', n: 'p',
        o: '๑', p: 'r', q: 'Ş', r: 't', s: 'น', t: 'ง', u: 'ຟ',
        v: 'x', w: 'ฯ', x: 'ຊ', y: '«--(¯`v´¯)--»', z: '«--(¯`v´¯)--»'
      }
    },
    {
      name: 'font change facebook',
      prefix: '↤↤↤↤↤',
      suffix: '↦↦↦↦↦',
      map: {
        a: 'a̴', b: 'b̴', c: 'c̴', d: 'd̴', e: 'e̴', f: 'f̴', g: 'g̴',
        h: 'h̴', i: 'i̴', j: 'j̴', k: 'k̴', l: 'l̴', m: 'm̴', n: 'n̴',
        o: 'o̴', p: 'p̴', q: 'q̴', r: 'r̴', s: 's̴', t: 't̴', u: 'u̴',
        v: 'v̴', w: 'w̴', x: 'x̴', y: 'y̴', z: 'z̴'
      }
    },

    {
      name: 'changing the font on facebook Fraktur',
      map: {
        A: '𝔄', B: '𝔅', C: 'ℭ', D: '𝔇', E: '𝔈', F: '𝔉', G: '𝔊',
        H: 'ℌ', I: 'ℑ', J: '𝔍', K: '𝔎', L: '𝔏', M: '𝔐', N: '𝔑',
        O: '𝔒', P: '𝔓', Q: '𝔔', R: 'ℜ', S: '𝔖', T: '𝔗', U: '𝔘',
        V: '𝔙', W: '𝔚', X: '𝔛', Y: '𝔜', Z: 'ℨ',
        a: '𝔞', b: '𝔟', c: '𝔠', d: '𝔡', e: '𝔢', f: '𝔣', g: '𝔤',
        h: '𝔥', i: '𝔦', j: '𝔧', k: '𝔨', l: '𝔩', m: '𝔪', n: '𝔫',
        o: '𝔬', p: '𝔭', q: '𝔮', r: '𝔯', s: '𝔰', t: '𝔱', u: '𝔲',
        v: '𝔳', w: '𝔴', x: '𝔵', y: '𝔶', z: '𝔷'
      }
    },
    {
      name: 'Bold Fraktur',
      map: {
        A: '𝕬', B: '𝕭', C: '𝕮', D: '𝕯', E: '𝕰', F: '𝕱', G: '𝕲',
        H: '𝕳', I: '𝕴', J: '𝕵', K: '𝕶', L: '𝕷', M: '𝕸', N: '𝕹',
        O: '𝕺', P: '𝕻', Q: '𝕼', R: '𝕽', S: '𝕾', T: '𝕿', U: '𝖀',
        V: '𝖁', W: '𝖂', X: '𝖃', Y: '𝖄', Z: '𝖅',
        a: '𝖆', b: '𝖇', c: '𝖈', d: '𝖉', e: '𝖊', f: '𝖋', g: '𝖌',
        h: '𝖍', i: '𝖎', j: '𝖏', k: '𝖐', l: '𝖑', m: '𝖒', n: '𝖓',
        o: '𝖔', p: '𝖕', q: '𝖖', r: '𝖗', s: '𝖘', t: '𝖙', u: '𝖚',
        v: '𝖛', w: '𝖜', x: '𝖝', y: '𝖞', z: '𝖟'
      }
    },
    {
      name: 'stylish font for facebook name Double Struck',
      map: {
        A: '𝔸', B: '𝔹', C: 'ℂ', D: '𝔻', E: '𝔼', F: '𝔽', G: '𝔾',
        H: 'ℍ', I: '𝕀', J: '𝕁', K: '𝕂', L: '𝕃', M: '𝕄', N: 'ℕ',
        O: '𝕆', P: 'ℙ', Q: 'ℚ', R: 'ℝ', S: '𝕊', T: '𝕋', U: '𝕌',
        V: '𝕍', W: '𝕎', X: '𝕏', Y: '𝕐', Z: 'ℤ',
        a: '𝕒', b: '𝕓', c: '𝕔', d: '𝕕', e: '𝕖', f: '𝕗', g: '𝕘',
        h: '𝕙', i: '𝕚', j: '𝕛', k: '𝕜', l: '𝕝', m: '𝕞', n: '𝕟',
        o: '𝕠', p: '𝕡', q: '𝕢', r: '𝕣', s: '𝕤', t: '𝕥', u: '𝕦',
        v: '𝕧', w: '𝕨', x: '𝕩', y: '𝕪', z: '𝕫'
      }
    },
    {
      name: 'Cursive',
      map: {
        A: '𝒜', B: '𝐵', C: '𝒞', D: '𝒟', E: '𝐸', F: '𝐹', G: '𝒢',
        H: '𝐻', I: '𝐼', J: '𝒥', K: '𝒦', L: '𝐿', M: '𝑀', N: '𝒩',
        O: '𝒪', P: '𝒫', Q: '𝒬', R: '𝑅', S: '𝒮', T: '𝒯', U: '𝒰',
        V: '𝒱', W: '𝒲', X: '𝒳', Y: '𝒴', Z: '𝒵',
        a: '𝒶', b: '𝒷', c: '𝒸', d: '𝒹', e: '𝑒', f: '𝒻', g: '𝑔',
        h: '𝒽', i: '𝒾', j: '𝒿', k: '𝓀', l: '𝓁', m: '𝓂', n: '𝓃',
        o: '𝑜', p: '𝓅', q: '𝓆', r: '𝓇', s: '𝓈', t: '𝓉', u: '𝓊',
        v: '𝓋', w: '𝓌', x: '𝓍', y: '𝓎', z: '𝓏'
      }
    },
    {
      name: 'Bold Cursive',
      map: {
        A: '𝓐', B: '𝓑', C: '𝓒', D: '𝓓', E: '𝓔', F: '𝓕', G: '𝓖',
        H: '𝓗', I: '𝓘', J: '𝓙', K: '𝓚', L: '𝓛', M: '𝓜', N: '𝓝',
        O: '𝓞', P: '𝓟', Q: '𝓠', R: '𝓡', S: '𝓢', T: '𝓣', U: '𝓤',
        V: '𝓥', W: '𝓦', X: '𝓧', Y: '𝓨', Z: '𝓩',
        a: '𝓪', b: '𝓫', c: '𝓬', d: '𝓭', e: '𝓮', f: '𝓯', g: '𝓰',
        h: '𝓱', i: '𝓲', j: '𝓳', k: '𝓴', l: '𝓵', m: '𝓶', n: '𝓷',
        o: '𝓸', p: '𝓹', q: '𝓺', r: '𝓻', s: '𝓼', t: '𝓽', u: '𝓾',
        v: '𝓿', w: '𝔀', x: '𝔁', y: '𝔂', z: '𝔃'
      }
    },
    {
      name: 'Monospace',
      map: {
        A: '𝙰', B: '𝙱', C: '𝙲', D: '𝙳', E: '𝙴', F: '𝙵', G: '𝙶',
        H: '𝙷', I: '𝙸', J: '𝙹', K: '𝙺', L: '𝙻', M: '𝙼', N: '𝙽',
        O: '𝙾', P: '𝙿', Q: '𝚀', R: '𝚁', S: '𝚂', T: '𝚃', U: '𝚄',
        V: '𝚅', W: '𝚆', X: '𝚇', Y: '𝚈', Z: '𝚉',
        a: '𝚊', b: '𝚋', c: '𝚌', d: '𝚍', e: '𝚎', f: '𝚏', g: '𝚐',
        h: '𝚑', i: '𝚒', j: '𝚓', k: '𝚔', l: '𝚕', m: '𝚖', n: '𝚗',
        o: '𝚘', p: '𝚙', q: '𝚚', r: '𝚛', s: '𝚜', t: '𝚝', u: '𝚞',
        v: '𝚟', w: '𝚠', x: '𝚡', y: '𝚢', z: '𝚣'
      }
    },
    {
      name: 'stylish font for facebook Bubbles',
      map: {
        A: 'Ⓐ', B: 'Ⓑ', C: 'Ⓒ', D: 'Ⓓ', E: 'Ⓔ', F: 'Ⓕ', G: 'Ⓖ',
        H: 'Ⓗ', I: 'Ⓘ', J: 'Ⓙ', K: 'Ⓚ', L: 'Ⓛ', M: 'Ⓜ', N: 'Ⓝ',
        O: 'Ⓞ', P: 'Ⓟ', Q: 'Ⓠ', R: 'Ⓡ', S: 'Ⓢ', T: 'Ⓣ', U: 'Ⓤ',
        V: 'Ⓥ', W: 'Ⓦ', X: 'Ⓧ', Y: 'Ⓨ', Z: 'Ⓩ',
        a: 'ⓐ', b: 'ⓑ', c: 'ⓒ', d: 'ⓓ', e: 'ⓔ', f: 'ⓕ', g: 'ⓖ',
        h: 'ⓗ', i: 'ⓘ', j: 'ⓙ', k: 'ⓚ', l: 'ⓛ', m: 'ⓜ', n: 'ⓝ',
        o: 'ⓞ', p: 'ⓟ', q: 'ⓠ', r: 'ⓡ', s: 'ⓢ', t: 'ⓣ', u: 'ⓤ',
        v: 'ⓥ', w: 'ⓦ', x: 'ⓧ', y: 'ⓨ', z: 'ⓩ'
      }
    },
    {
      name: 'Dark Bubbles',
      map: {
        A: '🅐', B: '🅑', C: '🅒', D: '🅓', E: '🅔', F: '🅕', G: '🅖',
        H: '🅗', I: '🅘', J: '🅙', K: '🅚', L: '🅛', M: '🅜', N: '🅝',
        O: '🅞', P: '🅟', Q: '🅠', R: '🅡', S: '🅢', T: '🅣', U: '🅤',
        V: '🅥', W: '🅦', X: '🅧', Y: '🅨', Z: '🅩',
        a: '🅐', b: '🅑', c: '🅒', d: '🅓', e: '🅔', f: '🅕', g: '🅖',
        h: '🅗', i: '🅘', j: '🅙', k: '🅚', l: '🅛', m: '🅜', n: '🅝',
        o: '🅞', p: '🅟', q: '🅠', r: '🅡', s: '🅢', t: '🅣', u: '🅤',
        v: '🅥', w: '🅦', x: '🅧', y: '🅨', z: '🅩'
      }
    },
    {
      name: 'Squares',
      map: {
        A: '🄰', B: '🄱', C: '🄲', D: '🄳', E: '🄴', F: '🄵', G: '🄶',
        H: '🄷', I: '🄸', J: '🄹', K: '🄺', L: '🄻', M: '🄼', N: '🄽',
        O: '🄾', P: '🄿', Q: '🅀', R: '🅁', S: '🅂', T: '🅃', U: '🅄',
        V: '🅅', W: '🅆', X: '🅇', Y: '🅈', Z: '🅉',
        a: '🄰', b: '🄱', c: '🄲', d: '🄳', e: '🄴', f: '🄵', g: '🄶',
        h: '🄷', i: '🄸', j: '🄹', k: '🄺', l: '🄻', m: '🄼', n: '🄽',
        o: '🄾', p: '🄿', q: '🅀', r: '🅁', s: '🅂', t: '🅃', u: '🅄',
        v: '🅅', w: '🅆', x: '🅇', y: '🅈', z: '🅉'
      }
    },
    {
      name: 'Dark Squares',
      map: {
        A: '🅰', B: '🅱', C: '🅲', D: '🅳', E: '🅴', F: '🅵', G: '🅶',
        H: '🅷', I: '🅸', J: '🅹', K: '🅺', L: '🅻', M: '🅼', N: '🅽',
        O: '🅾', P: '🅿', Q: '🆀', R: '🆁', S: '🆂', T: '🆃', U: '🆄',
        V: '🆅', W: '🆆', X: '🆇', Y: '🆈', Z: '🆉',
        a: '🅰', b: '🅱', c: '🅲', d: '🅳', e: '🅴', f: '🅵', g: '🅶',
        h: '🅷', i: '🅸', j: '🅹', k: '🅺', l: '🅻', m: '🅼', n: '🅽',
        o: '🅾', p: '🅿', q: '🆀', r: '🆁', s: '🆂', t: '🆃', u: '🆄',
        v: '🆅', w: '🆆', x: '🆇', y: '🆈', z: '🆉'
      }
    },
    {
      name: 'Sky Blue',
      map: {
        A: '🅐', B: '🅑', C: '🅒', D: '🅓', E: '🅔', F: '🅕', G: '🅖',
        H: '🅗', I: '🅘', J: '🅙', K: '🅚', L: '🅛', M: '      🅜', N: '🅝',
        O: '🅞', P: '🅟', Q: '🅠', R: '🅡', S: '🅢', T: '🅣', U: '🅤',
        V: '🅥', W: '🅦', X: '🅧', Y: '🅨', Z: '🅩',
        a: '🅐', b: '🅑', c: '🅒', d: '🅓', e: '🅔', f: '🅕', g: '🅖',
        h: '🅗', i: '🅘', j: '🅙', k: '🅚', l: '🅛', m: '🅜', n: '🅝',
        o: '🅞', p: '🅟', q: '🅠', r: '🅡', s: '🅢', t: '🅣', u: '🅤',
        v: '🅥', w: '🅦', x: '🅧', y: '🅨', z: '🅩'
      }
    },

    // Add other font mappings here...

    {
      name: 'Random Decoration 3',
      prefix: '★---★',
      suffix: '★---★',
      map: {
        a: '𝒂', b: '𝒃', c: '𝒄', d: '𝒅', e: '𝒆', f: '𝒇', g: '𝒈',
        h: '𝒉', i: '𝒊', j: '𝒋', k: '𝒌', l: '𝒍', m: '𝒎', n: '𝒏',
        o: '𝒐', p: '𝒑', q: '𝒒', r: '𝒓', s: '𝒔', t: '𝒕', u: '𝒖',
        v: '𝒗', w: '𝒘', x: '𝒙', y: '𝒚', z: '𝒛'
      }
    },
    {
      name: 'Random Decoration 4',
      prefix: 'ஜ۞ஜ',
      suffix: 'ஜ۞ஜ',
      map: {
        a: '𝐀', b: 'ｂ', c: 'Č', d: 'ᗪ', e: '𝐞', f: '𝓕', g: 'ᵍ',
        h: 'ℍ', i: '𝓘', j: '𝓙', k: 'ᛕ', l: 'Ł', m: 'ᵐ', n: 'η',
        o: 'σ', p: '𝕡', q: '𝓆', r: 'г', s: 'ˢ', t: 'Ŧ', u: '𝓊',
        v: 'ν', w: 'Ⓦ', x: '𝓍', y: '𝐘', z: '𝕫'
      }
    },





    {
      name: 'Kirby Hug',
      prefix: '(っ◔◡◔っ ♥',
      suffix: '♥',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Cool Emoticon',
      prefix: '(⌐■_■)',
      suffix: '(⌐■_■)',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Random Emoticons 1',
      prefix: '(ʘ‿ʘ)',
      suffix: '(ʘ‿ʘ)',
      map: {
        a: 'a', b: 'b', c: 'c', d: 'd', e: 'e', f: 'f', g: 'g',
        h: 'h', i: 'i', j: 'j', k: 'k', l: 'l', m: 'm', n: 'n',
        o: 'o', p: 'p', q: 'q', r: 'r', s: 's', t: 't', u: 'u',
        v: 'v', w: 'w', x: 'x', y: 'y', z: 'z'
      }
    },
    {
      name: 'Random Emoticons 2',
      prefix: '≧(◉◡◉)≦',
      suffix: '≧(◉◡◉)≦',
      map: {
        a: '𝑎', b: '𝑏', c: '𝑐', d: '𝑑', e: '𝑒', f: '𝑓', g: '𝑔',
        h: 'ℎ', i: '𝑖', j: '𝑗', k: '𝑘', l: '𝑙', m: '𝑚', n: '𝑛',
        o: '𝑜', p: '𝑝', q: '𝑞', r: '𝑟', s: '𝑠', t: '𝑡', u: '𝑢',
        v: '𝑣', w: '𝑤', x: '𝑥', y: '𝑦', z: '𝑧'
      }
    }






  ];

  const convertText = (text, map) => {
    return text.split('').map(char => map[char] || char).join('');
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  const applyStyle = (text, font) => {
    const prefix = font.prefix || '';
    const suffix = font.suffix || '';
    return `${prefix}${convertText(text, font.map)}${suffix}`;
  };

  return (
    <div className="font-changer">
      
        {fonts.map((font, index) => (
          <div key={index} className="font-container">
            <h2>{font.name}</h2>
            <div
              className="styled-text"
              style={{ color: textColor, fontSize: `${fontSize}px` }}
            >
              {applyStyle(inputText, font)}
            </div>
            <button
              className="copy-button"
              onClick={() => handleCopy(applyStyle(inputText, font), index)}
            >
              {copiedIndex === index ? 'Copied' : 'Copy'}
            </button>
          </div>
        ))}
      
      <div class="content-container">
        <h2>Unlocking Creativity with Stylish Fonts for Facebook</h2>
        <p>In the world of social media, standing out is crucial. One way to make your Facebook posts unique and eye-catching is by using stylish fonts. Whether you're looking to change the font on Facebook posts or want a fancy Facebook font for your profile name, our Facebook font changer tool has got you covered.</p>
        
        <h2>Why Use Different Fonts for Facebook?</h2>
        <p>Using different fonts for Facebook can make your posts more engaging and help you express your personality. From changing the font style of Facebook posts to using special fonts for Facebook, the possibilities are endless. Our tool offers a range of fonts for Facebook, allowing you to customize your social media experience.</p>
        
        <h2>How to Change Font on Facebook Post</h2>
        <p>Changing the font on a Facebook post is simple with our Facebook font changer. Just type your text into our font generator for Facebook, select the font style you like, and copy and paste it into your Facebook post. It's that easy! This Facebook text changer lets you choose from various font styles, making your posts stand out.</p>
        
        <h2>Font Style Online for Facebook</h2>
        <p>Our online tool offers an array of font styles for Facebook. Whether you need a fancy Facebook font for a special occasion or a different font for your daily posts, we have it all. Our Facebook font generator provides stylish fonts for Facebook, perfect for adding a creative touch to your profile.</p>
        
        <h2>Stylish Font for Facebook Name</h2>
        <p>Want to make your Facebook name more stylish? Use our font maker for Facebook to generate a unique and stylish font for your Facebook name. This font style name for Facebook will make your profile more attractive and memorable. From fancy fonts to special fonts for Facebook, our tool offers a wide selection.</p>
        
        <h2>Facebook Font Changer Bold</h2>
        <p>For those who want to make a bold statement, our Facebook font changer bold option is perfect. This feature allows you to create bold and eye-catching text that stands out in any Facebook post. Whether it's for a status update or a comment, bold fonts can enhance your Facebook experience.</p>
        
        <h2>Free Font Facebook</h2>
        <p>Our Facebook font style generator is completely free to use. You can generate and use stylish fonts for Facebook without any cost. Change the font style on Facebook with ease and enjoy the flexibility of our free tool.</p>
        
        <h2>How to Use the Facebook Font Editor</h2>
        <p>Using our Facebook font editor is straightforward. Simply enter your text, choose the font type for Facebook, and copy the styled text. Paste it into your Facebook post, and you're done! This tool is perfect for those who want to change the font in Facebook posts regularly.</p>
        
        <h2>Special Features of Our Font Changer for Facebook</h2>
        <ul>
            <li><strong>Facebook Font Converter:</strong> Convert regular text into stylish fonts for Facebook.</li>
            <li><strong>Facebook Font Color Changer:</strong> Add a splash of color to your fonts.</li>
            <li><strong>Fancy Font Facebook:</strong> Choose from a variety of fancy fonts to enhance your posts.</li>
            <li><strong>Facebook Font Style Change:</strong> Easily switch between different font styles.</li>
        </ul>
        
        <h2>Enhance Your Social Media Presence</h2>
        <p>By using different fonts for Facebook, you can create posts that are not only visually appealing but also reflect your unique style. Our Facebook font changer tool makes it easy to experiment with various font styles, from fancy to bold, and everything in between.</p>
        
        <h2>Conclusion</h2>
        <p>Fonts for Facebook are a fantastic way to personalize your social media posts and make them stand out. Whether you're looking to change the font on a Facebook post, use a fancy Facebook font, or find a special font for your profile name, our Facebook font generator has everything you need. Try it today and see the difference stylish fonts can make!</p>
    </div>
    </div>
  );
};

export default FontsForFacebook;