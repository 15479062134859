import React, { useState, useEffect } from 'react';
import WebFont from 'webfontloader';
import './FontChanger.css';
import './CalligraphyFontGenerator.css';

const CalligraphyFontGenerator = ({ inputText, textColor, fontSize }) => {
  const [selectedFont, setSelectedFont] = useState('Arizonia');
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [fontLoadError, setFontLoadError] = useState(false);

  const fonts = [
    'Arizonia', 'Great Vibes', 'Pacifico', 'Dancing Script', 'Satisfy', 'Homemade Apple',
    'Kaushan Script', 'Courgette', 'Sacramento', 'Allura', 'BillionStars_PersonalUse',
    'chancur', 'AdineKirnberg-Script', 'kencall', 'AdineKirnberg-Alternate', 'Adine Kirnberg',
    'QUIGLEYW', 'AmericanText', 'Feathergraphy2', 'CivityFG', 'Manualito-Flo', 'Bastarda',
    'JandaRomantic', 'HANFORD_', 'Kingthings Calligraphica', 'Kingthings Calligraphica Italic',
    'Kingthings Calligraphica Light', 'Kingthings Petrock', 'Kingthings Petrock light',
    'Kingthings Wrote', 'Yoghurt', 'GCursiv2', 'GCursive', 'WatGoth1', 'WatGoth2',
    'Discipuli Britannica', 'Discipuli Britannica Bold', 'Cigno', 'Respective', 'Respective_Slanted',
    'Dragonfly', 'New Day Script', 'SomeWeatz_with_symbols', 'Brasserie', 'akaDora',
    'CalligraphyFLF', 'HamletOrNot', 'SchneidlerInitialen', 'DS_Mysticora', 'MagicMedieval',
    'Alpine', 'Admiration Pains', 'AguafinaScript-Regular', 'Allura-Regular', 'Angel of Blood Light',
    'Angel of Blood', 'Arabella', 'Bluelmin Benedict', 'Bradstone-Parker Script Limited Free Version',
    'Brannboll_Ny_PersonalUseOnly', 'BrotherTattoo_Demo', 'ChopinScript', 'Death in the Shadow',
    'EchinosParkScript_PERSONAL_USE_ONLY', 'Feathergraphy_Clean', 'Feathergraphy2', 'Harbell_Personal_Use',
    'Hemmet_Personal_Use_Only', 'Impregnable_Personal_Use_Only', 'Karine aime les Chocolats',
    'LinaScriptAltDemo', 'LinaScriptDemo', 'LinaScriptDotAltDemo', 'LinaScriptDotDemo',
    'Mademoiselle Camille L', 'Mademoiselle Camille', 'Magnolia Light', 'Magnolia', 'Maratre',
    'MardianDemo', 'MotionPicture_PersonalUseOnly', 'Nenuphar of Venus', 'Pharmount_PersonalUseOnly',
    'Playball', 'Quality Street', 'Queen Anha Script', 'Rainy Wind', 'SouthernAire_Personal_Use_Only',
    'Streamster', 'Ventilla Script', 'Walker on the Moon Light', 'Walker on the Moon',
    'Wolf in the City Light', 'Wolf in the City', 'Acceleration Reaction', 'Albondigas',
    'Aldebaran', 'Alisandra Demo', 'Amontilladios', 'Amontillado', 'Amontillados', 'Antlers_Demo',
    'Atelier du Machiniste', 'Awesome Free Font', 'Back to Black Demo', 'bernadette', 'Blacksword',
    'Bold Stylish Calligraphy', 'CALPHI_T', 'Champion Shipmate', 'Devil East Free Font',
    'Dreams American Diner', 'Hirondelles des Alpes', 'Infinite_Stroke', 'Infinite_Stroke_Bolder',
    'Infinite_Stroke_Bolder_Cond', 'Infinite_Stroke_Cond', 'Kadisoka Script', 'Last King Quest',
    'Marchand de Venise Italic', 'Marchand de Venise', 'Melancholight Italic', 'Melancholight',
    'Midnight Street', 'Mistery Curse', 'Mochary', 'Rocking Season', 'Sanctuaire du Machiniste',
    'Stackyard', 'The Black Pearl italic', 'The Black Pearl semi-italic', 'The Black Pearl',
    'The Heart of Everything', 'Vtc-NueTattooScript', 'WHISPERS CALLIGRAPHY'
  ];

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts
      },
      active: () => {
        setFontLoadError(false);
      },
      inactive: () => {
        setFontLoadError(true);
      }
    });
  }, [fonts]);

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };

  return (
    <div className="calligraphy-font-generator">
      
      {fontLoadError && <p>Error loading fonts. Please check your internet connection.</p>}
      <div className="controls">
        <select value={selectedFont} onChange={handleFontChange}>
          {fonts.map((font, index) => (
            <option key={index} value={font}>{font}</option>
          ))}
        </select>
      </div>
      <div className="styled-texts">
        <div className="styled-text-wrapper">
          <div 
            className="styled-text" 
            style={{ 
              color: textColor, 
              fontSize: `${fontSize}px`, 
              fontFamily: selectedFont 
            }}
          >
            {inputText}
          </div>
          <button 
            className="copy-button" 
            onClick={() => handleCopy(inputText, 0)}
          >
            {copiedIndex === 0 ? 'Copied' : 'Copy'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalligraphyFontGenerator;
