import React, { useState } from 'react';
import './FontChanger.css';

const FancyFonts = ({ inputText, textColor, fontSize }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const fonts = [
    {
  name: 'Strikethrough',
  map: {
    A: '[̲̅A]', B: '[̲̅B]', C: '[̲̅C]', D: '[̲̅D]', E: '[̲̅E]', F: '[̲̅F]', G: '[̲̅G]',
    H: '[̲̅H]', I: '[̲̅I]', J: '[̲̅J]', K: '[̲̅K]', L: '[̲̅L]', M: '[̲̅M]', N: '[̲̅N]',
    O: '[̲̅O]', P: '[̲̅P]', Q: '[̲̅Q]', R: '[̲̅R]', S: '[̲̅S]', T: '[̲̅T]', U: '[̲̅U]',
    V: '[̲̅V]', W: '[̲̅W]', X: '[̲̅X]', Y: '[̲̅Y]', Z: '[̲̅Z]',
    a: '[̲̅a]', b: '[̲̅b]', c: '[̲̅c]', d: '[̲̅d]', e: '[̲̅e]', f: '[̲̅f]', g: '[̲̅g]',
    h: '[̲̅h]', i: '[̲̅i]', j: '[̲̅j]', k: '[̲̅k]', l: '[̲̅l]', m: '[̲̅m]', n: '[̲̅n]',
    o: '[̲̅o]', p: '[̲̅p]', q: '[̲̅q]', r: '[̲̅r]', s: '[̲̅s]', t: '[̲̅t]', u: '[̲̅u]',
    v: '[̲̅v]', w: '[̲̅w]', x: '[̲̅x]', y: '[̲̅y]', z: '[̲̅z]'
  }
},
{
  name: 'Stylish',
  map: {
    A: '【A】', B: '【B】', C: '【C】', D: '【D】', E: '【E】', F: '【F】', G: '【G】',
    H: '【H】', I: '【I】', J: '【J】', K: '【K】', L: '【L】', M: '【M】', N: '【N】',
    O: '【O】', P: '【P】', Q: '【Q】', R: '【R】', S: '【S】', T: '【T】', U: '【U】',
    V: '【V】', W: '【W】', X: '【X】', Y: '【Y】', Z: '【Z】',
    a: '【a】', b: '【b】', c: '【c】', d: '【d】', e: '【e】', f: '【f】', g: '【g】',
    h: '【h】', i: '【i】', j: '【j】', k: '【k】', l: '【l】', m: '【m】', n: '【n】',
    o: '【o】', p: '【p】', q: '【q】', r: '【r】', s: '【s】', t: '【t】', u: '【u】',
    v: '【v】', w: '【w】', x: '【x】', y: '【y】', z: '【z】'
  }
},
{
  name: 'DecorativeText',
  map: {
    A: '（A）', B: '（B）', C: '（C）', D: '（D）', E: '（E）', F: '（F）', G: '（G）',
    H: '（H）', I: '（I）', J: '（J）', K: '（K）', L: '（L）', M: '（M）', N: '（N）',
    O: '（O）', P: '（P）', Q: '（Q）', R: '（R）', S: '（S）', T: '（T）', U: '（U）',
    V: '（V）', W: '（W）', X: '（X）', Y: '（Y）', Z: '（Z）',
    a: '（a）', b: '（b）', c: '（c）', d: '（d）', e: '（e）', f: '（f）', g: '（g）',
    h: '（h）', i: '（i）', j: '（j）', k: '（k）', l: '（l）', m: '（m）', n: '（n）',
    o: '（o）', p: '（p）', q: '（q）', r: '（r）', s: '（s）', t: '（t）', u: '（u）',
    v: '（v）', w: '（w）', x: '（x）', y: '（y）', z: '（z）'
  }
},
{
  name: 'Blocky',
  map: {
    A: '░A░', B: '░B░', C: '░C░', D: '░D░', E: '░E░', F: '░F░', G: '░G░',
    H: '░H░', I: '░I░', J: '░J░', K: '░K░', L: '░L░', M: '░M░', N: '░N░',
    O: '░O░', P: '░P░', Q: '░Q░', R: '░R░', S: '░S░', T: '░T░', U: '░U░',
    V: '░V░', W: '░W░', X: '░X░', Y: '░Y░', Z: '░Z░',
    a: '░a░', b: '░b░', c: '░c░', d: '░d░', e: '░e░', f: '░f░', g: '░g░',
    h: '░h░', i: '░i░', j: '░j░', k: '░k░', l: '░l░', m: '░m░', n: '░n░',
    o: '░o░', p: '░p░', q: '░q░', r: '░r░', s: '░s░', t: '░t░', u: '░u░',
    v: '░v░', w: '░w░', x: '░x░', y: '░y░', z: '░z░'
  }
},
{
  name: 'WavyLines',
  map: {
    A: '≋A≋', B: '≋B≋', C: '≋C≋', D: '≋D≋', E: '≋E≋', F: '≋F≋', G: '≋G≋',
    H: '≋H≋', I: '≋I≋', J: '≋J≋', K: '≋K≋', L: '≋L≋', M: '≋M≋', N: '≋N≋',
    O: '≋O≋', P: '≋P≋', Q: '≋Q≋', R: '≋R≋', S: '≋S≋', T: '≋T≋', U: '≋U≋',
    V: '≋V≋', W: '≋W≋', X: '≋X≋', Y: '≋Y≋', Z: '≋Z≋',
    a: '≋a≋', b: '≋b≋', c: '≋c≋', d: '≋d≋', e: '≋e≋', f: '≋f≋', g: '≋g≋',
    h: '≋h≋', i: '≋i≋', j: '≋j≋', k: '≋k≋', l: '≋l≋', m: '≋m≋', n: '≋n≋',
    o: '≋o≋', p: '≋p≋', q: '≋q≋', r: '≋r≋', s: '≋s≋', t: '≋t≋', u: '≋u≋',
    v: '≋v≋', w: '≋w≋', x: '≋x≋', y: '≋y≋', z: '≋z≋'
  }
},
{
  name: 'KatakanaBrackets',
  map: {
    A: '『A』', B: '『B』', C: '『C』', D: '『D』', E: '『E』', F: '『F』', G: '『G』',
    H: '『H』', I: '『I』', J: '『J』', K: '『K』', L: '『L』', M: '『M』', N: '『N』',
    O: '『O』', P: '『P』', Q: '『Q』', R: '『R』', S: '『S』', T: '『T』', U: '『U』',
    V: '『V』', W: '『W』', X: '『X』', Y: '『Y』', Z: '『Z』',
    a: '『a』', b: '『b』', c: '『c』', d: '『d』', e: '『e』', f: '『f』', g: '『g』',
    h: '『h』', i: '『i』', j: '『j』', k: '『k』', l: '『l』', m: '『m』', n: '『n』',
    o: '『o』', p: '『p』', q: '『q』', r: '『r』', s: '『s』', t: '『t』', u: '『u』',
    v: '『v』', w: '『w』', x: '『x』', y: '『y』', z: '『z』'
  }
},
{
  name: 'Bracketed',
  map: {
    A: '【A】', B: '【B】', C: '【C】', D: '【D】', E: '【E】', F: '【F】', G: '【G】',
    H: '【H】', I: '【I】', J: '【J】', K: '【K】', L: '【L】', M: '【M】', N: '【N】',
    O: '【O】', P: '【P】', Q: '【Q】', R: '【R】', S: '【S】', T: '【T】', U: '【U】',
    V: '【V】', W: '【W】', X: '【X】', Y: '【Y】', Z: '【Z】',
    a: '【a】', b: '【b】', c: '【c】', d: '【d】', e: '【e】', f: '【f】', g: '【g】',
    h: '【h】', i: '【i】', j: '【j】', k: '【k】', l: '【l】', m: '【m】', n: '【n】',
    o: '【o】', p: '【p】', q: '【q】', r: '【r】', s: '【s】', t: '【t】', u: '【u】',
    v: '【v】', w: '【w】', x: '【x】', y: '【y】', z: '【z】'
  }
},
{
  name: 'KatakanaInspired',
  map: {
    A: 'ﾑ', B: '乃', C: 'ᄃ', D: 'り', E: '乇', F: 'ｷ', G: 'ム',
    H: 'ん', I: 'ﾉ', J: 'ﾌ', K: 'ズ', L: 'ﾚ', M: 'ﾶ', N: '刀',
    O: 'の', P: 'ｱ', Q: 'ゐ', R: '尺', S: '丂', T: 'ｲ', U: 'ひ',
    V: '√', W: 'W', X: 'ﾒ', Y: 'ﾘ', Z: '乙',
    a: 'ﾑ', b: '乃', c: 'ᄃ', d: 'り', e: '乇', f: 'ｷ', g: 'ム',
    h: 'ん', i: 'ﾉ', j: 'ﾌ', k: 'ズ', l: 'ﾚ', m: 'ﾶ', n: '刀',
    o: 'の', p: 'ｱ', q: 'ゐ', r: '尺', s: '丂', t: 'ｲ', u: 'ひ',
    v: '√', w: 'W', x: 'ﾒ', y: 'ﾘ', z: '乙'
  }
},
{
  name: 'JapaneseInspired',
  map: {
    A: '卂', B: '乃', C: '匚', D: 'ᗪ', E: '乇', F: '千', G: 'Ꮆ',
    H: '卄', I: '丨', J: 'ﾌ', K: 'Ҝ', L: 'ㄥ', M: '爪', N: '几',
    O: 'ㄖ', P: '卩', Q: 'Ɋ', R: '尺', S: '丂', T: 'ㄒ', U: 'ㄩ',
    V: 'ᐯ', W: '山', X: '乂', Y: 'ㄚ', Z: '乙',
    a: '卂', b: '乃', c: '匚', d: 'ᗪ', e: '乇', f: '千', g: 'Ꮆ',
    h: '卄', i: '丨', j: 'ﾌ', k: 'Ҝ', l: 'ㄥ', m: '爪', n: '几',
    o: 'ㄖ', p: '卩', q: 'Ɋ', r: '尺', s: '丂', t: 'ㄒ', u: 'ㄩ',
    v: 'ᐯ', w: '山', x: '乂', y: 'ㄚ', z: '乙'
  }
},
{
  name: 'CurrencySymbols',
  map: {
    A: '₳', B: '฿', C: '₵', D: 'Đ', E: 'Ɇ', F: '₣', G: '₲',
    H: 'Ⱨ', I: 'ł', J: 'J', K: '₭', L: 'Ⱡ', M: '₥', N: '₦',
    O: 'Ø', P: '₱', Q: 'Q', R: 'Ɽ', S: '₴', T: '₮', U: 'Ʉ',
    V: 'V', W: '₩', X: 'Ӿ', Y: 'Ɏ', Z: 'Ⱬ',
    a: '₳', b: '฿', c: '₵', d: 'Đ', e: 'Ɇ', f: '₣', g: '₲',
    h: 'Ⱨ', i: 'ł', j: 'J', k: '₭', l: 'Ⱡ', m: '₥', n: '₦',
    o: 'Ø', p: '₱', q: 'Q', r: 'Ɽ', s: '₴', t: '₮', u: 'Ʉ',
    v: 'V', w: '₩', x: 'Ӿ', y: 'Ɏ', z: 'Ⱬ'
  }
}








    
    // Add other font mappings here...
  ];

  const convertText = (text, map) => {
    return text.split('').map(char => map[char] || char).join('');
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  return (
    <div className="fancy-fonts">
      <div className="styled-texts">
        {fonts.map((font, index) => (
          <div key={index} className="styled-text-wrapper">
            <div className="styled-text" style={{ color: textColor, fontSize: `${fontSize}px` }}>
              {convertText(inputText, font.map)}
            </div>
            <button 
              className="copy-button" 
              onClick={() => handleCopy(convertText(inputText, font.map), index)}
            >
              {copiedIndex === index ? 'Copied' : 'Copy'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FancyFonts;
