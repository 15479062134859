import React from 'react';
import './FontHistory.css'; // Create this CSS file for styling the history list

const FontHistory = ({ history }) => {
  return (
    <div className="font-history">
      <h2>Most Used Fonts</h2>
      <ul>
        {history.map((font, index) => (
          <li key={index} style={{ fontFamily: font }}>
            {font}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FontHistory;
