import React, { useState, useEffect } from 'react';
import './GlitchTextGenerator.css';


const GlitchTextGenerator = ({ inputText, setInputText, textColor, fontSize }) => {
  const [glitchIntensity, setGlitchIntensity] = useState(1);
  const [glitchSpeed, setGlitchSpeed] = useState(1);
  const [glitchedText, setGlitchedText] = useState('');

  useEffect(() => {
    setGlitchedText(generateGlitchedText());
  }, [inputText, glitchIntensity, glitchSpeed]);

  // Function to generate glitched text
  const generateGlitchedText = () => {
    const glitchChars = ['̷', '͜', '̨', '̇', '͡', '͢', '̷', '͇', '͌', '́', '͜͜', '͡͝', '͂'];
    let result = '';

    for (let i = 0; i < inputText.length; i++) {
      const char = inputText[i];
      const glitchChance = Math.random();
      if (glitchChance < glitchIntensity / 5) {
        let glitchedChar = char;
        const numGlitches = Math.floor(glitchIntensity * 2);
        for (let j = 0; j < numGlitches; j++) {
          glitchedChar += glitchChars[Math.floor(Math.random() * glitchChars.length)];
        }
        result += `<span class="glitch-char">${glitchedChar}</span>`;
      } else {
        result += char;
      }
    }

    return result;
  };

  // Function to copy glitched text to clipboard
  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = glitchedText.replace(/<[^>]*>?/gm, ''); // Remove HTML tags for copying plain text
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  return (
    <div className="glitch-text-generator">
      <h2>Glitch Text Generator</h2>
      <div className="controls">
        <label>Glitch Intensity:</label>
        <input
          type="range"
          min="1"
          max="5"
          value={glitchIntensity}
          onChange={(e) => setGlitchIntensity(e.target.value)}
        />
        <label>Glitch Speed:</label>
        <input
          type="range"
          min="1"
          max="5"
          value={glitchSpeed}
          onChange={(e) => setGlitchSpeed(e.target.value)}
        />
      </div>
      <div
        className="glitched-text"
        style={{
          color: textColor,
          fontSize: `${fontSize}px`,
        }}
        dangerouslySetInnerHTML={{ __html: glitchedText }}
      />
      <button onClick={copyToClipboard}>Copy Glitched Text</button>
    </div>
  );
};

export default GlitchTextGenerator;
