import React, { useState } from 'react';
import './SquiggleFancyFonts.css';

const SquiggleFancyFonts = ({ inputText, textColor, fontSize }) => {
  const [selectedEffect, setSelectedEffect] = useState('None');
  const [intensity, setIntensity] = useState(5);

  const effects = [
    'None', 'Wave', 'Zigzag', 'Wobble', 'Curly', 'Bounce', 'Twist', 'Skew', 'Stretch', 'Shrink'
  ];

  const applyEffect = (text) => {
    switch (selectedEffect) {
      case 'Wave':
        return text.split('').map((char, index) => (
          <span key={index} style={{ position: 'relative', top: Math.sin(index / intensity) * 5 }}>{char}</span>
        ));
      case 'Zigzag':
        return text.split('').map((char, index) => (
          <span key={index} style={{ position: 'relative', top: index % 2 === 0 ? -intensity : intensity }}>{char}</span>
        ));
      case 'Wobble':
        return text.split('').map((char, index) => (
          <span key={index} style={{ position: 'relative', left: Math.cos(index / intensity) * 5 }}>{char}</span>
        ));
      case 'Curly':
        return text.split('').map((char, index) => (
          <span key={index} style={{ position: 'relative', top: Math.sin(index / intensity) * 5, left: Math.cos(index / intensity) * 5 }}>{char}</span>
        ));
      case 'Bounce':
        return text.split('').map((char, index) => (
          <span key={index} style={{ position: 'relative', top: Math.abs(Math.sin(index / intensity) * 10) }}>{char}</span>
        ));
      case 'Twist':
        return text.split('').map((char, index) => (
          <span key={index} style={{ display: 'inline-block', transform: `rotate(${Math.sin(index / intensity) * 10}deg)` }}>{char}</span>
        ));
      case 'Skew':
        return text.split('').map((char, index) => (
          <span key={index} style={{ display: 'inline-block', transform: `skewX(${Math.sin(index / intensity) * 10}deg)` }}>{char}</span>
        ));
      case 'Stretch':
        return text.split('').map((char, index) => (
          <span key={index} style={{ display: 'inline-block', transform: `scaleY(${1 + Math.sin(index / intensity) * 0.5})` }}>{char}</span>
        ));
      case 'Shrink':
        return text.split('').map((char, index) => (
          <span key={index} style={{ display: 'inline-block', transform: `scale(${1 - Math.sin(index / intensity) * 0.5})` }}>{char}</span>
        ));
      default:
        return text;
    }
  };

  return (
    <div className="squiggle-fancy-fonts">
      
      <div className="controls">
        <label>Effect:</label>
        <select value={selectedEffect} onChange={(e) => setSelectedEffect(e.target.value)}>
          {effects.map((effect, index) => (
            <option key={index} value={effect}>{effect}</option>
          ))}
        </select>
        <label>Intensity:</label>
        <input
          type="range"
          min="1"
          max="10"
          value={intensity}
          onChange={(e) => setIntensity(e.target.value)}
        />
      </div>
      <div className="styled-text-wrapper">
        <div 
          className="styled-text" 
          style={{ 
            color: textColor, 
            fontSize: `${fontSize}px`,
            display: 'inline-block',
            whiteSpace: 'pre-wrap'
          }}
        >
          {applyEffect(inputText)}
        </div>
      </div>
    </div>
  );
};

export default SquiggleFancyFonts;
